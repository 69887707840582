import React from 'react';

const parseApiMetaTags = (tag) => {
  if (!tag.length) {
    return;
  }

  const node = tag.split(/\n/)[0];
  let nodeType = tag.match(/[a-z]+/)[0];

  let props = {
    key: 0,
  };

  node.match(/([a-z-]+=".*?")/g).forEach((attr) => {
    let matches = attr.match(/([a-z-]+)="(.*?)"/);
    props[matches[1]] = matches[2];
  });

  return React.createElement(nodeType, props);
};

const parseTitleMetaTag = (tag) => {
  if (!tag || tag.props?.name !== 'title') {
    return tag;
  }
  const nodeType = 'title';
  const props = {
    key: 0,
    children: tag.props.content,
  };
  return React.createElement(nodeType, props);
};

export const parseTags = (apiTags) => {
  return apiTags.map((apiTag) => parseApiMetaTags(apiTag)).map((tag) => parseTitleMetaTag(tag));
};

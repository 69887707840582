import { useSearchContext } from 'providers/SearchContextProvider';

const { Helmet } = require('react-helmet');

const ResulsPageMetaTags = () => {
  const { metaTags } = useSearchContext();

  return <Helmet defer={false}>{metaTags}</Helmet>;
};

export default ResulsPageMetaTags;

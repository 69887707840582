const data = [
  {
    key: 'enhancedSort',
    label: 'Enhanced Sort',
    subtitle: 'cap rate, ProperScore',
  },

  {
    key: 'historicRents',
    label: 'Property Specific Historical Rents',
    subtitle: '2021:$59k  2020:$62k',
  },

  {
    key: 'marketDetails',
    label: 'More Market Details',
    subtitle: '# of rentals, seasonality',
  },

  {
    key: 'propertyFeatures',
    label: 'Property Features',
    subtitle: 'pool, view, etc',
  },
  // {
  //   key: 'virtualTours',
  //   label: 'Virtual Tours',
  //   subtitle: 'Explore in 3D',
  // },
  {
    key: 'propertyType',
    label: 'Property Type',
    subtitle: 'house, condo',
  },

  // {
  //   key: 'moreFinancials',
  //   label: 'More Financials',
  //   subtitle: 'cap rate, etc',
  // },

  // {
  //   key: 'distanceFromHome',
  //   label: 'Distance from Home',
  //   subtitle: 'travel time',
  // },
];

export default data;

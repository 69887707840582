import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Container } from '@material-ui/core';

import Logo from 'components/atoms/Logo';

import { useStyles } from './styles';

const Footer = () => {
  const classes = useStyles();
  const { t } = useTranslation('footer');
  const year = new Date().getFullYear();

  return (
    <Box component={'div'} className={classes.root} displayPrint={'none'}>
      <div className={classes.footer}>
        <Container className={classes.start}>
          <Logo dark style={{ height: '4vh' }} />
          <p className={classes.links}>
            <a
              href={'https://www.propersum.io/terms-privacy'}
              target={'_blank'}
              rel={'noreferrer'}
              className={classes.link}
            >
              {t('terms')}
            </a>
            <span>|</span>
            <a
              href={'https://www.propersum.io/contact-us'}
              target={'_blank'}
              rel={'noreferrer'}
              className={classes.link}
            >
              {t('contact')}
            </a>
          </p>
        </Container>
      </div>
      <div className={classes.end}>{t('copyright', { year })}</div>
    </Box>
  );
};

export default Footer;

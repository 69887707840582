import { colors } from '@material-ui/core';
// eslint-disable-next-line no-unused-vars
import { PaletteOptions } from '@material-ui/core/styles/createPalette';

/**
 * @see https://material-ui.com/customization/palette/
 * @type {PaletteOptions}
 */
const palette = {
  type: 'light',
  background: {
    default: '#FFFFFF',
  },
  primary: {
    main: '#41B060',
    light: '#289528',
    bg: '#C1F4C1',
  },
  secondary: {
    main: '#FFFFFF',
    light: '#FBFBFB', // 3%
    dark: '#F2F2F2', // 5%
    200: '#CCCCCC',
    400: '#999999',
    600: '#666666',
    700: '#3C3C3C',
    800: '#333333',
  },
  error: {
    main: '#C84040',
    light: '#FDF9F9',
  },
  success: {
    main: '#3CA455',
    light: '#F9FCFA',
  },
  warning: {
    main: '#E67337',
  },
  info: {
    main: '#110F24',
  },
  action: {
    active: colors.common.white,
  },
  text: {
    primary: colors.common.black,
  },
};

export default palette;

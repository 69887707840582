import { getCurrentBrowserFingerPrint } from '@rajesh896/broprint.js';
import axios from 'axios';
import { get } from 'common/api';

const instance = axios.create();

const endpoints = {
  viewableAreas: '/marketplace/viewableareas/',
  cloudflareIp: 'https://www.cloudflare.com/cdn-cgi/trace',
};

const FINGERPRINT_KEY = 'fingerprint';

const setFingerprint = (deviceId, ip) => {
  const fingerprint = window.btoa(`${deviceId}-${ip}`);
  window.localStorage.setItem(FINGERPRINT_KEY, fingerprint.substring(0, 25));
};

export const getFingerprint = () => localStorage.getItem(FINGERPRINT_KEY);

export const getIp = async () => {
  const { data } = await instance.get(endpoints.cloudflareIp);
  const ipRegex = /[0-9]{1,3}.[0-9]{1,3}.[0-9]{1,3}.[0-9]{1,3}/;
  return data.match(ipRegex)[0];
};

export const getDeviceId = async () => {
  const fingerprint = getFingerprint();
  if (fingerprint) {
    return fingerprint;
  }
  const deviceId = await getCurrentBrowserFingerPrint();
  const ip = await getIp();

  setFingerprint(deviceId, ip);
  return deviceId;
};

export const clearFingerprint = () => localStorage.removeItem(FINGERPRINT_KEY);

export const getViewableAreas = () => get(endpoints.viewableAreas);

import React, { useState } from 'react';
import ReactGA from 'react-ga';
import { useTranslation } from 'react-i18next';
import { Box, Button, Typography } from '@material-ui/core';
import {
  PaymentElement as PaymentElementStripe,
  useElements,
  useStripe,
} from '@stripe/react-stripe-js';

import { useDialog } from 'providers/DialogProvider';
import { useSnackbar } from 'providers/SnackbarProvider';
import { useUserContext } from 'providers/UserProvider';

import { useStyles } from './styles';

const PaymentElement = ({ label, price }) => {
  const stripe = useStripe();
  const elements = useElements();
  const { reload } = useUserContext();
  const [openSnackbar] = useSnackbar();
  const [, , clearDialogs] = useDialog();
  const [message, setMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const { t } = useTranslation('payment');

  const classes = useStyles();

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    setIsLoading(true);

    const { error } = await stripe.confirmPayment({
      elements,
      redirect: 'if_required',
    });

    setTimeout(() => {
      if (error) {
        if (error.type === 'card_error' || error.type === 'validation_error') {
          setMessage(error.message);
        } else {
          setMessage(t('error'));
        }
      } else {
        openSnackbar({
          children: t('success'),
          variant: 'success',
          autoHideDuration: null,
        });

        ReactGA.event({
          action: 'complete_purchase',
          category: 'purchase',
          value: '1',
        });

        clearDialogs();

        reload();
      }

      setIsLoading(false);
    }, 1000);
  };

  return (
    <div className={classes.paper}>
      <Box marginBottom={'1rem'}>
        <Typography variant="body1" color="initial">
          {label}
        </Typography>
      </Box>

      <Box>
        <PaymentElementStripe id="payment-element" />
      </Box>

      <Box className={classes.footer}>
        <Typography variant="h3" color="primary">
          {`$${price}`}
        </Typography>
        <Button
          variant="contained"
          color="primary"
          onClick={handleSubmit}
          disabled={isLoading}
          style={{ height: '2rem' }}
        >
          {isLoading ? 'Processing...' : 'Complete Purchase'}
        </Button>
      </Box>
      <Box>{message && <div id="payment-message">{message}</div>}</Box>
    </div>
  );
};

export default PaymentElement;

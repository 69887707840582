import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Typography } from '@material-ui/core';
import { Form, Formik } from 'formik';

import useApiCall from 'hooks/useApiCall';
import { useDialog } from 'providers/DialogProvider';
import { useSnackbar } from 'providers/SnackbarProvider';
import { forgotPassword } from 'services/authService';
import forgotPasswordValidation from 'validators/forgotPassword';

import Button from 'components/atoms/Button';
import Input from 'components/atoms/Formik/Input';

import { useStyles } from './styles';

const ForgotPasswordModal = ({ noTrailingInfo }) => {
  const classes = useStyles();
  const { t } = useTranslation('forgotModal');
  const [, , clearDialogs] = useDialog();
  const [openSnackbar] = useSnackbar();

  const [execForgotPassword, { data, error, loading }] = useApiCall(forgotPassword);

  const onSubmit = async ({ email }, { setSubmitting }) => {
    await execForgotPassword(email);
    setSubmitting(false);
  };

  useEffect(() => {
    if (error) {
      // tries to translate key sent from BE, render custom message or default message by default
      openSnackbar({
        children: (
          <Typography variant={'body1'} className={classes.error}>
            {error?.response?.data?.error}
          </Typography>
        ),
        variant: 'error',
      });
    }
  }, [error]);

  useEffect(() => {
    if (data && !error) {
      clearDialogs();
      openSnackbar({
        children: <Typography variant={'body1'}>{t('resetSuccess')}</Typography>,
        variant: 'success',
      });
    }
  }, [data, error]);

  return (
    <Formik
      onSubmit={onSubmit}
      initialValues={{ email: '' }}
      validationSchema={forgotPasswordValidation(t)}
    >
      <Form className={classes.root}>
        <div className={classes.section}>
          <Input
            name="email"
            placeholder={t('emailPlaceholder')}
            fullWidth
            label={t('email')}
            required
          />
        </div>
        <div className={[classes.section, classes.actions].join(' ')}>
          <Button
            label={loading ? t('sendingEmail') : t('sendButton')}
            variant={'primary'}
            fullWidth
            type={'submit'}
            disabled={loading}
          />
        </div>
        {!noTrailingInfo && (
          <div className={classes.content}>
            <Typography variant={'body2'}>{t('forgot')}</Typography>
          </div>
        )}
      </Form>
    </Formik>
  );
};

export default ForgotPasswordModal;

import { Dialog } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import DialogTitle from './DialogTitle';
import { useDialog } from './index';

const useStyles = makeStyles({
  backDrop: {
    backdropFilter: 'blur(5px)',
    backgroundColor: 'rgba(255,255,255,0.4)',
  },
});

/**
 * Generic dialog used for every modal component
 *
 * @param {JSX.Element} children The dialog content
 * @param {boolean} logo Shows the logo instead of the title
 * @param {string} title
 * @param {boolean} open
 * @param {boolean} lockedContent
 * @param {boolean} withTitle
 * @param onClose
 * @param onKill
 * @param fullWidth
 * @param maxWidth
 * @param rest
 * @param fullWidth
 * @param maxWidth
 * @return {JSX.Element}
 */
const DialogContainer = ({
  children,
  open,
  onClose,
  lockedContent = false,
  logo = false,
  title,
  onKill,
  withTitle = true,
  fullWidth = true,
  maxWidth = 'xs',
  blurred = false,
  ...rest
}) => {
  const [, closeModal] = useDialog();

  const classes = useStyles();
  return (
    <Dialog
      open={open}
      onClose={lockedContent ? undefined : onClose}
      disableBackdropClick={lockedContent}
      onExited={onKill}
      maxWidth={maxWidth}
      fullWidth={fullWidth}
      BackdropProps={{
        classes: {
          root: lockedContent || blurred ? classes.backDrop : null,
        },
      }}
      {...rest}
    >
      {withTitle && (
        <DialogTitle onClose={lockedContent ? undefined : closeModal} logo={logo} title={title} />
      )}
      {children}
    </Dialog>
  );
};

export default DialogContainer;

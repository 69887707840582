import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-around',
    alignItems: 'center',
  },
  selected: {
    color: theme.palette.primary.main,
    fontWeight: 'bold',
  },
  notSelected: {
    color: theme.palette.secondary[400],
  },
}));

import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  childRoot: {
    padding: '0.5em 0 0 0',
    width: '100%',
    [theme.breakpoints.up('md')]: {
      padding: '1.286em',
    },
  },
  checkBoxWrapper: {
    display: 'flex',
    flexDirection: 'column',
  },
  row: {
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'column',
    marginBottom: '0.8em',
    [theme.breakpoints.up('md')]: {
      marginTop: '0.714em',

      '&:first-child': {
        marginRight: '0',
      },
    },
  },
  minMaxRow: {
    display: 'flex',
    '& p': {
      minWidth: '15%',
    },
  },
  labelColumn: {
    flex: '1',
    display: 'flex',
  },
  inputColumn: {
    display: 'flex',
  },
  inputWrapper: {
    width: '71px',
  },

  title: {
    fontSize: '1.14em',
    fontWeight: '700',
    lineHeight: '1.7em',
    marginTop: '1em',
    [theme.breakpoints.up('md')]: {
      marginTop: '0',
    },
  },
  middleText: {
    display: 'flex',
    alignItems: 'center',
    padding: '0',
    fontSize: '18px',
    margin: '0 10px 0 0',
    fontWeight: '400',
    color: theme.palette.text.primary,
  },
  separator: {
    border: '0 none',
    height: '1px',
    width: '100%',
    margin: '0.5em 0',
  },
}));

import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  childRoot: {
    padding: '0.5em 0 0 0',
    width: '100%',
    [theme.breakpoints.up('md')]: {
      padding: '1.2em',
    },
  },
  innerWrapper: {
    display: 'flex',
    flexDirection: 'column',
  },
  minMaxRow: {
    display: 'flex',
    '& p': {
      minWidth: '15%',
    },
  },
  inputText: {
    display: 'flex',
    alignItems: 'center',
    padding: '0',
    fontSize: '18px',
    marginRight: '10px',
    color: theme.palette.text.primary,
    [theme.breakpoints.up('md')]: {
      padding: '0 10px',
    },
  },
  checkBoxText: {
    fontSize: '14px',
  },
  separator: {
    border: '0 none',
    height: '1px',
    width: '100%',
    margin: '0.5em 0',
  },
}));

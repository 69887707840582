import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  formControl: {
    width: ({ width }) => width,
    '& label': {
      color: theme.palette.primary.main,
    },
    '& fieldset': {
      borderColor: `${theme.palette.primary.bg} !important`,
    },
    '& svg': {
      color: theme.palette.primary.main,
      display: ({ hasArrow }) => !hasArrow && 'none',
    },
  },
  selectIcon: {
    position: 'absolute',
    top: 13,
    left: 12,
    marginRight: '20px',
    color: `${theme.palette.success.main} `,
    display: 'block !important',
  },
  selectWrapper: {
    position: 'relative',
  },
  lineSeparator: {
    borderLeft: `1px solid ${theme.palette.primary.bg}`,
    height: '29px',
    position: 'absolute',
    left: 45,
    top: 10,
  },
  select: {
    width: ({ width }) => width,
    '& div': {
      paddingLeft: ({ selectIcon }) => (selectIcon ? '60px' : '10px'),
      '&:focus': {
        backgroundColor: 'transparent',
      },
    },
    height: (props) => props.hidden && 0,
    visibility: (props) => props.hidden && 'hidden',
    color: ({ selectTextColor }) => selectTextColor,
  },
}));

import * as queryString from 'query-string';

import httpClient from './httpClient';

const paramsSerializer = (params) => queryString.stringify(params);

export const post = async (endpoint, body) => {
  return httpClient.post(endpoint, body);
};

export const get = async (endpoint, body = {}, cancelToken = null) => {
  return httpClient.get(endpoint, {
    params: body,
    cancelToken,
    paramsSerializer,
  });
};

export const put = async (endpoint, body) => {
  return httpClient.put(endpoint, body);
};

export const patch = async (endpoint, body) => {
  return httpClient.patch(endpoint, body);
};

export const deleteResource = async (endpoint) => {
  return httpClient.delete(endpoint);
};

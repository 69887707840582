export default {
  brand: 'Propersum®',
  defaultTitle: 'Propersum',
  goHome: 'Go Home',
  pages: {
    results: {
      title: 'Propersum — Buy vacation properties with confidence',
      description:
        'The vacation rental real estate marketplace where buyers get reliable financials, agents sell more, and managers generate new business.',
      saveSearchModalTitle: 'Save Search',
      saveSearchModalError: 'No properties found. To get more, try adjusting your search.',
      shareSearchModalTitle: 'Share this Search',
      loginFirst: 'Please Log In and try again.',
      otherProperties: 'Other vacation properties for sale',
    },
    resultsByLocation: {
      title: 'Vacation rentals for sale in {{area}} — Propersum',
      description:
        '{{area}} vacation rentals for sale with reliable rental estimates from local property managers.',
    },
    resetPassword: {
      title: 'Reset Password',
      description: 'Reset Password',
    },
    profile: {
      title: '{{company}} — Propersum',
      error: 'There was an error on our side',
      noUser: 'Profile not found',
      ourCompany: 'Our Company',
      video: 'Video',
      technologiesWeUse: 'Technologies and Services We Use',
      businessFounded: 'Business Founded',
      unitsUnderManagement: 'Units under Management',
      fullTimeEmployees: 'Full-Time Employees',
      unitsPerEmployeeRatio: 'Units per Employee',
    },
  },
  menu: {
    searchProperties: 'Search Properties',
    buyers: 'Buyers',
    agents: 'Agents',
    managers: 'Managers',
    myDashboard: 'My Dashboard',
    proposals: 'Proposals',
    myFavorites: 'My Favorites & Catalogs',
    myAccount: 'My Account',
    settings: 'Settings',
    login: 'Log In',
    signOut: 'Sign Out',
  },
  propertyCard: {
    managementProposals: 'Vacation rental estimates by Property Managers',
    propersumEstimates: 'Propersum Estimates',
    withoutProposals: 'Annual rents estimate based on similar vacation properties',
    properEstimate: 'ProperEstimate™',
    options: {
      save: 'Save To Catalog',
      delete: 'Remove from this catalog',
    },
    newCatalogTitle: 'Create Catalog and Save',
    loginFirst: 'Please Log In and try again.',
    capRate: 'Cap Rate',
    rents: 'Rents',
    properScore: 'Proper Score',
  },
  proposalBox: {
    requestTitle1: 'Request',
    requestTitle2: 'proposals and estimates',
    requestTitle3: 'for this property from local experts in vacation rental management',
    addLabel1: 'Add Your',
    addLabel2: 'Quick Proposal',
    requestButton: 'Request Proposals',
    addProposal: 'Add Proposal',
    proposalsDue: 'Posting',
    fillCompanyDataLabel1: 'To add a proposal',
    fillCompanyDataLabel2: 'Fill your company information',
  },
  propertyEstimates: {
    annualEstimates: 'Annual Estimates',
    grossRents: 'Gross Rents',
    managementFee: 'Management Fee',
    fee: 'Fee',
    otherServices: 'Other Services',
    utilities: 'Core Utilities',
    maintenance: 'Maintenance',
    community_dues: 'Community Dues',
    home_insurance: 'Home Insurance',
    property_taxes: 'Property Taxes',
    includeMortgage: 'Include Mortgage',
    netTotal: 'Annual Net Estimate',
    cashDown: 'Cash Down',
    loanAmount: 'Loan Amount',
    rateAndTerm: 'Rate and Term',
    paymentsMonthly: 'Monthly Payment',
    resetEstimates: 'Reset Estimates',
    netOperatingIncome:
      'Annual Net Estimate is the total of potential rents and operating expenses listed above.',
    estimateWithMortgage:
      'Annual estimate that includes the mortgage cost estimate in the total calculation.',
    grossRentsTooltip:
      "Gross Rents is the estimated total rents based on comparable vacation properties and after a full year of renting as a vacation rental property. Gross Rents will be the ProperEstimate or a specific property manager's annual estimate, if available and selected.",
    operatingCostsTooltip:
      'Operating costs are the expenses expected with operating and maintaining a property as a year round short-term vacation rental business. Some expenses, including but not limited to personal income taxes and property permit fees, are not included here so ask your prospective property manager and real estate agent what other fees you may incur.',
    managementFeeTooltip:
      'This is the service fee charged by a professional management company as a percentage of all rents collected, to manage your accommodations business. Similar to any business, the owner of the business (you) is responsible for all of the bills and expenses while the staff (your property management company) runs your business on your behalf for a fee. PRO TIP: Experienced investors will verify what other fees the manager may be charging directly to guests, in addition to the fees the manager is collecting from the property owner.',
    otherServicesTooltip:
      'Other Services may include various operating expenses such as credit card processing fees, linen services, housekeeping, marketing services/packages, HVAC maintenance contracts, third party commissions, and various other common expenses. Some or all of these costs may be billed directly to guests, billed to the property owner, or included with the management fee. PRO TIP: Experienced investors will verify what services are included or excluded from the Management Fee.',
    coreUtilitiesTooltip:
      'Unlike a regular, long-term rental property where the tenant pays for utilities, short-term vacation rental property owners pay for all utilities as a cost of doing business. Core utilities include all power, water, TV, and internet services. Some properties with speciality amenities, like a hot tub or heated pool, should expect higher utilities costs in conjunction with higher rents.',
    maintenanceTooltip:
      'Short-term vacation rental maintenance is notably more frequent and more expensive than a traditional, long-term rental. Guests on vacation are busier, clumsier, and in greater numbers than typical tenants so wear and tear is notably higher with regular maintenance needs and can be as frequent as every few weeks. Additionally, vacationing guests are often impatient when a need arises, therefore speed of correction often increases maintenance costs. PRO TIP:  On average, general maintenance costs range between 3% and 7% of the annual rents. Smaller or older homes have higher percentages than larger or newer homes.',
    ownershipCostsTooltip:
      'Ownership costs are the expenses and costs simply to own the property as if it were a regular, non-rental. PRO TIP: In real estate investing, some of these expenses are used for calculating expanded financials such as Cap Rate and OpEx Ratio.',
    communityDuesTooltip:
      "When available, community dues are the fees charged by the homeowner's association, property owner's association, or neighborhood community. PRO TIP: These fees are often poorly disclosed by the listing agency, so it's important to check with your real estate agent what, if any, fees are applicable and what those fees would cover. Many condominium communities have notably high dues, so always verify with your professional Realtors before making an offer.",
    homeInsuranceTooltip:
      'Home Insurance estimates are algorithmic and are generated by comparable, in-market vacation properties and the expected premium for coverage to protect the property in the event it must be completely rebuilt. This is primarily a function of property size, property quality, and rebuilding costs.',
    propertyTaxesTooltip:
      'Property tax is a complex topic due to variations in assessment accuracy as well as a combination of mostly county and local taxation. Often, properties are not accurately assessed by the local authority and as such, official tax records are frequently inaccurate representation of potential, future tax liability. Propersum uses an algorithmic approached for tax estimation based on a proper assessment of property value.',
    annualNetEstimateTooltip:
      'Annual Net Estimate show the total cash (or loss) after 12 months of actively renting as a vacation property. It is a combination of all above income and expenses, including taxes and insurance as well as mortgage payments (if selected) for a property. PRO TIP: This number is used by professional investors as a quick guide on general performance.',
    operatingCosts: 'Operating Costs',
    ownershipCosts: 'Ownership Costs',
  },
  home: {
    title: 'HOME',
    redirect: 'Redirect',
    today: 'Today is the day',
    button: 'Dispatch an example action',
    values: 'Values from the store (dispatch an action to load more)',
    login: 'Login',
    noMatches: 'No matches found.',
    noPropertiesFound: 'no results',
  },
  propertyBrowser: {
    propertiesForSale: 'Properties for Sale',
    resultCount: '{{resultCount}} Results',
    sortBy: 'Sort By:',
    filters: 'Filters',
    save: 'Save',
    share: 'Share',
    saveSearch: 'Save Search',
    shareSearch: 'Share Search',
    loading: 'Loading...',
    properties: 'Property',
    properties_plural: 'Properties',
  },
  layout: {
    disclaimer:
      'Agents, agencies, brokers, property managers, insurance providers, lenders, advertisers, and PROPERSUM are not liable or responsible for errors and omissions in this material, and no guarantee can be made for any data, rental figures, taxes, insurance, and/or potential income & expenses displayed. PROPERSUM does not provide financial, investment, lending, insurance, or real estate services. The PROPERSUM logo is a registered trademark of PROPERSUM, Inc. All rights reserved ® 2020',
  },
  managersList: {
    managers: 'Estimates',
    managersHelp: {
      title: 'Estimates',
      content:
        'Estimates provided by Propersum or, when available, professionals offering management services for this property.',
    },
    rents: 'Rents',
    rentsHelp: {
      title: 'Rents',
      content:
        'The total rental income (gross rents) a manager expects to generate for you over the course of a year.',
    },
    fee: 'Fee',
    feeHelp: {
      title: 'Fee',
      content:
        'The percentage of rents the manager will charge for providing you professional management service.',
    },
  },
  summary: {
    summaryTitle: 'Summary',
    estimateTitle: 'Estimate',
    grossRents: 'Gross Rents',
    grossRentsHelp: 'Gross Rents estimates are supplied by property managers.',
    managementFee: 'Management Fee',
    otherServices: 'Other Services',
    subtotal: 'Annual Estimate',
  },
  help: {
    group1: {
      title: '',
      content:
        'Gross Rents and Management Fee estimates are provided by the company proposing management services. Proposal estimates may vary based on a number of factors and expectations such as owner use, property upkeep, and market conditions.',
    },
    group2: {
      title: '',
      content:
        'Other Services often include operating expenses such as credit card processing fees, linen services, housekeeping, marketing services/packages, property maintenance, third party commissions, and various other common expenses. Some or all of these costs may be billed directly to guests, billed to the property owner, or included with the management fee. Please contact the management company for details.',
    },
    group3: {
      title: '',
      content:
        'Utilities, Community Dues, Home Insurance, and Property Taxes are estimates based on and sourced from a combination of public, private, and calculated data. As with all real estate purchases, a local real estate professional is recommended for verification.',
    },
    group4: {
      title: '',
      content:
        'Annual Estimate is the sum of potential rents and expenses listed above and is comparable to the frequently termed “Net Operating Income.”',
    },
    calculator: {
      title: '',
      content:
        'Quickly modify your down payment, rate and length of loan to discover net performance scenarios fitting your preference.',
    },
    netPerformance: {
      title: '',
      content: 'Annual estimate that includes the mortgage cost estimate in the total calculation.',
    },
  },
  error: {
    default: 'Something went wrong. Please try again.',
  },
  cardOverview: {
    title: 'Annual Estimate Summary',
    netPerformance: 'Annual Estimate with Mortgage',
    cashOnCash: 'Cash-on-Cash',
  },
  userMenu: {
    buttonTitle: 'User Menu',
    optionMyAccount: 'My Account',
    optionLogOut: 'Log out',
  },
  auth: {
    login: 'Log In',
    signup: 'Sign Up',
    createLogin: 'Create an account or log in',
    signUpButton: 'New? Sign Up',
    logInButton: 'Have an Account? Log In',
    logInSubmit: 'Log In',
    signUpSubmit: 'Create Account',
    forgotPassword: 'Forgot Password?',
    userPlaceholder: 'your@email.com*',
    passPlaceholder: 'Password*',
    createPassPlaceholder: 'Create password*',
    badCredentials: 'Wrong credentials. Please try again',
    generalError: 'There was a problem trying to process your request',
    emailError: 'Invalid email address',
    authError: 'Incorrect email or password',
    signupError: 'Please verify email or password',
    passwordConfirmError: 'Passwords must match',
    required: 'Required',
    forgotModalTitle: 'Forgot password',
    termsAndConditions: 'I accept the Terms and Conditions and Privacy Policy',
    userType: 'Which best describes you?',
    accept: 'I accept ',
    terms: 'Terms and Conditions and Privacy Policy',
    termsError:
      'You must accept our Terms and Conditions and Privacy Policy in order to create an account.',
    newPassword: 'New password*',
    confirm: 'Confirm new password*',
    accountExists: 'Account already exists. ',
    accountExistsPlease: 'Please ',
    accountExistsLogin: 'login here',
    accountExistsOr: ' or ',
    accountExistsReset: 'reset your password',
    accountExistsNeeded: ' if needed.',
    userTypeRequired: 'You must select a user type',
    selectToFinish: 'Select one to finish',
  },
  catalogs: {
    title: 'Catalogs',
    form: {
      titles: {
        create: 'Create catalog',
        update: 'Edit catalog',
      },
      name: 'Name',
      submit: 'Save',
    },
    error: 'Something went wrong. Please try again',
    catalogBy: 'Catalog by ',
  },
  property: {
    bedrooms: 'Bedrooms',
    bathrooms: 'Bathrooms',
  },
  actions: {
    save: 'SAVE',
    cancel: 'CANCEL',
    send: 'SEND',
  },
  updateCatalog: {
    changeTitle: 'Rename Catalog',
    updateCatalogBy: 'Change catalog by',
    blank: '(blank)',
  },
  shareCatalog: {
    title: 'Share',
    email: 'Email',
    helpEmail: 'Send an access link to your recipient. You will also be copied on the email.',
  },
  catalogMenuOptions: {
    update: 'Rename Catalog',
    share: 'Share',
    updateCatalogBy: 'Change catalog by',
    confirm: 'Confirm',
    delete: 'Delete',
    cancel: 'Cancel',
    removePropertyConfirmation: 'Remove property card from this catalog?',
    deleteCatalogConfirmation: 'Delete catalog?',
    deleteCatalog: '',
    deleteProperty: '',
  },
  cardMenu: {
    addToACatalog: 'Add to a catalog',
    delete: 'Remove from catalog',
    share: 'Share',
  },
  changePassword: {
    title: 'Change Password',
    confirmation: 'Continue',
  },
  createPassword: {
    title: 'Create Password',
    confirmation: 'Continue',
  },
  formErrors: {
    lengthMin: 'Too short',
    lengthMax: 'length limit',
    email: 'Bad email format',
    password: 'Bad password format',
    passwordMatch: "Password doesn't match",
  },
  filters: {
    filter: 'Search',
    save: 'Save',
    share: 'Share',
    list: 'List',
    map: 'Map',
    apply: 'Apply',
    areas: 'Areas',
    bedrooms: 'Bedrooms',
    bathrooms: 'Bathrooms',
    clearFilter: 'Reset Search',
    noResults: 'No Results',
    noResultsHelp: 'To get more results, try adjusting your search',
    error: "Couldn't fetch results. Please try again.",
    favoritesError: 'Something went wrong. Please try again.',
    loginFirst: 'Please Log In and try again.',
    searchMoreButton: 'Send Feedback',
    feedbackSentMessage: 'Thank you for sending us your feedback',
  },
  validations: {
    requiredField: 'This field is required',
    invalidFormat: 'Invalid email format',
  },
  favorites: {
    myFavorites: 'My favorites',
  },
  profileModal: {
    listedBy: 'Listed by ',
  },
  hamburgerMenu: {
    properties: 'Properties',
    favorites: 'Favorites',
    catalogs: 'Catalogs',
    myAccount: 'My Account',
  },
  footer: {
    contact: 'Contact Us',
    terms: 'Terms & Policy',
    copyright: 'Propersum Inc • © {{year}} • all rights reserved.',
  },
  shareModal: {
    share: 'Sharing allows anyone with the link to view this content.',
    emailPlaceholder: 'receiver@email.com',
    copyButton: 'Copy Link',
    sendButton: 'Send',
    sendingEmail: 'Sending...',
    linkCopied: 'Copied!',
    emailError: 'Invalid email address',
    error: 'Error while sharing',
    required: 'Email is required.',
    email: 'Email',
    shared: 'Shared',
  },
  shareSearchModal: {
    share: 'Sharing allows the search to be viewed by anyone with the link.',
    emailPlaceholder: 'receiver@email.com',
    copyButton: 'Copy Link',
    sendButton: 'Share',
    sendingEmail: 'Sharing...',
    linkCopied: 'Copied!',
    emailError: 'Invalid email address',
    error: 'Error while sharing',
    required: 'Email is required.',
    email: 'Email',
    shareSuccess: 'The search was successfully shared!',
  },
  additionalInformation: {
    properScoreTooltip:
      'ProperScore™ is a proprietary scoring system which ranks each vacation property against other vacation properties in-market and available for purchase. Scores may fluctuate with market conditions and as other properties become available or are sold.',
  },
  propertyDetails: {
    title: '{{address}} — Vacation Rental for Sale — Propersum',
    propertiesForSale: 'Properties for Sale',
    search: 'Search',
    emailTitle: 'Email this property to',
    saveToCatalog: 'Save to Catalog',
    managerProposalTitle: 'Vacation Rental Management Proposals',
    propertyDetailDescription: `This property is estimated to produce <0>{{estRentsLow}} to {{estRentsHigh}}</0>, 
    annually in gross rents when utilizing a reputable and professional vacation rental management firm. 
    Property management service fees are estimated between <1>{{estMgmtFeeLow}} to {{estMgmtFeeHigh}}</1>
     of gross rents excluding operating and ownership expenses.`,
    properEstimateTooltip:
      'ProperEstimate™ is the most advanced rent prediction algorithm available and uses real property management data combined with comparable properties in market based on quality, size, location, amenities, and demand to produce a reliable performance estimate trusted by professional Realtors and property investors across the country.',
    location: 'Location',
    loginFirst: 'Please Log In and try again.',
    moreProperties: 'Other properties in the area',
    seeMore: 'See More',
    addProposalModalTitle: 'Add management proposal',
    editProposalModalTitle: 'Edit management proposal',
    requestProposalsSuccess:
      'Request received! You will receive an email once management proposals are ready for this address.',
    requestProposalsError: 'There was an error on our side',
    descriptionMeta:
      'This property is estimated to produce {{estRentsLow}} to {{estRentsHigh}}, annually in gross rents when utilizing a reputable and professional vacation rental management firm.',
    disclaimerTitle: 'Disclaimer',
    disclaimer:
      'Propersum does not provide financial, investment, lending, insurance, management, or real estate advice or services. Real estate agents, real estate agencies, service brokers, vacation property managers, and Propersum are not liable or responsible for errors and omissions in estimated material, proposals, or property expense estimates, and no guarantee can be made for any data, rental regulation requirements, and/or potential income and expenses displayed or omitted. Interest rates and mortgage payments are estimates and mortgage payment estimates do not include insurance and taxes. Propersum is a registered trademark of Propersum, Inc. All rights reserved.',
    listedBy: 'Listed by ',
  },
  forgotModal: {
    forgot: 'Submit your email and we will send you a message with instructions',
    sendButton: 'Send',
    sendingEmail: 'Sending...',
    emailError: 'Invalid email address',
    required: 'Required.',
    error: 'Error while sending email',
    emailPlaceholder: 'you@email.com',
    email: 'Email',
    resetSuccess: 'You will receive an email with reset instructions',
  },
  signupModal: {
    title: 'Create a free account to unlock this content',
  },
  filterModal: {
    reset: 'Reset',
    apply: 'Apply Filters',
  },
  createCatalogModal: {
    label: 'Catalog Name',
    placeholder: 'Custom catalog 1',
    create: 'Create',
    creating: 'Creating...',
    lengthError: 'Name is too long. Max characters: 60.',
    required: 'Name is required',
    defaultError: 'Error creating catalog',
    saveSuccess: 'Catalog created successfully',
  },
  renameCatalogModal: {
    title: 'Rename Catalog',
    label: 'Catalog Name',
    rename: 'Rename',
    renaming: 'Renaming...',
    lengthError: 'Name is too long. Max characters: 60.',
    required: 'Name is required',
    defaultError: 'Error renaming catalog',
    saveSuccess: 'Catalog renamed successfully',
  },
  confirmationCatalogModal: {
    yes: 'Yes',
    no: 'No',
    defaultError: 'Something went wrong. Please try again later.',
    unauthorizedError: 'You do not have permission to do that.',
  },
  professionalInfo: {
    email: 'Email*',
    emailPlaceholder: 'your@email.com',
    phone: 'Phone (Optional)',
    phonePlaceholder: '(XX) X - XXX - XXXX',
    textArea: 'Your Message',
    send: 'Send',
    emailRequired: 'Email is required.',
    textRequired: 'Message is required.',
    sendingEmail: 'Sending...',
    error: 'Error while sending email',
    view: 'View',
    edit: 'Edit',
    sent: 'Message sent',
    loginFirst: 'Please Log In and try again.',
  },
  catalogListCard: {
    shareTooltip:
      'Unlock your Catalog before you share it. Once unlocked it will be available to everyone',
    share: 'Share',
    options: 'Options',
    property: 'Property',
    properties: 'Properties',
    avatar: '',
  },
  catalogList: {
    duplicate: 'Duplicate Catalog',
    hideModalTitle: 'Hide Catalog?',
    hide: 'Hide Catalog',
    rename: 'Rename Catalog',
    delete: 'Delete Catalog',
    error: 'There was an error fetching your catalogs.',
    lockMessage: 'Locking a catalog will make it inaccessible',
    unlockMessage: 'Unlocking a catalog will make it accessible',
    hideMessage: 'Hiding a catalog will make it permanently hidden from your view. Continue?',
    deleteMessage: 'Are you sure you want to delete this catalog?',
    duplicateMessage: 'Are you sure you want to duplicate this catalog?',
    lockedTitle: 'Lock this catalog?',
    confirmLockedBtnLabel: 'Yes, Lock it',
    confirmUnlockedBtnLabel: 'Yes, Unlock it',
    unlockedTitle: 'Unlock this catalog?',
    emailTitle: 'Email this catalog to',
    notOwnerWarning: 'You must be the owner of the catalog to do that.',
    snackbarLockMessage: 'Catalog Locked',
    snackbarUnlockMessage: 'Catalog Unlocked',
    share: 'Share Catalog',
  },
  saveToCatalogModal: {
    error: 'There was an error fetching your catalogs.',
    or: 'or',
    newCatalogTitle: 'Create Catalog and Save',
    create: 'Create Catalog',
    saveSuccess: 'Address saved. To view the catalog now ',
    saveSuccessLink: 'click here.',
  },
  dashboard: {
    title: 'Dashboard - {{tab, capitalize}}',
    account: 'My Account',
    accountMobile: 'Account',
    catalogs: 'My Favorites & Catalogs',
    catalogsMobile: 'Catalogs',
    settings: 'Settings',
    proposals: 'Proposals',
    profile: 'Profile',
  },
  userHeader: {
    addName: 'Add Name',
    addAddress: 'Add Address',
    addPhone: 'Add Phone',
    editProfile: 'Edit profile',
    viewProfile: 'View profile',
    updateProfile: 'update profile',
    visitSite: 'Visit Site',
  },
  companyCard: {
    addLogo: 'No Company Logo',
    addCompanyName: 'No Company Name',
    addCompanyAddress: 'No Company Address',
    addCompanyWebsite: 'No Company website',
  },
  saveToNewCatalogModal: {
    label: 'Catalog Name',
    placeholder: 'Custom catalog 1',
    create: 'Create and Save',
    creating: 'Creating...',
    lengthError: 'Name is too long. Max characters: 60.',
    required: 'Catalog name is required',
    defaultError: 'Error creating catalog',
    saveSuccess: 'The property was saved to your new catalog',
  },
  saveSearchModal: {
    label: 'Search Name',
    placeholder: 'Custom Search 1',
    create: 'Save',
    creating: 'Saving...',
    lengthError: 'Name is too long. Max characters: 60.',
    required: 'Search name is required',
    defaultError: 'Error creating catalog',
    trailingInfo: 'Your search will be saved to your catalogs.',
    saveSuccess: 'Your search was saved to your catalogs',
  },
  requestAccessModal: {
    legend:
      'This catalog is locked by the owner. Click below to send a request to make the catalog public.',
    sendButton: 'Send',
    sendingEmail: 'Sending...',
    emailPlaceholder: 'youremail@email.com',
    emailError: 'Invalid email address',
    error: 'Error while sending',
    required: 'Email is required.',
  },
  brandedCatalog: {
    emptyCatalog: 'Empty catalog',
    searchProperties: 'Search properties',
    options: 'Options',
    page: {
      title: 'Propersum',
      error: 'There was an error on our side',
    },
    duplicate: 'Duplicate Catalog',
    hideModalTitle: 'Hide Catalog?',
    hide: 'Hide Catalog',
    rename: 'Rename Catalog',
    delete: 'Delete Catalog',
    share: 'Share Catalog',
    error: 'There was an error fetching catalog',
    lockMessage: 'Locking a catalog will make it inaccessible',
    unlockMessage: 'Unlocking a catalog will make it accessible',
    hideMessage: 'Hiding a catalog will make it permanently hidden from your view. Continue?',
    deleteMessage: 'Are you sure you want to delete this catalog?',
    duplicateMessage: 'Are you sure you want to duplicate this catalog?',
    lockedTitle: 'Lock this catalog?',
    unlockedTitle: 'Unlock this catalog?',
    confirmLockedBtnLabel: 'Yes, Lock it',
    confirmUnlockedBtnLabel: 'Yes, Unlock it',
    shareSearchModalTitle: 'Share',
    goHome: 'Go Home',
    property: {
      delete: 'Are you sure you want to delete this property?',
      deleteMessageConfirmation: 'Property removed',
      renameCallbackMessage: 'Catalog has been renamed',
    },
    requestAccessTitle: 'Locked Catalog',
    successRequest:
      'Catalog owner emailed requesting access for you. You will receive an email once this catalog is available.',
    loginFirst: 'Please Log In and try again.',
    defaultError: 'Something went wrong. Please try again later.',
    unauthorizedError: 'You do not have permission to do that.',
    snackbarLockMessage: 'Catalog Locked',
    snackbarUnlockMessage: 'Catalog Unlocked',
  },
  managementProposalInput: {
    grossRentPlaceholder: '65',
    feePlaceholder: '20',
    add: 'Add proposal',
    edit: 'Edit proposal',
    error: 'There was an error',
    accepting: 'Accepting',
    pending: 'Posting Soon',
    posted: 'Posted',
    inactive: 'Inactive',
    posting: 'Posting',
    acceptingLabel: 'Post',
    pendingLabel: 'Save',
    inactiveTooltip: 'This property is unavailable',
    typeError: 'You must specify a number between 0 and 100.',
    validationNumber: 'Number must be between 0 and 100.',
    defaultError: 'Something went wrong. Please try again.',
  },
  addProposalModal: {
    title: 'Add Your Proposal',
    grossRent: 'My Estimate for Annual Gross Rents',
    grossRentSecond: 'Enter "65" for $65,000',
    fee: 'My Management Fee',
    continue: 'Continue',
    required: 'Required',
    sending: 'Sending...',
    typeError: 'You must specify a number between 0 and 100.',
    validationNumber: 'Number must be between 0 and 100.',
    defaultError: 'Something went wrong. Please try again later.',
    forbiddenError: 'You do not have permission to do that.',
    numberError: 'Gross rent must be a number.',
    successSnackbar: 'Management proposal successfully posted!',
    messageConfirmation: 'Learn how to add unlimited proposals with Propersum for Managers.',
    modalTitle: 'Adding More Proposals',
    updateProfileSnackbar:
      'Proposal scheduled. <0>Click here</0> to update your name and company information.',
  },
  myProposals: {
    error: 'There was an error on our side',
    property: 'Property',
    bdsBa: 'Bds/Ba',
    sqft: 'Sqft',
    grossRent: 'Gross Rent',
    fee: 'Fee',
    status: 'Status',
    filter: 'Filter by: ',
    available: 'Available',
    postingSoon: 'Posting Soon',
    posted: 'Posted',
    inactive: 'Inactive',
    all: 'All',
    successSnackbar: 'Proposal scheduled for posting.',
    addProposalModalTitle: 'Add management proposal',
    editProposalModalTitle: 'Edit management proposal',
    updateProfileSnackbar:
      'Posted! Increase your chances of conversion by <0>updating your profile</0>',
  },
  privateRoute: {
    signUpModalTitle: 'Create a free account to unlock this content',
  },
  guidanceCard: {
    go: 'Go',
  },
  marketMembershipCard: {
    active: 'Active Membership',
    inactive: 'Expired Membership',
    market: 'Market',
  },
  settings: {
    yourSettings: 'Your Settings',
    saveSettingSuccess: 'Setting saved successfully',
    saveSettingError: 'There was an error saving your setting, please try again later',
  },
  account: {
    profile: 'My Account',
    companyProfile: 'My Professional Profile',
    marketMemberships: 'Market Memberships',
    error: 'There was an error updating your information',
    successSaved: 'Saved',
    incompleteAddress: 'A valid address update must have every field entered or every field empty',
  },
  accountProfile: {
    uploadPhoto: 'Upload Photo',
    nameLabel: 'Name *',
    name: '',
    email: 'Email *',
    emailPlaceholder: 'your-email@gmail.com',
    phoneLabel: 'Phone Number',
    phone: '(333) 333-3333',
    userTypeLabel: 'Which best describes you? *',
    save: 'Save',
    tooLargeError: 'File Size is too large',
    formatError: 'Unsupported File Format',
    requiredError: 'This field is required',
    stringError: 'This field must be a string',
    numberError: 'This field must be a number',
    emailError: 'Invalid email address',
  },
  companyProfile: {
    uploadPhoto: 'Upload Photo',
    requiredError: 'This field is required',
    descriptionLabel: 'Description',
    description:
      'Helpful Tip: Tell your prospective clients about your company, why it’s the best choice.',
    nameLabel: 'Name',
    name: 'Company Co.',
    professionLabel: 'Title / Profession',
    websiteLabel: 'Website',
    website: 'https://companywebsite.com',
    phoneLabel: 'Phone Number',
    companyPhoneLabel: 'Company Phone Number',
    phone: '333-333-3333',
    companyPhone: 'Company Phone',
    videoLabel: 'Video',
    video: 'Paste YouTube video URL',
    dateLabel: 'Year of Company Foundation',
    date: '2004',
    unitsLabel: 'Managed Units',
    units: '13',
    employeesLabel: 'Full Time Employees',
    employees: '33',
    technologiesLabel: 'Technologies and Services Used',
    technologies: 'What tech and services are you using?',
    logoLabel: 'Logo',
    backgroundLabel: 'Background image',
    save: 'Save',
    streetLabel: 'Address',
    street: '',
    cityLabel: 'City',
    city: '',
    stateLabel: 'State',
    state: '',
    zipcodeLabel: 'Postal Code',
    zipcode: '',
    videoUrlError: 'The video must be a YouTube URL',
    stringError: 'This field must be a string',
    numberError: 'This field must be a number',
    urlError: 'Website should be a valid URL',
    invalidYear: 'Year must be 4 digits',
  },
  feedbackModal: {
    title: 'Adding More Proposals',
    continue: 'Continue',
    messageConfirmation: 'Learn how to add unlimited proposals with Propersum for Managers.',
    defaultError: 'Something went wrong. Please try again.',
  },
  estimatesFilter: {
    managerProposalsOnly: 'Manager Proposals only',
    propersumEstimatesOnly: 'Propersum Estimates only',
    both: 'Both',
  },
  propertyMarketComparation: {
    annualEstimates: 'Annual Estimates',
    capRate: 'Cap Rate',
    cashFlow: 'Cash Flow',
    cashOnCash: 'Cash-on-Cash',
    grossYield: 'Gross Yield',
    market: 'Market',
    opexRatio: 'OpEx Ratio',
    property: 'Property',
    capRateTooltip:
      "Cap Rate (or Capitalization Rate) is calculated by combining all income and expenses (excluding mortgage payments) divided by the property's value. PRO TIP: Cap Rate is a commonly used metric by experienced investors for quickly comparing properties.",
    grossYieldTooltip:
      "Gross Yield is a fairly simple calculation of gross rents divided by the property's market value. PRO TIP: Gross Yield is a commonly used metric by experienced investors for quickly comparing properties.",
    opexRatioTooltip:
      "Operating Expense Ratio, also known as OpEx Ratio or OER, is a measurement of the cost to operate a rental property compared against the rents collected. In short, costs vs rents. It is calculated by dividing a property's expenses (excluding mortgage payments) by the expected rents. PRO TIP: OpEx Ratio is commonly used by investors for quickly identifying which properties have more or less expenses. Note: Propersum does not calculate or include depreciation in any figures including the OpEx Ratio.",
    cashFlowTooltip:
      'Cash Flow combines all income and expenses shown, including taxes and insurance as well as mortgage payments (if any) to show how much actual cash production is estimated over the course of a year. PRO TIP: Many investors seek a negative cash flows to offset gains from their other real estate investments, and therefore, reduce or avoid their taxable income. New investors may be able to reduce taxable income with a tailored down payment so consult with your tax advisor on the best strategy for your individual financial needs.',
    cashOnCashTooltip:
      'Cash-on-Cash compares annual Cash Flow to the cash invested (in this case, the down payment). Note: Without a mortgage, the down payment is effectively 100%. Traditionally, Cash-on-Cash can also include additional costs for expenses such as but not limited to acquisition costs, like closing costs and legal fees, however Propersum does not include these.',
  },
  blurredElement: {
    unlockTitle: 'Create an account or log in to unlock',
    upgrade: 'Upgrade',
    unlock: 'Unlock',
  },
  upgradeModal: {
    title: 'Upgrade to Premium for exclusive access',
    location: 'Location',
    quarterly: '${{quarterLabel}} Quarterly',
    annualy: '${{annualLabel}} Annually',
    footer: {
      properScore: '<bold>ProperScore</bold> property ranking',
      financials: 'Property <bold>financials</bold> (cap rate, etc)',
      hotSheets: 'Propersum <bold>Hot Sheets</bold> emails',
    },
  },
  payment: {
    success:
      'Thank you for purchasing a market. You will receive an email confirmation as receipt.',
    error: 'An unexpected error occurred.',
  },
};

import React, { useEffect, useState } from 'react';
import { CircularProgress } from '@material-ui/core';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

import { getClientSecret } from 'services/stripeService';

import PaymentElement from './PaymentElement';
import { useStyles } from './styles';

const CheckoutModal = ({ location, quarterly, price }) => {
  const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);
  const [clientSecret, setClientSecret] = useState(null);

  const classes = useStyles();

  useEffect(() => {
    const getStripeClientSecret = async () => {
      const secret = await getClientSecret({
        type: 'viewer',
        period: quarterly ? 'quarter' : 'annual',
        area: location.slug,
      });
      setClientSecret(secret);
    };
    getStripeClientSecret();

    return () => {
      setClientSecret(null);
    };
  }, []);

  const appearance = {
    theme: 'stripe',
    variables: {
      colorPrimary: '#41B060',
    },
  };
  const options = {
    clientSecret,
    appearance,
  };

  return (
    <>
      {!clientSecret ? (
        <div className={classes.loading}>
          <CircularProgress color="primary" />
        </div>
      ) : (
        <Elements options={options} stripe={stripePromise}>
          <PaymentElement label={location.name} price={price} />
        </Elements>
      )}
    </>
  );
};

export default CheckoutModal;

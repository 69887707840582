import React, { useState } from 'react';
import ReactGA from 'react-ga';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { CircularProgress } from '@material-ui/core';

import ForgotPasswordModal from 'modals/ForgotPasswordModal';
import { useDialog } from 'providers/DialogProvider';
import { useSnackbar } from 'providers/SnackbarProvider';
import { useUserContext } from 'providers/UserProvider';
import routes from 'router/routes';
import { executeLogin, signUp } from 'services/authService';

import CreateForm from './CreateForm';
import LoginForm from './LoginForm';
import { useStyles } from './styles';

const AccessModal = ({ title }) => {
  const [openDialog, closeDialog, clearDialogs] = useDialog();
  const [openSnackbar] = useSnackbar();
  const { reload } = useUserContext();
  const [userType, setUserType] = useState('');
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const [showError, setShowError] = useState(false);
  const [message, setMessage] = useState('');
  const [showSecondStep, setShowSecondStep] = useState(false);
  const history = useHistory();

  const classes = useStyles();
  const { t } = useTranslation('auth');

  const login = async (username, password) => {
    if (!loading) {
      setLoading(true);
      const success = await executeLogin(username, password);
      if (!success) {
        setShowError(true);
      } else {
        reload();
        clearDialogs();
      }
    }
    setLoading(false);
  };

  const openForgotPasswordModal = () => {
    closeDialog({ silent: true });
    openDialog({
      children: <ForgotPasswordModal />,
      logo: true,
      onKill: () => history.push(routes.results),
      blurred: true,
    });
  };

  const onSubmitSignUp = async () => {
    try {
      setShowError(false);
      if (!loading) {
        setLoading(true);
        if (!userType) {
          setMessage(t('userTypeRequired'));
          setShowError(true);
          setLoading(false);
          return;
        }
        const response = await signUp(username, password, userType.value);
        if (response && response.status === 200) {
          await login(username, password);

          ReactGA.event({
            action: 'finished_registration',
            category: 'registration',
            value: '1',
          });

          clearDialogs();
        }
        setLoading(false);
      }
    } catch (error) {
      setShowSecondStep(false);
      if (error?.response?.data?.error === 'account already exists') {
        openSnackbar({
          children: (
            <>
              {t('accountExists')}
              {t('accountExistsPlease')}
              <span className={classes.onClick}>{t('accountExistsLogin')}</span>
              {t('accountExistsOr')}
              <span onClick={openForgotPasswordModal} className={classes.onClick}>
                {t('accountExistsReset')}
              </span>
              {t('accountExistsNeeded')}
            </>
          ),
          variant: 'error',
        });
      } else {
        openSnackbar({ children: t('generalError'), variant: 'error' });
      }
      setLoading(false);
    }
  };

  return (
    <>
      {loading ? (
        <div className={classes.loading}>
          <CircularProgress color="primary" />
        </div>
      ) : (
        <>
          {!showSecondStep && (
            <LoginForm
              errorMessage={message}
              setShowSecondStep={setShowSecondStep}
              setUsername={setUsername}
              setPassword={setPassword}
              title={title || t('createLogin')}
            ></LoginForm>
          )}
          {showSecondStep && (
            <CreateForm
              setShowSecondStep={setShowSecondStep}
              openForgotPasswordModal={openForgotPasswordModal}
              onSubmitSignUp={onSubmitSignUp}
              setUsertypeFn={setUserType}
              loading={loading}
              showError={showError}
              message={message}
            ></CreateForm>
          )}
        </>
      )}
    </>
  );
};

export default AccessModal;

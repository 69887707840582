import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(4),
    paddingBottom: '5em',
    paddingTop: '3.5em',
  },
  actions: {
    bottom: 0,
    left: 0,
    right: 0,
    position: 'fixed',
    padding: theme.spacing(4),
    boxShadow: '0 -4px 4px rgba(0,0,0,0.05)',
    background: theme.palette.secondary.main,
  },
  closeModal: {
    position: 'absolute',
    top: 0,
    right: 0,
    padding: theme.spacing(4),
  },
}));

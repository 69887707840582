import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { generatePath, Link, useHistory } from 'react-router-dom';
import { Hidden, IconButton, Typography } from '@material-ui/core';
import capRateBlurred from 'assets/img/blurred/cap-rate.png';
import properScoreBlurred from 'assets/img/blurred/proper-score.png';
import clsx from 'clsx';
import Numeral from 'numeral';

import useIsMobile from 'hooks/useIsMobile';
import AccessModal from 'modals/AccessModal';
import SaveToCatalogModal from 'modals/SaveToCatalogModal';
import { useDialog } from 'providers/DialogProvider';
import { useSearchContext } from 'providers/SearchContextProvider';
import { useSnackbar } from 'providers/SnackbarProvider';
import { useUserContext } from 'providers/UserProvider';
import routes from 'router/routes';
import shuffleArray from 'utils/shuffleArray';

import Icon from 'components/atoms/Icon';
import Menu from 'components/atoms/Menu';
import SinglePhotoCarousel from 'components/atoms/SinglePhotoCarousel';
import ValuePill from 'components/atoms/ValuePill';
import AvatarGroup from 'components/molecules/AvatarGroup';
import FavoriteButton from 'components/molecules/FavoriteButton';

import { useStyles } from './styles';

import BlurredElement from '../BlurredElement';
import ProperScore from '../ProperScore';

/**
 * Renders a property card with details
 *
 * @param {object} property - An object with address, area, value, annualRents, features, images, and managers
 * @param {string} property.address - A string that represents the property address
 * @param {string} property.area - A string that represents the property area
 * @param {number} property.value - A number that represents the property value
 * @param {number} property.annualRents - A number that represents the property annual rents
 * @param {object} property.features - An object with beds (number), bathrooms (number), sqft (number)
 * @param {array} property.images - An array of objects with src (string) and alt (string), this images will appear in the photo gallery
 * @param {array} property.managers - An array of objects with name(string), company(string) and image(string).
 * @param {string} size - large or small (large by default)
 * @param {function} handleClick - Function to handle a click on the card
 * @param className optional styles
 */
const PropertyListCard = ({
  property,
  size = 'large',
  className,
  handleDeletePropertyFromCatalog,
  displayAllProposals,
}) => {
  const classes = useStyles({ size });
  const history = useHistory();
  const isMobile = useIsMobile();
  const isXsmall = useIsMobile('xs');
  const { t } = useTranslation('propertyCard');
  const [openDialog] = useDialog();
  const [anchorEl, setAnchorEl] = useState(null);
  const { fromSearch } = useSearchContext();
  const { user, loading: userLoading, canView } = useUserContext();
  const [openSnackbar] = useSnackbar();

  const openSaveModal = () => {
    if (!userLoading && !user) {
      openSnackbar({
        children: t('loginFirst'),
      });
      openDialog({
        maxWidth: 'xs',
        logo: true,
        blurred: true,
        children: <AccessModal />,
      });
    } else {
      openDialog({
        children: <SaveToCatalogModal properties={[property.id]} />,
        title: t('options.save'),
      });
    }
    closeMenu();
  };

  const handleDelete = async () => {
    handleDeletePropertyFromCatalog(property.id);
  };

  const closeMenu = () => {
    setAnchorEl(null);
  };

  const openMenu = (e) => {
    setAnchorEl(e.currentTarget);
  };

  const menuOptions = [
    { label: t('options.save'), action: openSaveModal },
    ...(handleDeletePropertyFromCatalog
      ? [{ label: t('options.delete'), action: handleDelete }]
      : []),
  ];

  const shuffledManagers = useMemo(() => property?.managers && shuffleArray(property.managers), [
    property,
  ]);

  const determineRouteForProperty = () => {
    if (displayAllProposals) {
      return routes.moreDetails;
    } else {
      return routes.property;
    }
  };

  const handleClick = () => {
    history.push({
      pathname: generatePath(determineRouteForProperty(), { slug: property.slug }),
      state: { fromSearch },
    });
  };

  return (
    <div className={clsx(classes.root, className, isMobile && !isXsmall ? classes.smCard : null)}>
      <div className={classes.images}>
        <div className={classes.imageCarousel}>
          <SinglePhotoCarousel
            photos={property.images}
            width={isMobile ? '7.9em' : size === 'large' ? '16.8125em' : '11.125em'}
            height={isMobile ? '9.5em' : '14.3125em'}
            handleClick={handleClick}
          />
        </div>
        {!isMobile && (
          <div className={classes.top}>
            <FavoriteButton property={property} />
            <IconButton size="medium" onClick={openMenu} className={classes.actionIcon}>
              <Icon name={'ellipsis'} size={'1.5em'} color={'secondary'} />
            </IconButton>
            <Menu options={menuOptions} anchorEl={anchorEl} onClose={closeMenu} open={!!anchorEl} />
          </div>
        )}
        <div className={classes.bottom}>
          <p className="longNumber">{Numeral(property.value).format('$0,0')}</p>
          <p className="shortNumber">{Numeral(property.value).format('$0.0a')}</p>
        </div>
      </div>
      <Link
        className={classes.information}
        to={{
          pathname: generatePath(determineRouteForProperty(), { slug: property.slug }),
          state: { fromSearch },
        }}
      >
        <div className={classes.header}>
          <div className={classes.infoWrapper}>
            <div className={classes.info}>
              <div className={classes.title}>
                <p className={clsx(classes.address, isMobile ? classes.smText : null)}>
                  {property.address}
                </p>
                <p className={clsx(classes.area, isMobile ? classes.smText : null)}>
                  <span>{property.city} </span>
                  <span>{property.state} </span>
                  <span>
                    <Hidden xsDown>{property.postalCode}</Hidden>
                  </span>
                </p>
              </div>
              <div className={classes.infoFinancial}>
                <Hidden xsDown>
                  <Typography>ProperScore</Typography>
                </Hidden>

                {!canView(property?.area) ? (
                  <BlurredElement
                    height={'64px'}
                    icon={true}
                    image={properScoreBlurred}
                    width={'64px'}
                    propertyArea={property?.area}
                  />
                ) : (
                  <ProperScore
                    propValue={property?.properScore}
                    className={classes.properScore}
                    thickness={7}
                    size={isMobile ? 4 : 2.9}
                    fontSize={isMobile ? '1.2rem' : '1rem'}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
        <div className={clsx(classes.features, isMobile && !isXsmall ? classes.smText : null)}>
          <div>
            <strong>{property.features.beds} </strong>
            <span>bds </span>

            <strong>{property.features.bathrooms} </strong>
            <span>ba </span>

            <strong>{property.features.sqft} </strong>
            <span>sq ft</span>
          </div>

          <Hidden xsDown>
            <div className={classes.infoFinancial}>
              <Typography>{t('capRate')}</Typography>

              {!canView(property?.area) ? (
                <BlurredElement
                  height={'40px'}
                  icon={true}
                  image={capRateBlurred}
                  width={'57px'}
                  propertyArea={property?.area}
                />
              ) : (
                <Typography color="primary" variant="h6">
                  {property.capRate}%
                </Typography>
              )}
            </div>
          </Hidden>
        </div>

        <div className={classes.estimatesBottom}>
          {shuffledManagers ? (
            <div className={classes.avatarGroupContainer}>
              <AvatarGroup
                avatars={
                  isMobile && shuffledManagers.length > 1
                    ? shuffledManagers.slice(0, 1)
                    : shuffledManagers
                }
                size={isMobile ? 'small' : 'medium'}
              />

              <p className={classes.manager}>
                <span>{shuffledManagers[0].name}</span>
                <br />
                {shuffledManagers[0].company}
              </p>
            </div>
          ) : (
            <>
              {!isMobile ? (
                <div className={classes.estimatesLong}>{t('withoutProposals')}</div>
              ) : null}
              {isMobile && (
                <div className={classes.estimatesShort}>
                  <p>{t('properEstimate')}</p>
                </div>
              )}
            </>
          )}

          <div className={classes.infoFinancial}>
            <Hidden xsDown>
              <Typography>{t('rents')}</Typography>
            </Hidden>
            <ValuePill
              type="halfRound"
              value={
                shuffledManagers?.length > 0
                  ? `${Numeral(shuffledManagers[0].grossRents).format('$0a')}/yr`
                  : `${Numeral(property.annualRents).format('$0a')}/yr`
              }
            />
          </div>
        </div>
      </Link>
    </div>
  );
};
export default PropertyListCard;

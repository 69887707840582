import Snackbar from '@material-ui/core/Snackbar';

import { useStyles } from './styles';

import Icon from '../Icon';

const variants = {
  default: '#000',
  success: '#33B557',
  warning: '#F56333',
  error: '#DE003C',
};
/**
 *
 * @param {String} verticalPosition could be TOP or BOTTOM
 * @param {String} horizontalPosition could be LEFT, RIGHT or CENTER
 * @param {String} variant background color of the snackbar. Could be DEFAULT, SUCCESS, WARNING or ERROR
 */
const SnackBar = ({
  verticalPosition: vertical = 'bottom',
  horizontalPosition: horizontal = 'center',
  open,
  handleClose = () => null,
  variant = 'default',
  children,
  autoHideDuration = 4000,
  ...rest
}) => {
  const classes = useStyles({ variant: variants[variant] });
  return (
    <div>
      <Snackbar
        className={classes.root}
        anchorOrigin={{ vertical, horizontal }}
        open={open}
        onClose={handleClose}
        key={vertical + horizontal}
        autoHideDuration={autoHideDuration}
        {...rest}
      >
        <span className={classes.messageWrapper}>
          {children}
          <span className={classes.iconWrapper}>
            <Icon name="close" color="#fff" onClick={() => handleClose()} />
          </span>
        </span>
      </Snackbar>
    </div>
  );
};

export default SnackBar;

import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { InputAdornment, Typography } from '@material-ui/core';
import Numeral from 'numeral';

import useIsMobile from 'hooks/useIsMobile';

import Checkbox from 'components/atoms/Checkbox';
import Input from 'components/atoms/Input';
import LineSeparator from 'components/atoms/LineSeparator';

import { useStyles } from './styles';

const emptyCheckboxSelection = {
  all_estimates: false,
  estimates: false,
  proposals: false,
};
const initialCheckboxSelection = {
  all_estimates: true,
  estimates: false,
  proposals: false,
};

const EstimatesChildren = ({ currentSelection, onChange }) => {
  const { t } = useTranslation('estimatesFilter');

  const { est_from, est_to } = currentSelection;
  const classes = useStyles();
  const isMobile = useIsMobile();

  const [checkboxSelection, setCheckboxSelection] = useState(initialCheckboxSelection);

  useEffect(() => {
    setCheckboxSelection({ ...currentSelection });
  }, [currentSelection]);

  const handleChange = (handler, event) => {
    onChange({ ...currentSelection, [handler]: Numeral(event.target.value).value() });
  };

  const handleCheckboxChange = (handler) => {
    let newSelection = { ...emptyCheckboxSelection };
    newSelection[handler] = true;
    if (
      (newSelection.estimates && newSelection.proposals) ||
      (!newSelection.estimates && !newSelection.proposals)
    ) {
      newSelection['estimates'] = false;
      newSelection['proposals'] = false;
      newSelection['all_estimates'] = true;
    }

    setCheckboxSelection(newSelection);
    onChange({ ...currentSelection, est_from, est_to, ...newSelection });
  };

  return (
    <div className={classes.childRoot}>
      {!isMobile && (
        <Typography variant="body1" className={classes.title}>
          Annual Rent Estimates
        </Typography>
      )}
      <div className={classes.row}>
        <div className={classes.minMaxRow}>
          <Typography variant="body1" className={classes.middleText}>
            Min
          </Typography>
          <Input
            value={est_from && Numeral(est_from).format('0,0')}
            disabled={false}
            onChange={(event) => handleChange('est_from', event)}
            placeholder={'0'}
            startAdornment={<InputAdornment position="start">$</InputAdornment>}
          />
        </div>
        <div className={classes.separator} />
        <div className={classes.minMaxRow}>
          <Typography variant="body1" className={classes.middleText}>
            Max
          </Typography>
          <Input
            value={est_to && Numeral(est_to).format('0,0')}
            disabled={false}
            onChange={(event) => handleChange('est_to', event)}
            placeholder={'∞'}
            startAdornment={<InputAdornment position="start">$</InputAdornment>}
          />
        </div>
      </div>
      {!isMobile && <LineSeparator margin={'1.5em 0'} />}{' '}
      <Typography variant="body1" className={classes.title}>
        Show Results with
      </Typography>
      <div className={classes.checkBoxWrapper}>
        <Checkbox
          checked={checkboxSelection.proposals && !checkboxSelection.estimates}
          handleChange={() => handleCheckboxChange('proposals')}
          label={t('managerProposalsOnly')}
        />
        <Checkbox
          checked={checkboxSelection.estimates && !checkboxSelection.proposals}
          handleChange={() => handleCheckboxChange('estimates')}
          label={t('propersumEstimatesOnly')}
        />
        <Checkbox
          checked={
            checkboxSelection.all_estimates ||
            (checkboxSelection.estimates && checkboxSelection.proposals) ||
            (!checkboxSelection.estimates && !checkboxSelection.proposals)
          }
          handleChange={() => handleCheckboxChange('all_estimates')}
          label={t('both')}
        />
      </div>
    </div>
  );
};
export default EstimatesChildren;

import { useCallback, useRef, useState } from 'react';

/**
 * Usage: `const reload = useForceRender();`
 * Invoke reload if you want to force a render on the component
 *
 * @return {function(): void}
 */
const useForceRender = () => {
  const [value, set] = useState(true);
  const valueRef = useRef(value);
  valueRef.current = value;
  return useCallback(() => set(!valueRef.current), [value]);
};

export default useForceRender;

const MuiSelect = {
  root: {
    padding: 15,
  },
  outlined: {
    paddingRight: '15px !important',
  },
};

export default MuiSelect;

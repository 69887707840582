import React from 'react';

import CreateCatalogModal from 'modals/CreateCatalogModal';

const SaveSearchModal = ({ properties, ...rest }) => {
  return (
    <CreateCatalogModal
      properties={properties}
      i18nKey={'saveSearchModal'}
      trailingInfo
      {...rest}
    />
  );
};

export default SaveSearchModal;

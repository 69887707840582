import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box } from '@material-ui/core';

import AccessModal from 'modals/AccessModal';
import UpgradeModal from 'modals/UpgradeModal';
import { useDialog } from 'providers/DialogProvider';
import { useUserContext } from 'providers/UserProvider';

import Button from 'components/atoms/Button';
import Icon from 'components/atoms/Icon';

import { useStyles } from './styles';

const BlurredElement = ({
  propertyArea,
  image,
  button = false,
  icon = false,
  width,
  height,
  ...rest
}) => {
  const { user } = useUserContext();
  const [openDialog] = useDialog();
  const { t } = useTranslation('blurredElement');

  const [display, setDisplay] = useState('none');
  const classes = useStyles({ image, button, width, height, display });

  const handleClickImage = (e) => {
    e.preventDefault();
    setDisplay('block');
  };

  const handleClickAction = (e) => {
    e.preventDefault();
    setDisplay('none');

    openDialog({
      children: user ? (
        <UpgradeModal propertyArea={propertyArea} />
      ) : (
        <AccessModal title={t('unlockTitle')} />
      ),
      logo: true,
    });
  };

  return (
    <Box
      className={classes.root}
      onClick={handleClickImage}
      onMouseEnter={() => {
        if (button || icon) setDisplay('block');
      }}
      onMouseLeave={() => {
        if (button || icon) setDisplay('none');
      }}
      {...rest}
    >
      {button && (
        <Button
          style={{ display: display }}
          label={user ? t('upgrade') : t('unlock')}
          onClick={handleClickAction}
        />
      )}
      {icon && (
        <Box display={display} className={classes.icon} onClick={handleClickAction}>
          <Icon name={'openLock'} />
        </Box>
      )}
    </Box>
  );
};

export default BlurredElement;

import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(4),
  },
  container: {
    minHeight: '18em',
    display: 'flex',
    flexDirection: 'column',
    alignContent: 'space-around',
    justifyContent: 'space-around',
  },
  contentFields: {
    display: 'flex',
    flexDirection: 'column',
    gap: '1rem',
  },
  inputWrapper: {
    marginTop: theme.spacing(1.8),
  },
  label: {
    textAlign: 'center',
    paddingBottom: theme.spacing(1.8),
  },
  actions: {
    padding: 0,
    marginTop: theme.spacing(3.2),
    '& button': {
      '&:not(:first-child)': {
        marginLeft: 0,
      },
    },
  },
  errorAuthWrapper: {
    paddingTop: '1em',
  },
  errorAuth: {
    margin: '0',
    fontSize: '1em',
    fontWeight: '500',
    lineHeight: '1.5em',
    color: theme.palette.error.main,
  },
  labelWrapper: {
    '& label': {
      margin: 0,
      fontSize: '1rem',
      lineHeight: '24px',
    },
    '& span': {
      paddingTop: 0,
      marginTop: 0,
    },
    display: 'flex',
    marginTop: theme.spacing(1.8),
  },
  or: {
    margin: theme.spacing(2),
  },
  button: {
    fontSize: '0.9em',
    color: theme.palette.text.primary,
    whiteSpace: 'normal',
  },
  checkbox: {
    fontSize: '0.92em',
  },
  onClick: {
    cursor: 'pointer',
    textDecoration: 'underline',
  },
  forgotPasswordWrapper: {
    textAlign: 'center',
    paddingTop: '1em',
  },
  forgotPassword: {
    margin: '0',
    fontSize: '1em',
    fontWeight: '500',
    lineHeight: '1.5em',
    color: 'rgba(0, 0, 0, 0.6);',
    cursor: 'pointer',
  },
  loading: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    minWidth: 'calc(100% - 4em)',
    minHeight: '20em',
  },
}));

import React, { useEffect, useState } from 'react';

const AreaDescription = ({ fullDescription }) => {
  const [description, setDescription] = useState(null);

  const generateDescription = () => {
    const regExpURL = /\((.+?)\)/;
    const matches = regExpURL.exec(fullDescription);

    if (matches?.length) {
      const description = fullDescription.replace(matches[0], '');
      generateAnchorLink(description, matches[1]);
    } else {
      setDescription(fullDescription);
    }
  };

  const generateAnchorLink = (description, url) => {
    const regExpURL = /\[(.+?)\]/;
    const matches = regExpURL.exec(description);
    const anchorLink = `<strong><a href=${url} target={'_blank'} rel={'noreferrer'}>${matches[1]}</a></strong>`;
    const result = description.replace(matches[0], anchorLink);
    setDescription(result);
  };

  useEffect(() => {
    generateDescription();
  }, [fullDescription]);

  return description && <p dangerouslySetInnerHTML={{ __html: description }} />;
};

export default AreaDescription;

import React from 'react';
import { useTranslation } from 'react-i18next';
import { Typography } from '@material-ui/core';

import Toggle from 'components/atoms/Toggle';

import { useStyles } from './styles';

/**
 * @param current (list|map)
 * @param onChange Function that receives the toggle event
 */
const PropertyBrowserSwitch = ({ current, onChange }) => {
  const { root, selected, notSelected } = useStyles();
  const { t } = useTranslation('filters');

  const listChecked = current === 'list';
  const mapChecked = current === 'map';

  return (
    <div className={root}>
      <Typography variant={'body1'} className={listChecked ? selected : notSelected}>
        {t('list')}
      </Typography>
      <Toggle onChange={onChange} checked={mapChecked} />
      <Typography variant={'body1'} className={mapChecked ? selected : notSelected}>
        {t('map')}
      </Typography>
    </div>
  );
};

export default PropertyBrowserSwitch;

import React from 'react';
import Switch from '@material-ui/core/Switch';

import { useStyles } from './styles';

/**
 *
 * @param {boolean} disabled -- If true the toggle will be toggleable with disabled style, otherwise it will be always green
 */
const Toggle = ({ checked, onChange, disabled }) => {
  const classes = useStyles({ disabled });

  return (
    <Switch
      className={classes.focusVisible}
      disableRipple
      classes={{
        ...classes,
      }}
      checked={checked}
      onChange={onChange}
    />
  );
};

Toggle.defaultProps = {
  checked: false,
  onChange: () => undefined,
  disabled: false,
};

export default Toggle;

import palette from '../palette';

const MuiMenuItem = {
  root: {
    '&:hover': {
      background: palette.primary.bg,
    },
  },
};

export default MuiMenuItem;

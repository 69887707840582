import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  childRoot: {
    padding: '0.5em',
    width: '100%',
    [theme.breakpoints.up('md')]: {
      padding: '1.286em',
    },
  },
  innerWrapper: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: '1.250em',
  },
  checkboxWrapper: {
    display: 'flex',
    flexDirection: 'column',
    '& label': {
      marginBottom: '-0.625em',
    },
  },
  row: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: '0.8em',
    [theme.breakpoints.up('md')]: {
      '&:first-child': {
        marginRight: '0',
      },
    },
  },

  minMaxRow: {
    display: 'flex',
    alignItems: 'center',

    '&:first-child': {
      marginRight: '20px',
    },
    [theme.breakpoints.up('md')]: {
      '&:first-child': {
        marginRight: '0',
      },
    },
  },
  labelColumn: {
    flex: '1',
    display: 'flex',
  },
  inputColumn: {
    display: 'flex',
  },
  inputWrapper: {
    width: '71px',
  },
  inputText: {
    display: 'flex',
    alignItems: 'center',
    padding: '0',
    fontSize: '18px',
    marginRight: '10px',
    marginLeft: '0.4em',
    color: theme.palette.text.primary,
    [theme.breakpoints.up('md')]: {
      padding: '0 10px',
      fontWeight: '700',
    },
  },

  title: {
    fontSize: '1.14em',
    fontWeight: '700',
    lineHeight: '1.7em',
    marginBottom: '0.4em',
  },
  subtitle: {
    fontSize: '1em',
    marginLeft: ' 2.3em',
    color: theme.palette.text.secondary,
  },
  middleText: {
    display: 'flex',
    alignItems: 'center',
    padding: '0',
    fontSize: '18px',
    margin: '0 10px',
    fontWeight: '400',
    color: theme.palette.text.primary,
    [theme.breakpoints.up('md')]: {
      padding: '0 10px',
    },
  },
  submitFeedbackContainer: {
    textAlign: 'right',
    paddingTop: theme.spacing(2),
  },
}));

import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    width: '100%',
    height: '6.42em',
    // width: '680px',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '1.42em',
    [theme.breakpoints.down('xs')]: {
      height: '4.071em',
      padding: '0.500em',
    },
  },
  left: {
    display: 'flex',
    alignItems: 'center',
    minWidth: '0',
  },
  close: {
    marginRight: '0.6em',
    cursor: 'pointer',
  },
  avatar: {
    marginRight: '0.8em',
  },
  title: {
    lineHeight: '1.357em',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    flex: '1',
    textOverflow: 'ellipsis',

    [theme.breakpoints.down('xs')]: {
      fontSize: '1em',
      lineHeight: '1.500em',
      paddingRight: '1em',
    },
  },
  button: {
    whiteSpace: 'nowrap',
  },
}));

import { createContext, useContext, useEffect, useState } from 'react';

import useForceRender from 'hooks/useForceRender';
import { getCurrentUser } from 'services/authService';
import { getFavoritesCatalog } from 'services/catalogService';
import { getViewableAreas } from 'services/fingerprintService';

/**
 * @typedef {Object} User
 *
 * @property {number} id -
 * @property {string} slug -
 * @property {string} name -
 * @property {('buyer'|'agent'|'manager'|'other_professional'|'not_listed')} user_type -
 * @property {string} email -
 * @property {string} personal_url -
 * @property {string} image -
 * @property {string} bio -
 * @property {string} hidden_catalogs -
 * @property {string} title -
 * @property {string} company_name -
 * @property {string} company_url -
 * @property {string} company_logo -
 * @property {string} company_background_image -
 * @property {string} company_video_url -
 * @property {string} company_address -
 * @property {string} company_phone -
 * @property {string} company_description -
 * @property {string} company_founded_date -
 * @property {string} company_full_time_employees -
 * @property {string} accepted_tos_date -
 * @property {string} subscriptions -
 * @property {string} settings -
 * @property {string} technologies -
 * @property {string} email_confirmed -
 * @property {string} mobile -
 * @property {string} is_admin -
 * @property {string} is_active -
 * @property {string} catalogs -
 * @property {string} agencies -
 * @property {string} management_companies -
 */

/**
 * @typedef {Object} Catalog
 *
 * @property {number} id -
 * @property {string} slug -
 * @property {string} name -
 * @property {string} image -
 * @property {number} user - User ID
 * @property {[Property]} properties -
 * @property {number} propertyCount -
 * @property {boolean} isPublic -
 * @property {boolean} isPermanent -
 */

/**
 * @type {{reload: function, loading: boolean, setLoading: function, user: User|null}}
 */
const defaultContext = {
  user: null,
  loading: false,
  reload: () => {},
};

export const isManager = (user) => user?.user_type === 'manager';
export const isAgent = (user) => user?.user_type === 'agent';
export const isGeneral = (user) =>
  user?.user_type === 'buyer' || user?.user_type === 'other' || user?.user_type === 'not_listed';

const UserContext = createContext(defaultContext);

const UserProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [favorites, setFavorites] = useState(null);
  const [loading, setLoading] = useState(true);
  const [viewAll, setViewAll] = useState(false);
  const [viewAbleAreas, setViewAbleAreas] = useState([]);

  const reload = useForceRender();

  useEffect(() => {
    const getUser = async () => {
      try {
        const user = await getCurrentUser();
        const favorites = user && (await getFavoritesCatalog());
        setUser(user);
        setFavorites(favorites);
        setLoading(false);
      } catch (e) {
        setLoading(false);
      }
    };
    getUser();
  }, [reload]);

  const restrictView = (areas) => {
    setViewAbleAreas(areas);
    setViewAll(false);
  };

  const getViewAreas = async () => {
    const { data } = await getViewableAreas();

    if (data.includes('all')) {
      setViewAll(true);
      setViewAbleAreas([]);
    } else {
      restrictView(data);
    }
  };

  const canView = (area) => {
    if (viewAll) return true;
    return viewAbleAreas.includes(area);
  };

  return (
    <UserContext.Provider
      value={{ user, favorites, loading, setLoading, reload, canView, getViewAreas }}
    >
      {children}
    </UserContext.Provider>
  );
};

/**
 * Returns the current user, if its loading and a reload hook
 * to update it when session is updated on login/logout
 * @return {{reload: function, loading: boolean, setLoading: function, user: User|null}, favorites: Catalog|null}
 */
export const useUserContext = () => {
  const userContext = useContext(UserContext);

  if (!userContext) {
    throw new Error('UserContext not found');
  }

  return userContext;
};

export default UserProvider;

import React from 'react';
import { useTranslation } from 'react-i18next';
import { Typography } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Numeral from 'numeral';

import CircularScore from 'components/atoms/CircleScore';

import { useStyles } from './styles';

const ProperScore = ({
  propValue,
  marketValue,
  size,
  thickness,
  isAnimated,
  fontSize = '4rem',
}) => {
  const classes = useStyles({ marketValue, fontSize });
  const { t } = useTranslation('marketMembershipCard');
  const marketThickness = thickness / 2;
  const marketSize = size / 2 + 1.25;
  return (
    <Box className={classes.container}>
      <CircularScore
        value={propValue}
        size={`${size}rem`}
        thickness={thickness}
        isAnimated={isAnimated}
      />

      <Box className={classes.content}>
        <Box position="relative" display="inline-flex">
          {marketValue && (
            <CircularScore
              value={marketValue}
              color={'#b7b7b7'}
              size={`${marketSize}rem`}
              thickness={marketThickness}
              animated={true}
            />
          )}

          <Box
            className={classes.content}
            flexDirection="column"
            marginTop={marketValue && '-1rem'}
          >
            <Typography variant="body1" component="div" className={classes.propValue}>
              {propValue && Numeral(propValue).format('0.0')}
            </Typography>
            {marketValue && (
              <Typography variant="body1" component="div" className={classes.marketValue}>
                {t('market')} {marketValue}
              </Typography>
            )}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default ProperScore;

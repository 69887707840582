import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Typography } from '@material-ui/core';

import useApiCall from 'hooks/useApiCall';
import { suggestedFeatures } from 'services/searchService';

import Button from 'components/atoms/Button';
import Checkbox from 'components/atoms/Checkbox';

import optionsData from './data';
import { useStyles } from './styles';

const MoreChildren = () => {
  const classes = useStyles();
  const { t } = useTranslation('filters');

  const [execSuggestedFeatures, { data, loading }] = useApiCall(suggestedFeatures);
  const [currentSelection, setCurrentSelection] = useState({
    enhancedSort: false,
    historicRents: false,
    marketDetails: false,
    propertyFeatures: false,
    // virtualTours: false,
    sortResults: false,
    propertyType: false,
    // moreFinancials: false,
    // distanceFromHome: false,
  });

  const empty = useMemo(
    () => Object.keys(currentSelection).filter((f) => currentSelection[f]).length === 0,
    [currentSelection]
  );

  const handleChange = (handler, value, event) => {
    const length = Object.keys(currentSelection).filter((f) => currentSelection[f]).length;
    if (length < 3 || !value) {
      setCurrentSelection({ ...currentSelection, [handler]: event ? event : value });
    }
  };

  const renderCheckbox = (checked, handleChange, label, subtitle) => {
    return (
      <div className={classes.checkboxWrapper} key={`${label},${subtitle}`}>
        <Checkbox checked={checked} handleChange={handleChange} label={label} />
        <Typography variant={'body1'} className={classes.subtitle}>
          {subtitle}
        </Typography>
      </div>
    );
  };

  const submit = async () => {
    const selected = Object.keys(currentSelection)
      .filter((f) => currentSelection[f])
      .join(',');
    await execSuggestedFeatures(selected);
  };

  return (
    <div className={classes.childRoot}>
      <Typography variant="h6" className={classes.title}>
        Tell us what to create next.
        <br /> What is important to you?
      </Typography>
      {data ? (
        <Typography variant={'body1'}>{t('feedbackSentMessage')}</Typography>
      ) : (
        <>
          <Typography variant="body1">Select up to 3</Typography>
          <div className={classes.innerWrapper}>
            {optionsData.map((option) => {
              const checked = currentSelection[option.key];
              const handler = () => handleChange(option.key, !checked, null);
              return renderCheckbox(checked, handler, option.label, option.subtitle);
            })}
          </div>
          <div className={classes.submitFeedbackContainer}>
            <Button
              disabled={loading || empty}
              size={'medium'}
              onClick={submit}
              label={t('searchMoreButton')}
            />
          </div>
        </>
      )}
    </div>
  );
};

export default MoreChildren;

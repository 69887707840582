let id = 0;

/**
 * Function to return an incrementing ID that will be unique throughout the
 * entire application. Useful for things such as element IDs where we want to
 * make sure we always have unique IDs even with multiple elements.
 *
 * This currently uses an incrementing number for the best performance, but may
 * use UUIDs in the future.
 */
const getUniqueId = () => id++;

export default getUniqueId;

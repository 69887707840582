import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  logoWrapper: {},
  rightWrapper: {
    display: 'flex',
    alignItems: 'center',
  },
  itemsWrapper: {
    display: 'flex',
  },
  itemWrapper: {
    margin: '0 1.5vw',
  },
  link: {
    color: theme.palette.text.primary,
    opacity: '0.5',
    fontSize: '1.125rem',
    fontWeight: '500',
    lineHeight: '1.75rem',
    fontFamily: theme.typography.fontFamily,
  },
  activeLink: {
    color: theme.palette.primary.light,
    fontWeight: '700',
    opacity: '1',
  },
}));

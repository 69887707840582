import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import Footer from 'components/molecules/Footer';
import Header from 'components/molecules/Header';

import ErrorBoundary from './ErrorBoundary';
import { useStyles } from './styles';

const DefaultTemplate = ({ children }) => {
  const classes = useStyles();

  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  return (
    <div className={classes.root}>
      <Header />
      <ErrorBoundary>{children}</ErrorBoundary>
      <Footer />
    </div>
  );
};

export default DefaultTemplate;

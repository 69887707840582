import palette from '../palette';

const MuiButton = {
  root: {
    borderRadius: 5,
    minWidth: 'unset',
    textTransform: 'capitalize',
    whiteSpace: 'nowrap',
  },
  containedSizeSmall: {
    padding: '0.2em',
  },
  containedPrimary: {
    boxShadow: 'none !important',
    color: '#FFFFFF',
    fontWeight: 'bold',
  },
  containedSecondary: {
    color: palette.primary.main,
  },
  outlinedPrimary: {
    background: '#FFFFFF',
  },
  textPrimary: {
    color: '#FFFFFF !important',
  },
};

export default MuiButton;

import React from 'react';

import { useStyles } from './styles';

/**
 * A beautiful horizontal line
 *
 * @param margin Optionally you can override the line margin
 */
const LineSeparator = ({ margin }) => {
  const classes = useStyles({ margin });
  return <hr className={classes.root} />;
};

export default LineSeparator;

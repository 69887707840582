import React, { useEffect, useState } from 'react';
import { generatePath, useHistory } from 'react-router-dom';

import routes from 'router/routes';

import Marker from 'components/atoms/Marker';
import Map from 'components/molecules/Map';
import PropertyMapCard from 'components/molecules/PropertyMapCard';

import { useStyles } from './styles';

/**
 * Renders a map with current properties.
 * Trigger an update when user ask for it.
 * Shows a card for each listed property.
 */
const PropertyMap = ({ properties, onBBoxSearch }) => {
  const classes = useStyles();
  const history = useHistory();

  const [zoom, setZoom] = useState(4);
  const [center, setCenter] = useState({ lat: 35, lng: -95 });
  const [mapObjects, setMapObjects] = useState(null);
  const [hoverKey, setHoverKey] = useState(null);

  const markerSize = 32;

  useEffect(() => {
    mapObjects && initBounds();
  }, [properties, mapObjects]);

  // Map Event when a marker is Clicked
  const onMarkerClick = (key, { lat, lng }) => {
    setCenter({ lat, lng });
    setZoom(16);
  };

  // Map Events to know which child is currently interacting
  const onChildMouseEnter = (key) => {
    setHoverKey(key);
    // Show Property Map Card
  };

  // When user closes the Property Map Card
  const onCardLeave = () => {
    setHoverKey(null);
  };

  const onCardClick = (slug) => {
    history.push(generatePath(routes.property, { slug }));
  };

  const initBounds = () => {
    if (properties?.length && mapObjects?.map && mapObjects.maps) {
      const bounds = new mapObjects.maps.LatLngBounds();

      properties?.map(({ id, lng, lat }) => {
        bounds.extend({ lat, lng });
        return id;
      });

      mapObjects.map.fitBounds(bounds);
      setZoom(mapObjects.map.getZoom());
    }
  };

  const handleMapLoad = (obj) => {
    setMapObjects(obj);
  };

  const handleZoomIn = () => {
    setZoom((z) => z + 1);
  };

  const handleZoomOut = () => {
    setZoom((z) => z - 1);
  };

  const handleRefresh = () => {
    const bounds = mapObjects.map.getBounds();
    const sw = {
      lat: bounds.getSouthWest().lat().toFixed(6),
      lng: bounds.getSouthWest().lng().toFixed(6),
    };
    const ne = {
      lat: bounds.getNorthEast().lat().toFixed(6),
      lng: bounds.getNorthEast().lng().toFixed(6),
    };

    onBBoxSearch(sw, ne);
  };

  return (
    <div className={classes.root}>
      <Map
        zoom={zoom}
        center={center}
        onChildClick={onMarkerClick}
        onChildMouseEnter={onChildMouseEnter}
        initBounds={initBounds}
        markerSize={markerSize}
        handleMapLoad={handleMapLoad}
        handleZoomIn={handleZoomIn}
        handleZoomOut={handleZoomOut}
        handleRefresh={handleRefresh}
      >
        {properties?.map((property, index) => (
          <Marker
            key={index}
            lat={property.lat}
            lng={property.lng}
            isActive={hoverKey == index}
            size={markerSize}
            onMouseLeave={onCardLeave}
          >
            <PropertyMapCard property={property} onClick={() => onCardClick(property.slug)} />
          </Marker>
        ))}
      </Map>
    </div>
  );
};

export default PropertyMap;

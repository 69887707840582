import { useEffect, useState } from 'react';

import { useUserContext } from 'providers/UserProvider';
import { getGuidanceCards } from 'services/guidanceCardService';

/**
 * @param {string} page A string representing the page where the guidance card is going to be used.
 * @return {[object, function] The first element in the array is an object with a guidance card data and the second is the function needed to discard a guidance card
 */
const useGuidanceCards = (page) => {
  const [guidanceCardData, setGuidanceCardData] = useState();
  const [guidanceCardsList, setGuidanceCardsList] = useState([]);
  const { user } = useUserContext();

  useEffect(() => {
    const getGuidanceCardList = async () => {
      const result = await getGuidanceCards(page);

      setGuidanceCardsList(result.data);
    };
    getGuidanceCardList();
  }, [user]);

  useEffect(() => {
    const dicardedGuidanceCards = sessionStorage.getItem('dicardedGuidanceCards')
      ? JSON.parse(sessionStorage.getItem('dicardedGuidanceCards'))
      : [];

    const filteredList = guidanceCardsList.filter((gc) => !dicardedGuidanceCards.includes(gc.id));

    const index = Math.floor(Math.random() * filteredList.length);
    setGuidanceCardData(filteredList[index]);
  }, [guidanceCardsList]);

  const discardGuidanceCard = (id) => {
    const dicardedGuidanceCards = sessionStorage.getItem('dicardedGuidanceCards')
      ? JSON.parse(sessionStorage.getItem('dicardedGuidanceCards'))
      : [];

    const hiddenArray = JSON.stringify(Array.from(new Set([...dicardedGuidanceCards, id])));

    sessionStorage.setItem('dicardedGuidanceCards', hiddenArray);
    setGuidanceCardData(null);
  };

  return [guidanceCardData, discardGuidanceCard];
};

export default useGuidanceCards;

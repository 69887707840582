import { get } from 'common/api';

import { parseTags } from 'utils/locationsAdapter';

const endpoints = {
  areas: '/marketplace/areas/',
  page_defaults: '/marketplace/page_defaults/',
};

export const getAllAreas = () => get(endpoints.areas);

export const getPageDefaults = async () => {
  const { data } = await get(endpoints.page_defaults);
  const tags = parseTags(data.html_meta_tags);
  return {
    tags,
    description: data.search_dropdown_description,
    prices: {
      annualPrice: data.area_view_annual_price,
      quarterPrice: data.area_view_quarter_price,
    },
  };
};

import { DialogTitle as MuiDialogTitle, IconButton, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';

import Logo from 'components/atoms/Logo';

const useStyles = makeStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
    backgroundColor: theme.palette.primary.main,
    '& h6': {
      padding: theme.spacing(0, 5),
    },
    position: 'relative',
  },
  logo: {
    display: 'flex',
    paddingTop: '0.25em',
    paddingLeft: '0.25em',
  },
  title: {
    padding: theme.spacing(1),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(2),
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    top: theme.spacing(0),
    color: theme.palette.secondary.main,
  },
}));

/**
 * Dialog title component.
 *
 * @param {function} onClose The handler for the close button
 * @param {boolean} logo Shows the logo instead of the title
 * @param {string} title
 */
const DialogTitle = ({ onClose, logo, title }) => {
  const classes = useStyles();
  return (
    <MuiDialogTitle disableTypography className={classes.root}>
      {logo ? (
        <Logo alternative className={classes.logo} />
      ) : (
        <Typography variant="h6" color={'secondary'} align={'center'} className={classes.title}>
          {title}
        </Typography>
      )}
      {onClose ? (
        <div className={classes.closeButton}>
          <IconButton aria-label="close" onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </div>
      ) : null}
    </MuiDialogTitle>
  );
};

export default DialogTitle;

import {
  AccountCircle,
  Add,
  AttachMoneyOutlined,
  BarChart,
  BusinessCenterOutlined,
  Cancel,
  CheckCircle,
  Close,
  Edit,
  EmailOutlined,
  Error,
  Favorite,
  InfoOutlined,
  KeyboardArrowDown,
  KeyboardArrowLeft,
  KeyboardArrowRight,
  KeyboardArrowUp,
  Language,
  Link,
  LocationOn,
  Lock,
  Menu,
  MoreVert,
  PhoneOutlined,
  PhotoCamera,
  PlayArrow,
  Remove,
  SingleBed,
  Straighten,
} from '@material-ui/icons';
import LocationOnOutlinedIcon from '@material-ui/icons/LocationOnOutlined';
import { ReactComponent as Bath } from 'assets/icons/Bath.svg';
import { ReactComponent as Clock } from 'assets/icons/Clock.svg';
import { ReactComponent as EllipsisActive } from 'assets/icons/EllipsisActive.svg';
import { ReactComponent as FavoriteTwoTone } from 'assets/icons/FavoriteTwoTone.svg';
import { ReactComponent as Filters } from 'assets/icons/Filters.svg';
import { ReactComponent as FiltersActive } from 'assets/icons/FiltersActive.svg';
import { ReactComponent as Flag } from 'assets/icons/Flag.svg';
import { ReactComponent as Locked } from 'assets/icons/Locked.svg';
import { ReactComponent as MapMarker } from 'assets/icons/MapMarker.svg';
import { ReactComponent as OpenLock } from 'assets/icons/OpenLock.svg';
import { ReactComponent as PlayActive } from 'assets/icons/PlayActive.svg';
import { ReactComponent as RoundedArrowLeft } from 'assets/icons/RoundedArrowLeft.svg';
import { ReactComponent as RoundedArrowRight } from 'assets/icons/RoundedArrowRight.svg';
import { ReactComponent as Unlocked } from 'assets/icons/Unlocked.svg';

const icons = {
  menu: Menu,
  favoriteFilled: Favorite,
  ellipsis: MoreVert,
  close: Close,
  play: PlayArrow,
  link: Link,
  info: InfoOutlined,
  edit: Edit,
  arrowDown: KeyboardArrowDown,
  arrowLeft: KeyboardArrowLeft,
  arrowRight: KeyboardArrowRight,
  arrowUp: KeyboardArrowUp,
  add: Add,
  remove: Remove,
  error: Error,
  location: LocationOn,
  money: AttachMoneyOutlined,
  rule: Straighten,
  barChart: BarChart,
  email: EmailOutlined,
  phone: PhoneOutlined,
  language: Language,
  case: BusinessCenterOutlined,
  checkCircle: CheckCircle,
  bed: SingleBed,
  closedLock: Lock,
  account: AccountCircle,
  camera: PhotoCamera,
  cancel: Cancel,
  locationOnOutlinedIcon: LocationOnOutlinedIcon,
  clock: Clock,
  mapMarker: MapMarker,
  favorite: FavoriteTwoTone,
  flag: Flag,
  bath: Bath,
  openLock: OpenLock,
  unlocked: Unlocked,
  locked: Locked,
  filters: Filters,
  filtersActive: FiltersActive,
  roundedArrowLeft: RoundedArrowLeft,
  roundedArrowRight: RoundedArrowRight,
  ellipsisActive: EllipsisActive,
  playActive: PlayActive,
};

export const iconNames = Object.keys(icons);

export default icons;

import React from 'react';
import ReactDOM from 'react-dom';
import ReactGA from 'react-ga';
import App from 'App';
import reportWebVitals from 'reportWebVitals';

import 'index.css';

/**
 * REACT_APP_ENV === pr (pr branch)
 * REACT_APP_ENV === staging (master branch)
 * REACT_APP_ENV === production (production branch)
 */
if (process.env.REACT_APP_ENV === 'production') {
  ReactGA.initialize(process.env.REACT_APP_UA_CODE);
}

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

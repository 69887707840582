import { get, post } from 'common/api';
import jwtDecode from 'jwt-decode';

const endpoints = {
  obtainToken: '/auth/token/obtain/',
  refreshToken: '/auth/token/refresh/',
  getUser: '/users/:id/',
  getAgency: '/api/marketplace/agencies/:id/',
  getManagementCompany: '/api/marketplace/managementcompanies/:id/',
  forgotPassword: '/users/password_reset_request/',
  signUp: '/users/user_creation_request/',
};

const TOKEN_KEY = 'authToken';

export const getTokenData = (token) => {
  return jwtDecode(token);
};

export const getCurrentToken = () => localStorage.getItem(TOKEN_KEY);
export const setCurrentToken = (token) => localStorage.setItem(TOKEN_KEY, token);
export const clearToken = () => localStorage.removeItem(TOKEN_KEY);

export const tokenIsExpired = (token) => {
  const { exp: expirationDate } = getTokenData(token);

  return expirationDate < (new Date().getTime() + 1) / 1000;
};

export const hasCompanyInformation = ({
  company_name,
  company_phone,
  company_address,
  company_units_managed,
  company_logo,
  company_full_time_employees,
  company_founded_date,
  company_description,
}) => {
  return !!(
    company_name &&
    company_phone &&
    company_address &&
    company_units_managed &&
    company_logo &&
    company_full_time_employees &&
    company_founded_date &&
    company_description
  );
};

/**
 * Get current user from local storage token. The session is removed if the token is expired or invalid
 *
 * @return {Promise<null|User>}
 */
export const getCurrentUser = async () => {
  try {
    const token = getCurrentToken();

    if (!token) return null;

    if (tokenIsExpired(token)) {
      // TODO: try using refresh token instead
      throw new Error('Session expired, please login again');
    }

    const { user_id: userId } = getTokenData(token);

    const { data: user } = await get(endpoints.getUser.replace(':id', userId));

    const agencies = user.agencies ? await getAgencies(user.agencies) : [];

    const management_companies = user.management_companies
      ? await getManagementCompanies(user.management_companies)
      : [];

    return {
      ...user,
      agencies,
      management_companies,
      hasCompanyInformation: hasCompanyInformation(user),
    };
  } catch (e) {
    // TODO: Show proper feedback
    console.error(e);
    clearToken();
    return null;
  }
};

/**
 * @param {string} email
 * @param {string} password
 */
export const executeLogin = async (email, password) => {
  try {
    const response = await login(email, password);
    setCurrentToken(response.data.access);
    return true;
  } catch (e) {
    console.error(e);
    return false;
  }
};

/**
 * Takes email, password and user_type(optional) create an account, after this action user is already logged.
 *
 * @param {string} email
 * @param {string} password
 * @param {string} user_type
 */
export const signUp = async (email, password, user_type) =>
  post(endpoints.signUp, { email, password, user_type });

/**
 * Takes email and password and returns an access and refresh JWT pair.
 *
 * @param {string} email
 * @param {string} password
 */
export const login = async (email, password) => post(endpoints.obtainToken, { email, password });

/**
 * Get User info
 *
 * @param {*} id
 */
export const getUser = async (id) => get(endpoints.getUser.replace(':id', id));

/**
 * Takes a refresh JWT and returns an access JWT if the refresh token is valid.
 *
 * @param {*} refresh
 */
export const refreshToken = async (refresh) => post(endpoints.refreshToken, { refresh });

/**
 * Get a list of agencies by id
 *
 * @param {{id:number}[]} agencies Array of agencies with ids
 * @return {Promise<unknown[]>}
 */
export const getAgencies = async (agencies) => {
  const agenciesCalls = agencies.map(async ({ id }) => {
    const { data } = await get(endpoints.getAgency.replace(':id', id));
    return data;
  });
  return Promise.all(agenciesCalls);
};

/**
 * Get a list of management companies by id
 *
 * @param {{id:number}[]} managementCompanies Array of management companies with ids
 * @return {Promise<unknown[]>}
 */
const getManagementCompanies = async (managementCompanies) => {
  const managementCalls = managementCompanies.map(async ({ id }) => {
    const { data } = await get(endpoints.getManagementCompany.replace(':id', id));
    return data;
  });
  return Promise.all(managementCalls);
};

/**
 * Takes email and send an email to user with an url to recover the password
 *
 * @param {string} email
 */
export const forgotPassword = async (email) => post(endpoints.forgotPassword, { email });

export default {
  results: '/',
  resultsByLocation: '/vacation-rentals-for-sale/:location',
  account: '/account',
  property: '/vacation-rentals-for-sale/property-details/:slug',
  moreDetails: '/more-details/:slug',
  resetPassword: '/resetpassword/:id/:token',
  profile: '/profiles/:slug',
  dashboard: '/dashboard/:tab?',
  brandedCatalog: '/catalog/:slug',
};

import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CircularProgress, DialogActions, MenuItem, Typography } from '@material-ui/core';
import { Form, Formik } from 'formik';

import useIsMobile from 'hooks/useIsMobile';
import signupSchema from 'validators/signup';

import Dropdown from 'components/atoms/Dropdown';
import LinearProgress from 'components/molecules/LinearProgress';

import { useStyles } from './styles';

const menuItems = [
  { value: 'buyer', label: 'Researching a Purchase' },
  { value: 'agent', label: 'Real Estate Agent or Broker' },
  // { value: 'manager', label: 'Property Management Professional' },
  { value: 'other_professional', label: 'Other Professional' },
  { value: 'not_listed', label: 'Not Listed' },
];

const CreateForm = ({ onSubmitSignUp, setUsertypeFn, loading, showError, message }) => {
  const isMobile = useIsMobile();
  const [userType, setUserType] = useState('');

  const classes = useStyles();
  const { t } = useTranslation('auth');

  const onChangeDropdown = useCallback(
    (value) => {
      setUserType(value);
      setUsertypeFn(value);
    },
    [userType, setUserType]
  );

  const handleAccess = () => {
    setUsertypeFn(userType);
    onSubmitSignUp();
  };

  return (
    <>
      {loading ? (
        <div className={classes.loading}>
          <CircularProgress color="primary" />
        </div>
      ) : (
        <Formik validationSchema={signupSchema(t)}>
          <Form className={classes.root}>
            <div className={classes.container}>
              <div>
                <div className={classes.label} style={{ fontSize: '1.1rem' }}>
                  {t('userType')}
                </div>
                <div className={classes.inputWrapper}>
                  <Dropdown
                    name="userType"
                    placeholder={t('selectToFinish')}
                    selectTextColor={userType ? '#41B060' : '#9F9F9F'}
                    selected={userType}
                    onChange={onChangeDropdown}
                  >
                    {menuItems.map((item) => (
                      <MenuItem key={item.value} value={item}>
                        {item.label}
                      </MenuItem>
                    ))}
                  </Dropdown>
                </div>
              </div>

              <div>
                <div className={classes.inputWrapper} style={{ fontSize: '.7rem' }}>
                  By accessing this application, you agree to the{' '}
                  <a href={'https://www.propersum.io/terms-privacy'}>
                    Terms of Service and Privacy Policy
                  </a>
                  .
                </div>
                {showError && (
                  <div className={classes.errorAuthWrapper}>
                    <Typography variant={'body1'} className={classes.errorAuth}>
                      {message?.length ? message : t('authError')}
                    </Typography>
                  </div>
                )}
                <DialogActions className={classes.actions}>
                  <LinearProgress
                    onClickFn={handleAccess}
                    isMobile={isMobile}
                    isDisabled={!userType}
                  ></LinearProgress>
                </DialogActions>
              </div>
            </div>
          </Form>
        </Formik>
      )}
    </>
  );
};

export default CreateForm;

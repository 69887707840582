import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    height: '100%',
  },
  resultList: {
    paddingTop: theme.spacing(3),
    overflow: 'auto',
    paddingRight: theme.spacing(2),
    flexBasis: '50%',
    [theme.breakpoints.down('sm')]: {
      flexBasis: ({ currentView }) => currentView === 'list' && '100%',
      display: ({ currentView }) => currentView !== 'list' && 'none',
      padding: theme.spacing(0, 2),
    },
    [theme.breakpoints.down('md')]: {
      flexBasis: '62%',
    },
  },
  map: {
    display: 'block',
    flexBasis: '50%',
    [theme.breakpoints.down('sm')]: {
      display: ({ currentView }) => currentView !== 'map' && 'none',
      flexBasis: ({ currentView }) => currentView === 'map' && '100%',
    },

    [theme.breakpoints.down('md')]: {
      flexBasis: '38%',
    },
  },
}));

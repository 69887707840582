import { createContext, useContext, useRef, useState } from 'react';

import getUniqueId from 'utils/getUniqueId';

import SnackBar from 'components/atoms/Snackbar';

const defaultContext = {
  default: 'default',
  success: 'success',
  warning: 'warning',
  error: 'error',
};

const SnackbarContext = createContext(defaultContext);
const SnackbarProvider = ({ children }) => {
  const [snackbars, setSnackbars] = useState([]);

  const closeSnackbar = () => {
    setSnackbars((snackbars) => {
      const latestSnackbar = snackbars.pop();
      if (!latestSnackbar) return snackbars;
      return [...snackbars].concat({ ...latestSnackbar, open: false });
    });
  };

  const createSnackbar = (option) => {
    const snackbar = {
      key: `snackbar-${getUniqueId()}`,
      open: true,
      ...option,
      handleClose: closeSnackbar,
    };
    setSnackbars((snackbars) => [...snackbars, snackbar]);
  };

  const clearSnackbars = () => {
    setSnackbars([]);
  };

  const value = useRef([createSnackbar, closeSnackbar, clearSnackbars]);

  return (
    <SnackbarContext.Provider value={value}>
      {children}
      {snackbars.map((snackbar, key) => {
        return <SnackBar key={key} {...snackbar} />;
      })}
    </SnackbarContext.Provider>
  );
};

/**
 * A hook for snackbar management
 *
 * Usage:
 *
 * createSnackbar({
        children: (
          <span>
            Not important, just let you know. <a href="#">Link</a>
          </span>
        ),
        variant: 'success',
      });
 *
 * @return a tuple with [createSnackbar,closeSnackbar,clearSnackbars]
 */
export const useSnackbar = () => {
  const snackbarContext = useContext(SnackbarContext);

  if (!snackbarContext) {
    throw new Error('Snackbar Context not found');
  }

  return snackbarContext.current;
};

export default SnackbarProvider;

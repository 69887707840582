import React, { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { Typography } from '@material-ui/core';

import PropertyListCard from 'components/molecules/PropertyListCard';
import PropertyListCardSkeleton from 'components/molecules/PropertyListCard/skeleton';

import { useStyles } from './styles';

/**
 * Renders a list of PropertyCards with some skeleton loaders
 * If the properties array is empty, a message is rendered
 *
 * @param {Property[]} properties List of properties to be rendered. See PropertyCard documentation for reference
 * @param {boolean} loading Shows a skeleton list if it's true
 * @param handleDeletePropertyFromCatalog
 * @param customEmptyMessage
 * @param {Element} guidanceCard A guidance card element
 */
const PropertyList = ({
  properties,
  loading,
  handleDeletePropertyFromCatalog,
  customEmptyMessage,
  guidanceCard,
  moreDetails = [],
}) => {
  const classes = useStyles();
  const { t } = useTranslation('filters');

  return (
    <div className={classes.root}>
      {properties?.map((property, index) => (
        <Fragment key={property.id}>
          <PropertyListCard
            property={property}
            className={classes.listItem}
            handleDeletePropertyFromCatalog={handleDeletePropertyFromCatalog}
            displayAllProposals={moreDetails.includes(property.id)}
          />
          {index === 1 && guidanceCard && <div className={classes.listItem}>{guidanceCard}</div>}
        </Fragment>
      ))}

      {loading &&
        [...Array(4).keys()].map((i) => (
          <PropertyListCardSkeleton key={i} className={classes.listItem} />
        ))}

      {!properties?.length && !loading ? (
        customEmptyMessage ? (
          customEmptyMessage
        ) : (
          <>
            <Typography variant={'h3'}>{t('noResults')}</Typography>
            <Typography variant={'body1'}>{t('noResultsHelp')}</Typography>
          </>
        )
      ) : null}
    </div>
  );
};

export default PropertyList;

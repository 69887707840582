import { useEffect, useState } from 'react';

/**
 * @param {'xs'|'sm'} media breakpoint to match
 *
 * @return {boolean} returns true when the viewport matches the provided breakpoint
 */
const useIsMobile = (media = 'sm') => {
  const breakpoints = {
    xs: 374,
    sm: 599,
    tablet: 820,
  };
  const isMobileView = () => window.innerWidth <= breakpoints[media];

  const [isMobile, setIsMobile] = useState(isMobileView());

  const updateIsMobile = () => setIsMobile(isMobileView());

  useEffect(() => {
    window.addEventListener('resize', updateIsMobile);
    return () => {
      window.removeEventListener('resize', updateIsMobile);
    };
  }, [updateIsMobile]);

  return isMobile;
};

export default useIsMobile;

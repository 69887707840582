import { makeStyles } from '@material-ui/core/styles';

const baseRoot = {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
};
export const useStyles = makeStyles((theme) => ({
  rootDesktop: {
    ...baseRoot,
    padding: theme.spacing(2, 0),
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  rootMobile: {
    ...baseRoot,
    padding: theme.spacing(2, 0),
    display: 'none',
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
    },
  },
  actions: {
    color: theme.palette.primary.bg,
  },
}));

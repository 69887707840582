import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DialogActions } from '@material-ui/core';

import { useDialog } from 'providers/DialogProvider';

import Button from 'components/atoms/Button';
import Icon from 'components/atoms/Icon';
import PropertyFilters from 'components/organisms/PropertyFilters';

import { useStyles } from './styles';

const FilterModal = ({ currentFilters, onFilter, onReset, locations }) => {
  const classes = useStyles();
  const [, closeDialog] = useDialog();
  const { t } = useTranslation('filterModal');
  const [filters, setFilters] = useState(JSON.parse(JSON.stringify(currentFilters)));

  const applyFilters = () => {
    onFilter(filters);
    closeDialog();
  };

  const onFilterChange = (newFilters) => {
    const newSelection = { ...currentFilters, ...newFilters };
    setFilters(newSelection);
  };

  const handleReset = () => {
    onReset();
    closeDialog();
  };

  const handleRestore = () => {
    setFilters(JSON.parse(JSON.stringify(currentFilters)));
    closeDialog();
  };
  return (
    <div className={classes.root}>
      <div className={classes.closeModal} onClick={handleRestore}>
        <Icon name={'close'} color={'#000000'} size={'1em'} />
      </div>
      <PropertyFilters
        currentSelection={filters}
        handleFilter={onFilterChange}
        onReset={onReset}
        locations={locations}
      />
      <DialogActions className={classes.actions}>
        <Button
          label={t('reset')}
          size={'large'}
          variant={'secondary'}
          fullWidth
          onClick={handleReset}
        />
        <Button
          label={t('apply')}
          size={'large'}
          variant={'primary'}
          type={'submit'}
          fullWidth
          onClick={applyFilters}
        />
      </DialogActions>
    </div>
  );
};

export default FilterModal;

import React from 'react';
import { useParams } from 'react-router-dom';

import { useCanView } from 'hooks/useCanView';
import SearchContextProvider from 'providers/SearchContextProvider';

import SearchProperties from 'components/organisms/SearchProperties';
import DefaultTemplate from 'components/templates/DefaultTemplate';

import SearchPageMetaTags from './SearchPageMetaTags';

const ResultsByLocationPage = () => {
  useCanView();
  const { location } = useParams();
  return (
    <DefaultTemplate>
      <SearchContextProvider>
        <SearchPageMetaTags location={location} />
        <SearchProperties location={location} />
      </SearchContextProvider>
    </DefaultTemplate>
  );
};

export default ResultsByLocationPage;

import React from 'react';

import { useCanView } from 'hooks/useCanView';
import SearchContextProvider from 'providers/SearchContextProvider';

import SearchProperties from 'components/organisms/SearchProperties';
import DefaultTemplate from 'components/templates/DefaultTemplate';

import ResulsPageMetaTags from './ResultsPageMetaTags';

const ResultsPage = () => {
  useCanView();
  return (
    <DefaultTemplate>
      <SearchContextProvider>
        <ResulsPageMetaTags />
        <SearchProperties />
      </SearchContextProvider>
    </DefaultTemplate>
  );
};

export default ResultsPage;

import React from 'react';
import { Link } from 'react-router-dom';

import Button from 'components/atoms/Button';
import ErrorPageMessage from 'components/molecules/ErrorPageMessage';

const NotFoundError = ({ type, message }) => {
  return (
    <ErrorPageMessage>
      <h1>{type} not found</h1>
      <p>{message}</p>
      <Link to={'/'}>
        <Button label="Go home" />
      </Link>
    </ErrorPageMessage>
  );
};

export default NotFoundError;

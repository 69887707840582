import { createMuiTheme } from '@material-ui/core';

import overrides from './overrides';
import palette from './palette';
import typography from './typography';

const theme = createMuiTheme({
  spacing: 5,
  shape: {
    borderRadius: 5,
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1400,
      xl: 1920,
    },
  },
  palette,
  typography,
  overrides,
  props: {
    MuiInput: {
      disableUnderline: true,
    },
    MuiContainer: {
      maxWidth: 'lg',
    },
    MuiMenu: {
      anchorOrigin: {
        vertical: 'bottom',
        horizontal: 'left',
      },
      transformOrigin: {
        vertical: 'top',
        horizontal: 'left',
      },
      disableScrollLock: true,
      getContentAnchorEl: null,
    },
  },
});

export default theme;

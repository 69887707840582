import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { CircularProgress, MenuItem, Typography } from '@material-ui/core';
import clsx from 'clsx';

import useGetArea from 'hooks/useGetArea';
import { useSearchContext } from 'providers/SearchContextProvider';
import sortCriteria from 'utils/sortCriteria';

import AreaDescription from 'components/atoms/AreaDescription';
import Dropdown from 'components/atoms/Dropdown';
import Icon from 'components/atoms/Icon';

import { useStyles } from './styles';

const PropertyListHeader = ({ resultCount, selectedSort, onChangeSort, loading }) => {
  const classes = useStyles();
  const { fromSearch, defaultDescription } = useSearchContext();
  const { location } = useParams();
  const area = useGetArea(location);
  const { t } = useTranslation('propertyBrowser');
  const [isOpen, setIsOpen] = useState(false);
  const clickHandler = () => {
    setIsOpen((prev) => !prev);
  };

  const description = area?.description || defaultDescription;

  return (
    <div>
      <div className={classes.root}>
        {fromSearch && (
          <div className={classes.expandibleHeader}>
            {description && (
              <button className={classes.arrowButton} onClick={clickHandler}>
                <Icon
                  name={isOpen ? 'arrowDown' : 'arrowRight'}
                  className={classes.icon}
                  color={'#41B060'}
                  size={'1.1em'}
                />
              </button>
            )}
            <span className={classes.title}>{t('propertiesForSale')}</span>
          </div>
        )}

        <Typography variant={'h3'} className={classes.resultCount}>
          {!loading ? (
            fromSearch ? (
              ' '
            ) : (
              t('resultCount', { resultCount })
            )
          ) : (
            <CircularProgress size={24} />
          )}
        </Typography>
        <div className={classes.searchWrapper}>
          <label className={classes.sortLabel}>{t('sortBy')}</label>
          <Dropdown variant="standard" selected={selectedSort} onChange={onChangeSort}>
            {sortCriteria.map((item) => (
              <MenuItem key={item.value} value={item}>
                {item.label}
              </MenuItem>
            ))}
          </Dropdown>
        </div>
      </div>

      <div className={clsx(classes.paragraph, isOpen && classes.show)}>
        {description && <AreaDescription fullDescription={description}></AreaDescription>}
      </div>
    </div>
  );
};

export default PropertyListHeader;

import React, { useState } from 'react';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { ExpandMore } from '@material-ui/icons';
import clsx from 'clsx';

import { useStyles } from './styles';

import Icon from '../Icon';

/**
 *
 */
const Dropdown = ({
  placeholder,
  label,
  selected,
  onChange,
  selectIcon,
  children,
  variant,
  button,
  hasArrow,
  width = '100%',
  selectTextColor = '#000',
  rootClass,
  selectClass,
  MenuProps,
}) => {
  const [open, setOpen] = useState(false);
  const classes = useStyles({
    selectIcon,
    hidden: !!button,
    hasArrow,
    width,
    selectTextColor,
  });
  //const Icon = selectIcon && selectIcon;
  const handleChange = (event) => {
    onChange(event.target.value);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <FormControl variant={variant} className={clsx(classes.formControl, rootClass)}>
      {label && <label>{label}</label>}
      <div className={classes.selectWrapper}>
        {selectIcon && (
          <>
            <Icon name={selectIcon} className={classes.selectIcon} color={'#666666'} />
            <div className={classes.lineSeparator} />
          </>
        )}
        {button && <div onClick={handleOpen}>{button}</div>}
        <Select
          className={classes.select}
          displayEmpty
          labelId="dropdown-label"
          onChange={handleChange}
          value={selected}
          renderValue={(selectedValue) => {
            if (!selectedValue) {
              return placeholder;
            }
            return selectedValue.label || selectedValue.name;
          }}
          IconComponent={ExpandMore}
          open={button ? open : undefined}
          onClose={button ? handleClose : undefined}
          classes={{ root: selectClass }}
          MenuProps={MenuProps}
        >
          {children}
        </Select>
      </div>
    </FormControl>
  );
};

Dropdown.defaultProps = {
  selectIcon: null,
  label: null,
  selected: '',
  placeholder: '',
  variant: 'outlined',
  onChange: () => undefined,
  button: null,
  hasArrow: true,
};

export default Dropdown;

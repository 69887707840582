import palette from '../palette';

const MuiTextField = {
  root: {
    '& fieldset': {
      borderColor: palette.primary.bg,
    },
  },
};

export default MuiTextField;

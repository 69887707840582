import React from 'react';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Typography from '@material-ui/core/Typography';
import { ExpandMore } from '@material-ui/icons';

import useIsMobile from 'hooks/useIsMobile';

import Icon from 'components/atoms/Icon';

import { useStyles } from './styles';
/**
 *
 */
const FilterDropdown = ({
  label,
  selected,
  onChange,
  selectIcon,
  children,
  hasArrow,
  multiple,
  MenuProps,
  withLabel = false,
  labelClass,
  placeholder,
}) => {
  const isMobile = useIsMobile();
  const classes = useStyles({ selectIcon, hasArrow: isMobile ? true : hasArrow });
  const handleChange = (event) => {
    onChange(event.target.value);
  };

  return (
    <FormControl variant={'outlined'} className={classes.formControl}>
      {withLabel && <label className={labelClass}>{label}</label>}
      {!isMobile && (
        <div className={classes.selectWrapper}>
          <>
            <Icon name={selectIcon} className={classes.selectIcon} color={'#666666'} />
          </>
          <Select
            className={classes.select}
            displayEmpty
            labelId="dropdown-label"
            onChange={handleChange}
            value={selected}
            IconComponent={ExpandMore}
            renderValue={() => placeholder || label}
            multiple={multiple}
            MenuProps={MenuProps}
          >
            {children}
          </Select>
        </div>
      )}

      {isMobile && (
        <Accordion className={classes.accordionRoot}>
          <AccordionSummary
            expandIcon={<ExpandMore />}
            aria-controls="panel1a-content"
            id="panel1a-header"
            className={classes.accordionSummary}
          >
            {selectIcon && (
              <>
                <Icon name={selectIcon} className={classes.selectIcon} color={'#666666'} />
              </>
            )}
            <Typography className={classes.select}>{placeholder || label}</Typography>
          </AccordionSummary>
          <AccordionDetails>{children}</AccordionDetails>
        </Accordion>
      )}
    </FormControl>
  );
};

FilterDropdown.defaultProps = {
  selectIcon: null,
  label: null,
  selected: '',
  placeholder: '',
  onChange: () => undefined,
  button: null,
  hasArrow: true,
};

export default FilterDropdown;

import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(1, 0),
    [theme.breakpoints.up('md')]: {
      flexDirection: 'row',
      alignItems: 'center',
    },
  },
  resetButton: {
    fontWeight: 'bold !important',
    marginLeft: theme.spacing(2),
  },
  menuItem: {
    '&:hover': {
      [theme.breakpoints.up('md')]: {
        backgroundColor: `${theme.palette.primary.bg} !important`,
      },
    },
  },
  selected: {
    backgroundColor: `${theme.palette.primary.bg} !important`,
  },
  title: {
    fontSize: '1.571em',
    fontWeight: '700',
    marginBottom: '0.5em',
  },
  moneyFilter: {
    width: '100%',
    marginBottom: '10px',
    [theme.breakpoints.up('md')]: {
      width: '7.571em',
      marginRight: '10px',
      marginBottom: '0',
    }, //106
  },
  sizeFilter: {
    width: '100%',
    marginBottom: '10px',

    [theme.breakpoints.up('md')]: {
      width: '6.929em',
      marginRight: '10px',
      marginBottom: '0',
    },
  },
  estimatesFilter: {
    width: '100%',
    marginBottom: '10px',

    [theme.breakpoints.up('md')]: {
      width: '10.500em',
      marginRight: '10px',
      marginBottom: '0',
    },
  },
  moreFilter: {
    width: '100%',
    marginBottom: '10px',

    [theme.breakpoints.up('md')]: {
      width: '7.857em',
      marginRight: '10px',
      marginBottom: '0',
    },
  },
  locationFilter: {
    width: '100%',
    marginBottom: theme.spacing(2),

    [theme.breakpoints.up('md')]: {
      width: '20em',
      margin: theme.spacing(0, 2, 0, 0),
    },
  },
  propertiesCount: {
    color: 'rgba(0, 0, 0, 0.4)',
    paddingLeft: '0.3em',
    display: 'flex',
    alignItems: 'center',
  },
  close: {
    background: theme.palette.primary.main,
    borderRadius: '50%',
    marginLeft: 'auto',
    [theme.breakpoints.only('xs')]: {
      marginLeft: theme.spacing(1),
    },
  },
  locationMenu: {
    width: 'fit-content',
  },
}));

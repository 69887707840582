/**
 * API available filters:
 * - random
 * - price_asc
 * - price_desc
 * - bedrooms_asc
 * - bedrooms_desc
 *
 * TODO: @ben: we need 'best_sellers' and 'newest'
 */
const sortCriteria = [
  /*   { value: 'newest', label: 'Newest' },
  { value: 'best_sellers', label: 'Best Sellers' }, */
  { value: 'random', label: 'Random' },
  { value: 'price_asc', label: 'Price: Low to High' },
  { value: 'price_desc', label: 'Price: High to Low' },
  { value: 'bedrooms_asc', label: 'Bedrooms: Low to High' },
  { value: 'bedrooms_desc', label: 'Bedrooms: High to Low' },
];

export default sortCriteria;

import React, { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { IconButton } from '@material-ui/core';

import useApiCall from 'hooks/useApiCall';
import AccessModal from 'modals/AccessModal';
import { useDialog } from 'providers/DialogProvider';
import { useSnackbar } from 'providers/SnackbarProvider';
import { useUserContext } from 'providers/UserProvider';
import { toggleFavorite } from 'services/catalogService';

import Icon from 'components/atoms/Icon';

import { useStyles } from './styles';

const FavoriteButton = ({ property }) => {
  const classes = useStyles();
  const { t } = useTranslation('filters');

  const { user, loading: userLoading, favorites, reload } = useUserContext();
  const [execFav, { error: favoritesError }] = useApiCall(toggleFavorite);
  const [openSnackbar] = useSnackbar();
  const [openDialog] = useDialog();

  const isFavorite = useMemo(() => favorites?.properties?.includes(property.id), [
    property.id,
    favorites,
    reload,
  ]);

  const handleAddToFavorites = async (id) => {
    if (!userLoading && !user) {
      openSnackbar({
        children: t('loginFirst'),
      });
      openDialog({
        maxWidth: 'xs',
        logo: true,
        blurred: true,
        children: <AccessModal />,
      });
    } else {
      await execFav(id);
      reload();
    }
  };

  useEffect(() => {
    if (favoritesError) {
      openSnackbar({ children: t('favoritesError'), variant: 'error' });
    }
  }, [favoritesError]);

  return (
    <IconButton
      size="medium"
      onClick={() => handleAddToFavorites(property.id)}
      className={classes.actionIcon}
    >
      <Icon name={isFavorite ? 'favoriteFilled' : 'favorite'} size={'1.5em'} color={'primary'} />
    </IconButton>
  );
};

export default FavoriteButton;

import React from 'react';
import { SvgIcon } from '@material-ui/core';

import icons from './icons';
import { useStyles } from './styles';

/**
 * @returns {SvgIcon}
 *
 * @param {string} name Name of the icon to render
 * @param {string} color Will override the color of the icon (if possible) (primary,secondary,hex)
 * @param {string} size Will be used as height and width of the icon
 *
 * See ./icons.js to see the list of icons
 */
const Icon = ({ name, color = '#41B060', size, className, ...rest }) => {
  const classes = useStyles({ size });

  // use style prop when color is hex
  const colorProp = color.startsWith('#') ? { style: { color } } : { color };

  return (
    <SvgIcon
      component={icons[name]}
      classes={classes}
      className={className}
      {...colorProp}
      {...rest}
    />
  );
};

export default Icon;

import React from 'react';
import { Typography } from '@material-ui/core';
import ImagePlaceholder from 'assets/img/logo-white-and-green.svg';
import Numeral from 'numeral';

import useIsMobile from 'hooks/useIsMobile';

import Avatar from 'components/atoms/Avatar';
import Image from 'components/atoms/Image';
import ValuePill from 'components/atoms/ValuePill';

import { useStyles } from './styles';

/**
 * Renders a map property card with details
 *
 * @param {object} property - An object with address, area, value, annualRents, features, images, and managers
 * @param {number} property.value - A number that represents the property value
 * @param {number} property.annualRents - A number that represents the property annual rents
 * @param {object} property.features - An object with beds (number), bathrooms (number), sqft (number)
 * @param {array} property.images - An array of objects with src (string) and alt (string), this images will appear in the photo gallery
 * @param {array} property.managers - An array of objects with name(string), company(string) and image(string).
 * @param {func} onClick Function be executed when the card is clicked
 */
const PropertyMapCard = ({ property, onClick }) => {
  const isMobile = useIsMobile();
  const classes = useStyles({ isMobile });

  const managers =
    property.managers?.length > 0 && property.managers?.length > 1
      ? property.managers?.length - 1
      : property.managers?.length;
  const avatarImage = property.managers?.length > 0 ? property.managers[0].image : null;
  const displayedEstimate =
    property.managers?.length > 0 ? property.managers[0].grossRents : property.annualRents;

  return (
    <div className={classes.root} onClick={onClick}>
      <div className={classes.imageWrapper}>
        {property.images && property.images.length > 0 ? (
          <Image src={property.images[0].src} alt={property.address} className={classes.image} />
        ) : (
          <img src={ImagePlaceholder} alt={property.address} className={classes.placeholder} />
        )}
      </div>
      <div className={classes.right}>
        <div className={classes.rightTop}>
          <div className={classes.value}>{Numeral(property.value).format('$0.0a')}</div>
          <div className={classes.features}>
            <Typography variant={'body1'}>
              <b>{property.features.beds} </b>
              bds <b>{property.features.bathrooms} </b>ba
            </Typography>
          </div>
        </div>
        <div className={classes.rightBottom}>
          {!isMobile && (
            <div>
              {managers && (
                <>
                  <Avatar image={avatarImage} size={'small'} />
                  {managers > 1 && <span className={classes.managerCount}>+{managers}</span>}
                </>
              )}
            </div>
          )}
          <ValuePill value={`${Numeral(displayedEstimate).format('$0a')}/yr`} />
        </div>
      </div>
    </div>
  );
};

export default PropertyMapCard;

const MuiAvatarGroup = {
  root: {
    display: 'flex !important',
    flexDirection: 'row-reverse !important',
    '& div:first-child': {
      marginLeft: -6,
    },
  },
};
export default MuiAvatarGroup;

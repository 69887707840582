import React, { lazy, Suspense, useEffect } from 'react';
import ReactGA from 'react-ga';
import { Redirect, Route, Switch, useHistory } from 'react-router-dom';

import { useDialog } from 'providers/DialogProvider';
import { useSnackbar } from 'providers/SnackbarProvider';

import PageLoader from 'components/atoms/PageLoader';
import NotFoundError from 'components/organisms/NotFoundError';
import ResultsByLocationPage from 'components/pages/ResultsByLocationPage';
import ResultsPage from 'components/pages/ResultsPage';
import DefaultTemplate from 'components/templates/DefaultTemplate';

import PrivateRoute from './PrivateRoute';
import routes from './routes';

const DashboardPage = lazy(() => import('../components/pages/DashboardPage'));
const PropertyDetailsPage = lazy(() => import('../components/pages/PropertyDetailsPage'));
const ResetPasswordPage = lazy(() => import('../components/pages/ResetPasswordPage'));
const ProfilePage = lazy(() => import('../components/pages/ProfilePage'));
const CatalogPage = lazy(() => import('../components/pages/CatalogPage'));

const ViewContainer = () => {
  const history = useHistory();
  const [, , clearDialogs] = useDialog();
  const [, , clearSnackbars] = useSnackbar();

  useEffect(() => {
    return history.listen(() => {
      if (history.action === 'POP') {
        // do not clear dialogs for property-details pages
        // In the future, consider changing clear dialogs to only clear login/signup on non-private routes
        if (!history.location.pathname.includes('property-details')) {
          clearDialogs();
        }
        clearSnackbars();
      }
    });
  }, []);

  useEffect(() => {
    ReactGA.pageview(location.pathname);
    return history.listen(() => ReactGA.pageview(location.pathname));
  }, [history]);

  return (
    <Suspense fallback={<PageLoader />}>
      <Switch>
        <Route exact path={routes.results} component={ResultsPage} />
        <Route exact path={routes.resultsByLocation} component={ResultsByLocationPage} />
        <PrivateRoute exact path={routes.dashboard} component={DashboardPage} />
        <Route exact path={routes.profile} component={ProfilePage} />
        <Route exact path={routes.property} component={PropertyDetailsPage} />
        <Route exact path={routes.moreDetails} component={PropertyDetailsPage} />
        <Route exact path={routes.resetPassword} component={ResetPasswordPage} />
        <Route exact path={routes.brandedCatalog} component={CatalogPage} />

        <Route exact path={'/'}>
          <Redirect to={'/'} />
        </Route>

        <Route path="*">
          <DefaultTemplate>
            <NotFoundError type={'Page'} />
          </DefaultTemplate>
        </Route>
      </Switch>
    </Suspense>
  );
};

export default ViewContainer;

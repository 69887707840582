import React, { useEffect, useState } from 'react';
import { CircularProgress } from '@material-ui/core';

import { useStyles } from './styles';

const CircularScore = ({ value, color, size, thickness, isAnimated = false }) => {
  const classes = useStyles({ color, isAnimated });
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    setProgress(value * 10);
  }, [value]);

  return (
    <CircularProgress
      variant="determinate"
      value={progress}
      size={size}
      thickness={thickness}
      className={classes.root}
      style={{ transform: 'rotate(90deg)' }}
    />
  );
};

export default CircularScore;

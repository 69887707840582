import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  root: { padding: theme.spacing(5) },
  section: { paddingBottom: theme.spacing(3) },
  imageSection: {
    paddingBottom: theme.spacing(3),
    display: 'flex',
    justifyContent: 'center',

    '& img': {
      width: '100%',
    },
  },
  text: {
    fontSize: '1.286em',
    color: theme.palette.secondary[800],
  },
  actions: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
}));

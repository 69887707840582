import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  buttonStyled: {
    textTransform: 'none',
    fontSize: '1.125rem',
    lineHeight: '1.688rem',
    fontWeight: 'bold',
    color: theme.palette.text.primary,
    minWidth: '10rem',
    minHeight: '3.5rem',
    justifyContent: 'flex-end',
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.secondary.main,
    },
    '&:active': {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.secondary.main,
    },
  },

  avatarWrapper: {
    paddingLeft: '0.563rem',
  },
}));

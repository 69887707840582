import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Route, useHistory } from 'react-router-dom';

import AccessModal from 'modals/AccessModal';
import { useDialog } from 'providers/DialogProvider';
import { useUserContext } from 'providers/UserProvider';

import PageLoader from 'components/atoms/PageLoader';

import routes from './routes';

const PrivateRoute = ({ component, path, ...rest }) => {
  const Component = component;

  const history = useHistory();

  const [openDialog] = useDialog();

  const { user, loading } = useUserContext();

  const { t } = useTranslation('privateRoute');

  useEffect(() => {
    if (!loading && !user) {
      openDialog({
        maxWidth: 'xs',
        children: <AccessModal />,
        title: t('signUpModalTitle'),
        blurred: true,
        onKill: () => history.push(routes.results),
      });
    }
  }, [loading, user]);

  if (loading) return <PageLoader />;

  return <Route {...rest} path={path} component={Component} />;
};

export default PrivateRoute;

import Avatar from '@material-ui/core/Avatar';
import { makeStyles, withStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  xSmall: {
    width: theme.spacing(7),
    height: theme.spacing(7),
    border: `4px solid ${theme.palette.primary.bg}`,
    '& img': {
      background: theme.palette.secondary.main,
    },
  },
  small: {
    width: theme.spacing(8),
    height: theme.spacing(8),
    border: `4px solid ${theme.palette.primary.bg}`,
    '& img': {
      background: theme.palette.secondary.main,
    },
  },
  medium: {
    width: theme.spacing(10),
    height: theme.spacing(10),
    border: `4px solid ${theme.palette.primary.bg}`,
    '& img': {
      background: theme.palette.secondary.main,
    },
  },
  large: {
    width: theme.spacing(13),
    height: theme.spacing(13),
    border: `4px solid ${theme.palette.primary.bg}`,
    '& img': {
      background: theme.palette.secondary.main,
    },
  },
  xLarge: {
    width: theme.spacing(26),
    height: theme.spacing(26),
    border: `4px solid ${theme.palette.primary.bg}`,
    '& img': {
      background: theme.palette.secondary.main,
    },
  },
}));

export const SmallAvatar = withStyles((theme) => ({
  root: {
    width: 22,
    height: 22,
    border: `2px solid ${theme.palette.secondary.main}`,
  },
}))(Avatar);

import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Box, Container } from '@material-ui/core';

import routes from 'router/routes';

import Button from 'components/atoms/Button';
import Logo from 'components/atoms/Logo';
import Menu from 'components/atoms/Menu';
import AvatarButton from 'components/molecules/AvatarButton';

import { useStyles } from './DesktopHeader.styles';

const DesktopHeader = ({
  items,
  activePath,
  user,
  menuOptions,
  openLogin,
  handleClick,
  handleClose,
  anchorEl,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Container className={classes.root}>
      <Link to={routes.results}>
        <Logo />
      </Link>
      <div className={classes.rightWrapper}>
        <Box component={'div'} className={classes.itemsWrapper} displayPrint={'none !important'}>
          {items &&
            items.map((item) => (
              <div className={classes.itemWrapper} key={item.label}>
                {item.external ? (
                  <a href={item.path} target="_blank" rel="noopener noreferrer">
                    <p className={classes.link}>{item.label}</p>
                  </a>
                ) : (
                  <Link to={item.path}>
                    <p
                      className={`${classes.link} ${
                        item.path === activePath ? classes.activeLink : null
                      }`}
                    >
                      {item.label}
                    </p>
                  </Link>
                )}
              </div>
            ))}
        </Box>
        <div>
          {user ? (
            <>
              <AvatarButton
                image={user.image}
                name={user.name || user.email.split('@')[0]}
                onClick={(event) => handleClick(event)}
              />
              <Menu
                options={menuOptions}
                keepMounted
                anchorEl={anchorEl}
                onClose={handleClose}
                open={!!anchorEl}
              />
            </>
          ) : (
            <Button
              label={t('auth:login')}
              size="large"
              variant="third"
              onClick={() => openLogin()}
            />
          )}
        </div>
      </div>
    </Container>
  );
};

export default DesktopHeader;

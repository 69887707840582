const MuiInputAdornment = {
  positionStart: {
    '& p': {
      color: '#000',
    },
  },
  positionEnd: {
    marginLeft: '5px',
    '& p': {
      color: '#000',
    },
  },
};

export default MuiInputAdornment;

import React from 'react';
import Button from '@material-ui/core/Button';
import clsx from 'clsx';

import { useStyles } from './styles';

/**
 * @param {string} label - Label that is displayed on the button
 * @param {element} Icon - An icon element
 * @param {boolean} active - This flag will determine the icon color
 */
const TextButton = ({ label, Icon, active, onClick, color = 'primary', className, ...rest }) => {
  const classes = useStyles({ active, color });

  return (
    <Button
      variant="text"
      color="secondary"
      className={clsx(Icon ? classes.iconText : classes.textOnly, classes.button, className)}
      onClick={onClick}
      {...rest}
    >
      {label}
      {Icon && <Icon className={classes.icon} />}
    </Button>
  );
};

export default TextButton;

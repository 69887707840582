import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  paper: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: '1rem',
    display: 'flex',
    flexDirection: 'column',
    gap: '1rem',
    position: 'relative',
    '& h5': {
      textAlign: 'center',
    },
    [theme.breakpoints.up('sm')]: {
      padding: '2rem',
    },
  },
  locationMenu: {
    width: 'fit-content',
    maxHeight: '1rem',
  },
  actions: {
    display: 'flex',
    flexDirection: 'column',
    gap: '1rem',
    [theme.breakpoints.up('sm')]: {
      flexDirection: 'row',
    },
  },
  footer: {
    gap: '0.3rem',
    display: 'flex',
    flexDirection: 'column',
  },
}));

// eslint-disable-next-line no-unused-vars
import { TypographyOptions } from '@material-ui/core/styles/createTypography';

/**
 * @see https://material-ui.com/customization/typography/
 * @type {TypographyOptions}
 */
const typography = {
  fontFamily: ['Lato', 'sans-serif'].join(','),
  h1: {
    fontSize: '2.25rem',
    lineHeight: '3.375rem',
    fontWeight: '700',
    whiteSpace: 'nowrap',
  },
  h2: {
    fontSize: '1.625rem',
    lineHeight: '2.625rem',
    fontWeight: '600',
  },
  h3: {
    fontSize: '1.375rem',
    lineHeight: '2.063',
    fontWeight: '700',
  },
  h4: {
    fontSize: '1.25rem',
    fontWeight: '700',
  },
  h5: {
    fontSize: '1.15rem',
    fontWeight: '700',
  },
  h6: {
    fontSize: '1rem',
    fontWeight: 'bold',
  },

  button: {
    fontWeight: '700',
    textTransform: 'none',
  },
};

export default typography;

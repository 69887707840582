import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  root: {
    color: theme.palette.secondary[300],
    '&$checked': {
      color: theme.palette.primary.main,
    },
  },
  checked: {},
}));

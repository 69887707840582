import React, { useEffect, useRef, useState } from 'react';
import Slider from 'react-slick';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import Image from 'components/atoms/Image';

/**
 *
 * See https://react-slick.neostack.com/docs/api/ for help building the Settings object
 * @param {*} items list of items to render
 * @param {ReactComponent} CustomSlide If you want to render custom slides
 * @param {*} rest The rest of the props will be passed on to the CustomSlide
 *
 * @returns {Slider}
 */
const Carousel = ({
  swipeToSlide = true,
  dots = false,
  dotsClass,
  infinite = true,
  speed = 500,
  slidesToShow = 1,
  slidesToScroll = 1,
  arrows = false,
  fade = true,
  customPaging = null,
  lazyLoad = 'ondemand',
  items,
  CustomSlide,
  ...rest
}) => {
  const ref = useRef(null);
  const [controls, setControls] = useState({});
  const settings = {
    swipeToSlide,
    dots,
    dotsClass,
    infinite,
    speed,
    slidesToShow,
    slidesToScroll,
    arrows,
    fade,
    customPaging,
    lazyLoad,
  };

  useEffect(() => {
    ref?.current && setControls({ prev: ref.current.slickPrev, next: ref.current.slickNext });
  }, [ref]);

  return (
    <Slider {...settings} ref={ref}>
      {items?.map((item, i) =>
        CustomSlide ? (
          <CustomSlide key={i} index={i} item={item} controls={controls} {...rest} />
        ) : (
          <Image src={item.src} alt={item.alt} />
        )
      )}
    </Slider>
  );
};

export default Carousel;

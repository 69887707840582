import React from 'react';
import Avatar from '@material-ui/core/Avatar';
import Badge from '@material-ui/core/Badge';

import { SmallAvatar, useStyles } from './styles';

import Icon from '../Icon';

const AvatarTemplate = ({ image, altText, size, withBadge, badgeLock, children, ...rest }) => {
  const classes = useStyles();
  return withBadge ? (
    <Badge
      overlap="circle"
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      badgeContent={
        <SmallAvatar>
          {badgeLock ? (
            <Icon name={'locked'} size={'0.8em'} />
          ) : (
            <Icon name={'unlocked'} size={'0.8em'} />
          )}
        </SmallAvatar>
      }
    >
      <Avatar alt={altText} src={image} className={classes[size]} {...rest}>
        {children}
      </Avatar>
    </Badge>
  ) : (
    <Avatar alt={altText} src={image} className={classes[size]} {...rest}>
      {children}
    </Avatar>
  );
};

export default AvatarTemplate;

import React from 'react';
import { TextField } from '@material-ui/core';
import clsx from 'clsx';

import { useStyles } from './styles';

import Icon from '../Icon';

const Input = ({
  label,
  value,
  placeholder,
  disabled = false,
  error = false,
  onChange = () => null,
  onBlur,
  onFocus,
  fullWidth = true,
  name,
  type = 'text',
  errorMessage,
  success,
  startAdornment,
  variant = 'outlined',
  multiline,
  rows,
  rootClass,
  ...rest
}) => {
  const classes = useStyles();

  return (
    <div className={clsx(classes.root, rootClass)}>
      {label && <label className={classes.label}>{label}</label>}
      <div className={classes.wrapper}>
        <TextField
          variant={variant}
          disabled={disabled}
          error={error}
          placeholder={placeholder}
          value={value}
          onChange={onChange}
          onBlur={onBlur}
          onFocus={onFocus}
          fullWidth={fullWidth}
          name={name}
          type={type}
          helperText={error && errorMessage}
          multiline={multiline}
          rows={rows}
          InputProps={{
            startAdornment,
            endAdornment: error ? (
              <Icon name="cancel" size="1.1em" color="error" />
            ) : success ? (
              <Icon name="checkCircle" size="1.1em" color="primary" />
            ) : null,
          }}
          {...rest}
        />
      </div>
    </div>
  );
};

export default Input;

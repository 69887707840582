import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
    '& .gm-control-active': {
      display: 'none',
    },
    '& .gmnoprint': {
      display: 'none',
    },
    '& .gm-style-cc': {
      display: 'none',
    },
  },
  customControls: {
    position: 'absolute',
    display: 'flex',
    justifyContent: 'space-between',
    height: '32px',
    boxSizing: 'border-box',

    top: 15,
    left: 0,
    right: 0,
    padding: '0 20px',
    '& .MuiButton-containedSizeLarge': {
      alignItems: 'center',
      padding: '0.2em 1.4em',
    },
    '& .MuiButton-label': {
      color: theme.palette.text.primary,
    },
  },
  zoomControls: {
    width: '75px',
    display: 'flex',
    justifyContent: 'space-between',
    '& .MuiButton-containedSizeSmall': {
      minWidth: '32px',
      boxSizing: 'border-box',
    },
  },
}));

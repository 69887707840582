import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.primary.bg,
    border: '0 none',
    height: '1px',
    width: '100%',
    margin: ({ margin }) => (margin !== undefined ? margin : '5px 0'),
  },
}));

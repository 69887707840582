const getManagers = (proposals) =>
  proposals.map(({ manager, management_fee, gross_rents, image }) => ({
    id: manager?.id,
    slug: manager?.slug,
    name: manager?.name,
    company: manager?.company_name,
    image: image || manager?.image,
    fee: management_fee,
    grossRents: gross_rents,
    status,
    user_type: manager?.user_type,
  }));

const normalizeCommonFields = (dbProperty) => {
  const {
    bedrooms,
    bathrooms,
    square_footage,
    address: { street, city, state, postal_code, latitude, longitude },
    management_proposals,
    slug,
    purchase_price,
    est_rents_mid,
  } = dbProperty;

  const city_state_postal = [city, state, postal_code].filter(Boolean).join(' ');
  const managers = management_proposals.length ? getManagers(management_proposals) : null;

  return {
    value: purchase_price,
    fullAddress: `${street}, ${city_state_postal}`,
    annualRents: est_rents_mid,
    address: street,
    managers,
    lat: Number(latitude),
    lng: Number(longitude),
    slug,
    features: {
      beds: parseInt(bedrooms),
      bathrooms: parseInt(bathrooms),
      sqft: square_footage,
    },
  };
};

export const normalizeMapProperty = (dbProperty) => {
  const commonFields = normalizeCommonFields(dbProperty);
  const { image } = dbProperty;
  return {
    ...commonFields,
    images: [{ src: image, alt: 'Property Image' }],
  };
};

const normalizeProperty = (dbProperty) => {
  const commonFields = normalizeCommonFields(dbProperty);
  const {
    id,
    address: { street, city, state, postal_code },
    est_mgmt_fee_high,
    est_mgmt_fee_low,
    est_mgmt_fee_mid,
    est_rents_high,
    est_rents_low,
    community_dues,
    home_insurance,
    property_taxes,
    utilities,
    regulation_text,
    images,
    area,
    proposals_due,
    my_proposal,
    listing_agency,
    description,
    cap_rate,
    proper_score,
    market_score,
  } = dbProperty;

  const myProposal = my_proposal && {
    id: my_proposal.id,
    grossRent: my_proposal.gross_rents,
    fee: my_proposal.management_fee,
  };

  return {
    ...commonFields,
    id,
    address: street,
    city,
    state,
    postalCode: postal_code,
    area,
    communityDues: community_dues,
    homeInsurance: home_insurance,
    propertyTaxes: property_taxes,
    utilities,
    regulationText: regulation_text,
    estMgmtFeeHigh: est_mgmt_fee_high,
    estMgmtFeeLow: est_mgmt_fee_low,
    estMgmtFeeMid: est_mgmt_fee_mid,
    estRentsHigh: est_rents_high,
    estRentsLow: est_rents_low,
    images: images?.map(({ image, caption }) => {
      return {
        src: image,
        alt: caption,
      };
    }),
    proposalsDue: proposals_due,
    myProposal,
    listing_agency: listing_agency,
    description,
    capRate: cap_rate,
    properScore: proper_score,
    marketScore: market_score,
  };
};

export default normalizeProperty;

import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: theme.spacing(3),
  },
  expandibleHeader: {
    display: 'flex',
    alignItems: 'center',
    fontWeight: 'bold',
  },
  title: {
    fontWeight: 'Bold',
  },
  paragraph: {
    margin: '0 1em 2em 0',
    display: 'none',
  },
  show: {
    display: 'block',
  },
  link: {
    cursor: 'pointer',
  },
  arrowButton: {
    all: 'unset',
    cursor: 'pointer',
  },
  icon: {
    padding: '0.1em 0.3em 0 0',
  },
  searchSection: {
    display: 'flex',
    justifyContent: 'space-around',
  },
  resultCount: {
    fontWeight: 400,
  },
  searchWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    minWidth: '200px',
  },
  sortLabel: {
    fontWeight: 'bold',
    whiteSpace: 'nowrap',
  },
}));

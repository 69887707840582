import React from 'react';
import { Typography } from '@material-ui/core';
import MuiMenu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';

/**
 * Renders a Menu with Options coming from props
 * @param {[{label: string, action: function}]} options List of items to be rendered
 * @param {{*}} buttonProps See Button docs in /atoms
 */
const Menu = ({ options, open, onClose, anchorEl, ...rest }) => {
  return (
    <MuiMenu
      id="simple-menu"
      anchorEl={anchorEl}
      open={open}
      onClose={onClose}
      PaperProps={{
        style: {
          width: 'fit-content',
        },
      }}
      {...rest}
    >
      {options?.map(({ action, label }, i) => (
        <MenuItem key={i} onClick={action}>
          <Typography variant="inherit" noWrap>
            {label}
          </Typography>
        </MenuItem>
      ))}
    </MuiMenu>
  );
};

export default Menu;

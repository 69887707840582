import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  arrows: {
    background: 'none',
    border: 'none',
    padding: 0,
    position: 'absolute',
    outline: 'none !important',
    top: '50%',
    transform: 'translateY(-50%)',
    zIndex: 99,
    cursor: 'pointer',
  },
  prev: {
    left: 0,
    padding: theme.spacing(3, 3, 3, 0),
  },
  next: {
    right: 0,
    padding: theme.spacing(3, 0, 3, 3),
  },
  wrapper: {
    display: 'block !important',
    margin: 'auto',
    width: 'fit-content !important',
    position: 'relative',
    maxWidth: (props) => props.width,
    maxHeight: (props) => props.height,
    overflow: 'hidden',
  },
  photo: {
    margin: 'auto',
    height: (props) => props.height,
    width: '100%',
    objectFit: 'cover',
  },
}));

import palette from '../palette';

const lato = {
  fontFamily: 'Lato',
  fontStyle: 'normal',
  fontWeight: 400,
};

const MuiCssBaseline = {
  '@global': {
    '@font-face': [lato],
    a: {
      textDecoration: 'none',
      color: palette.primary.main,
      '&:hover': {
        color: palette.primary.light,
      },
    },
    '*::-webkit-scrollbar': {
      width: '0.4em',
      height: '0.4em',
    },
    '*::-webkit-scrollbar-track': {
      '-webkit-box-shadow': 'inset 0 0 6px rgba(0,0,0,0.00)',
    },
    '*::-webkit-scrollbar-thumb': {
      backgroundColor: palette.primary.main,
      borderRadius: '0.2em',
    },
  },
};

export default MuiCssBaseline;

import React from 'react';
import { Typography } from '@material-ui/core';
import clsx from 'clsx';

import Avatar from 'components/atoms/Avatar';
import Icon from 'components/atoms/Icon';

import { useStyles } from './styles';

/**
 * @param {Catalog} catalog Catalog to be rendered. See Catalog Adapter.
 * @param {function} onClick Function to be executed when cliking the card
 */
const CatalogModalListCard = ({ catalog, onClick }) => {
  const classes = useStyles();
  const { image, name, isPublic, isPermanent } = catalog;
  return (
    <div
      className={clsx(classes.root, !isPermanent && classes.notPermanent)}
      onClick={() => onClick(catalog)}
    >
      <div className={classes.start}>
        <Avatar image={image} altText={'Avatar'} size={'small'} />
        <Typography variant={'h4'} className={classes.name}>
          {name}
        </Typography>
      </div>
      <div className={classes.end}>
        <Icon name={isPublic ? 'unlocked' : 'locked'} size={'1.4em'} />
      </div>
    </div>
  );
};

export default CatalogModalListCard;

import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Typography } from '@material-ui/core';

import useIsMobile from 'hooks/useIsMobile';
import { getRecommendedProperties } from 'services/propertyService';
import getPropertyAreas from 'utils/getPropertyAreas';

import Button from 'components/atoms/Button';
import PropertyListCard from 'components/molecules/PropertyListCard';

import { useStyles } from './styles';

/**
 * @param {string} title - Title to show at the beginning of component
 * @param {object} propertyFilters - Filters to be sended to the service
 * @param {boolean} seeMore - Shows "See more" button at the end of the component to show more properties
 * @param {string} propertySlug - Current property to be sended to the service and improve the recommended search
 * @param propertyTags array of tags
 */
const RecommendedProperties = ({
  title,
  propertyFilters = { limit: 2, offset: 0 },
  propertySlug,
  propertyTags,
  seeMore,
}) => {
  const isMobile = useIsMobile();
  const classes = useStyles({ seeMore, isMobile });

  const { t } = useTranslation('propertyDetails');

  const [offset, setOffset] = useState({
    limit: 2,
    offset: 0,
  });

  const [properties, setProperties] = useState([]);
  const [loading, setLoading] = useState(true);
  const [isLastPage, setIsLastPage] = useState(false);

  const loadRelatedProperties = async (_properties) => {
    try {
      setLoading(true);
      const { data } = await getRecommendedProperties({
        ...propertyFilters,
        ...offset,
        reference_slug: propertySlug,
        areas: propertyTags ? [getPropertyAreas(propertyTags)] : undefined,
      });
      setProperties([..._properties, ...data?.results]);
      setIsLastPage(data?.isLastPage);
    } catch (error) {
      console.error(error);
    }
    setLoading(false);
  };

  useEffect(() => {
    loadRelatedProperties([]);
  }, [propertyTags, propertySlug]);

  const loadMoreRelated = () => {
    setOffset((prev) => ({
      limit: 2,
      offset: prev.offset + 2,
    }));
    loadRelatedProperties(properties);
  };

  return (
    <>
      <Typography className={classes.title} variant={seeMore ? 'h2' : 'body1'}>
        {title}
      </Typography>
      <div className={classes.relatedList}>
        {properties.map((p) => (
          <div className={classes.relatedListItem} key={p.id}>
            <PropertyListCard property={p} loading={loading} />
          </div>
        ))}
      </div>
      {!isLastPage && seeMore && (
        <div className={classes.seeMore}>
          <Button
            label={t('seeMore')}
            variant={'third'}
            onClick={loadMoreRelated}
            disabled={loading}
          />
        </div>
      )}
    </>
  );
};

export default RecommendedProperties;

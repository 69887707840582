import { useMemo } from 'react';

import { useSearchContext } from 'providers/SearchContextProvider';

const useGetArea = (location) => {
  const { locations } = useSearchContext();

  const area = useMemo(() => {
    return locations?.find(({ slug }) => slug === location);
  }, [location, locations]);

  return area;
};

export default useGetArea;

import React from 'react';
import { ReactComponent as PrimaryLightLogo } from 'assets/img/logo-black-and-green.svg';
import { ReactComponent as AlternativeDarkLogo } from 'assets/img/logo-black-and-white.svg';
import { ReactComponent as PrimaryDarkLogo } from 'assets/img/logo-white-and-green.svg';

const Logo = ({ style, alternative, dark, ...rest }) => {
  const LogoElement = alternative ? AlternativeDarkLogo : dark ? PrimaryDarkLogo : PrimaryLightLogo;

  // display:block removes an odd padding at the bottom
  return <LogoElement style={{ display: 'block', ...style }} alt={'Propersum Logo'} {...rest} />;
};

export default Logo;

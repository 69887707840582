import React from 'react';
import { Typography } from '@material-ui/core';
import InputAdornment from '@material-ui/core/InputAdornment';
import Numeral from 'numeral';

import TextInput from 'components/atoms/Input';

import { useStyles } from './styles';

const PriceChildren = ({ currentSelection, onChange }) => {
  const { price_min, price_max } = currentSelection;
  const classes = useStyles();

  const handleChange = (handler, event) => {
    onChange({ ...currentSelection, [handler]: Numeral(event.target.value).value() });
  };

  return (
    <div className={classes.childRoot}>
      <div className={classes.innerWrapper}>
        <div className={classes.minMaxRow}>
          <Typography variant="body1" className={classes.inputText}>
            Min
          </Typography>
          <TextInput
            value={price_min && Numeral(price_min).format('0,0')}
            InputProps={{ startAdornment: <>$</> }}
            onChange={(event) => handleChange('price_min', event)}
            startAdornment={<InputAdornment position="start">$</InputAdornment>}
            placeholder={'450,000'}
          />
        </div>
        <div className={classes.separator} />
        <div className={classes.minMaxRow}>
          <Typography variant="body1" className={classes.inputText}>
            Max
          </Typography>
          <TextInput
            value={price_max && Numeral(price_max).format('0,0')}
            InputProps={{ startAdornment: <>$</> }}
            onChange={(event) => handleChange('price_max', event)}
            startAdornment={<InputAdornment position="start">$</InputAdornment>}
            placeholder={'4,500,000'}
          />
        </div>
      </div>
    </div>
  );
};

export default PriceChildren;

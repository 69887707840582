import React from 'react';
import GoogleMapReact from 'google-map-react';

import Button from 'components/atoms/Button';

import { useStyles } from './styles';

/**
 * This component needs a valid Google Maps API Key to work.
 * Also, it should be render inside a wrapper with a set width and height.
 * You can render your cool animated react components on the map.
 *
 * You can access to Google Maps map and maps objects by using onGoogleApiLoaded,
 * in this case you will need to set yesIWantToUseGoogleMapApiInternals to true.
 *
 * @prop {lat: number, lng: number} center initial position of the map
 * @prop {number} zoom amplification of the map
 * @prop {any} children Markers to be rendered in the map
 * @prop {func} onChildClick
 * @prop {func} onChildMouseEnter
 * @prop {func} onChildMouseLeave
 * @prop {number} hoverDistance Helps with markers that are too close together
 * @prop {func} handleMapLoad Function to be executed on Map initialization
 *
 * @returns {GoogleMapReact}
 */
const Map = ({
  center,
  zoom,
  children,
  onChildClick,
  onChildMouseEnter,
  onChildMouseLeave,
  markerSize,
  handleMapLoad,
  handleZoomIn,
  handleZoomOut,
  handleRefresh,
  noCustomControls,
  ...rest
}) => {
  const classes = useStyles();

  const handleOnLoad = (map, maps) => {
    handleMapLoad && handleMapLoad({ map, maps });
  };

  return (
    <div style={{ height: '100%', width: '100%' }} className={classes.root}>
      <GoogleMapReact
        bootstrapURLKeys={{ key: process.env.REACT_APP_MAPS_KEY }}
        zoom={zoom}
        center={center}
        onChildMouseEnter={onChildMouseEnter}
        onChildMouseLeave={onChildMouseLeave}
        onChildClick={onChildClick}
        yesIWantToUseGoogleMapApiInternals
        onGoogleApiLoaded={({ map, maps }) => handleOnLoad(map, maps)}
        hoverDistance={markerSize / 2}
        {...rest}
      >
        {children}
      </GoogleMapReact>
      {!noCustomControls && (
        <div className={classes.customControls}>
          <Button
            label={'Refresh Results'}
            variant={'third'}
            onClick={handleRefresh}
            size={'large'}
          />
          <div className={classes.zoomControls}>
            <Button
              variant={'third'}
              icon="add"
              size={'small'}
              onClick={handleZoomIn}
              iconProps={{ color: '#000000' }}
            />

            <Button
              icon="remove"
              size={'small'}
              variant={'third'}
              onClick={handleZoomOut}
              iconProps={{ color: '#000000' }}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default Map;

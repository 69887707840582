import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative',
  },
  front: {
    position: 'absolute',
  },
  left: {
    marginRight: '0.5em',
    [theme.breakpoints.up('md')]: {
      marginRight: '1em',
    },
  },
  hidden: {
    visibility: 'hidden',
  },
  avatar: {
    borderColor: theme.palette.primary.bg,
    width: theme.spacing(10),
    height: theme.spacing(10),
  },
}));

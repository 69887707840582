import axios from 'axios';

import history from 'router/history';
import routes from 'router/routes';
import { clearToken, getCurrentToken, tokenIsExpired } from 'services/authService';
import { getFingerprint } from 'services/fingerprintService';

// Default config for axios instance
const config = {
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
};

// Creating the instance for axios
const httpClient = axios.create(config);

// Auth token interceptors
const authInterceptor = (conf) => {
  const token = getCurrentToken();
  if (token) {
    return {
      ...conf,
      headers: {
        ...conf.headers,
        Authorization: `Bearer ${token}`,
      },
    };
  }

  const fingerprint = getFingerprint();
  return {
    ...conf,
    headers: {
      ...conf.headers,
      ...(!!fingerprint && { fingerprint }),
    },
  };
};

export const setupInterceptors = () => {
  httpClient.interceptors.request.use(authInterceptor);
  httpClient.interceptors.request.use((conf) => {
    const token = getCurrentToken();

    if (token && tokenIsExpired(token)) {
      // TODO: show feedback (snackbar ie)
      clearToken();
      return Promise.reject(new Error('Token expired'));
    }

    return conf;
  });

  httpClient.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      if (error?.response?.status === 401) {
        // TODO: show feedback (snackbar ie)
        clearToken();
        history.push(routes.properties);
      } else {
        return Promise.reject(error);
      }
    }
  );
};

setupInterceptors();

export default httpClient;

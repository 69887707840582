import React from 'react';

import getImageUrl from 'utils/getImageUrl';

import AvatarTemplate from './Avatar';

const Avatar = ({ image, altText, size, withBadge, badgeLock, children, ...rest }) => {
  return (
    <AvatarTemplate
      altText={altText}
      image={getImageUrl(image, { width: 256 })}
      size={size}
      withBadge={withBadge}
      badgeLock={badgeLock}
      {...rest}
    >
      {children}
    </AvatarTemplate>
  );
};

Avatar.defaultProps = {
  image: '',
  altText: '',
  size: 'medium',
  withBadge: false,
  badgeLock: false,
};
export default Avatar;

import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CircularProgress, debounce, IconButton } from '@material-ui/core';

import FilterModal from 'modals/FilterModal';
import { useDialog } from 'providers/DialogProvider';
import { useSearchContext } from 'providers/SearchContextProvider';

import Button from 'components/atoms/Button';
import Icon from 'components/atoms/Icon';
import Menu from 'components/atoms/Menu';
import TextButton from 'components/atoms/TextButton';
import PropertyBrowserSwitch from 'components/molecules/PropertyBrowserSwitch';
import PropertyFilters from 'components/organisms/PropertyFilters';

import { useStyles } from './styles';

import { initialSelection } from '../SearchProperties/useSearchParams';

const PropertyBrowserActionsHeader = ({
  currentView,
  onViewChange,
  onFilter,
  onSave,
  onShare,
  initialValues,
  loading,
}) => {
  const classes = useStyles();
  const [openDialog] = useDialog();
  const { t } = useTranslation('propertyBrowser');
  const [anchorEl, setAnchorEl] = useState(null);

  const [currentSelection, setCurrentSelection] = useState(initialValues);

  const submit = useCallback(debounce(onFilter), [onFilter]);

  const { locations } = useSearchContext();

  const _onFilter = (filter) => {
    setCurrentSelection(filter);
    submit(filter);
  };

  const _onReset = () => {
    const values = initialSelection();
    setCurrentSelection(values);
    submit(values);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const openMenu = (e) => {
    setAnchorEl(e.currentTarget);
  };

  const menuOptions = [
    { label: t('saveSearch'), action: onSave },
    { label: t('shareSearch'), action: onShare },
  ];

  const openFilterModal = useCallback(() => {
    openDialog({
      children: (
        <FilterModal
          currentFilters={currentSelection}
          onFilter={_onFilter}
          onReset={_onReset}
          locations={locations}
        />
      ),
      withTitle: false,
      fullScreen: true,
    });
  });

  return (
    <>
      <div className={classes.rootMobile}>
        <Button onClick={openFilterModal} label={t('filters')} variant={'third'} size={'medium'} />
        <PropertyBrowserSwitch current={currentView} onChange={onViewChange} />

        <IconButton aria-label="open" size="medium" onClick={openMenu}>
          <Icon name={'ellipsis'} color={'#000000'} />
        </IconButton>
        <Menu
          options={menuOptions}
          disableScrollLock
          anchorEl={anchorEl}
          onClose={handleCloseMenu}
          open={!!anchorEl}
        />
      </div>
      <div className={classes.rootDesktop}>
        <PropertyFilters
          onSave={onSave}
          onShare={onShare}
          handleFilter={_onFilter}
          onReset={_onReset}
          currentSelection={currentSelection}
          locations={locations}
        />
        <div className={classes.actions}>
          <TextButton
            label={loading ? <CircularProgress size={20} /> : t('save')}
            onClick={onSave}
          />
          |
          <TextButton label={t('share')} onClick={onShare} />
        </div>
      </div>
    </>
  );
};

export default PropertyBrowserActionsHeader;

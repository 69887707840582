import * as Yup from 'yup';

const signup = (t) =>
  Yup.object({
    username: Yup.string().email(t('emailError')).required(t('required')),
    password: Yup.string().required(t('required')),
    terms: Yup.boolean().oneOf([true], t('required')),
  });

export default signup;

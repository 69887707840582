import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
    padding: theme.spacing(2.5),
    position: 'relative',
    cursor: 'pointer',
    '&::after': {
      display: 'block',
      position: 'absolute',
      content: '""',
      height: '1px',
      width: '100%',
      bottom: 0,
      left: '50%',
      transform: 'translateX(-50%)',
      borderBottom: `1px solid ${theme.palette.primary.bg}`,
    },
  },
  start: {
    display: 'flex',
    alignItems: 'center',
    flexGrow: 0,
    flexBasis: '80%',
  },
  end: {
    flexBasis: '10%',
    justifyContent: 'end',
    display: 'flex',
    alignItems: 'center',
  },
  name: {
    marginLeft: theme.spacing(2),
    color: theme.palette.secondary[800],
    display: '-webkit-box',
    WebkitBoxOrient: 'vertical',
    WebkitLineClamp: 1,
    overflow: 'hidden',
  },
  notPermanent: {
    background: 'rgba(193, 244, 193, 0.2)',
  },
}));

import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  text: {
    margin: '0',
    background: theme.palette.primary.main,
    borderRadius: '5px',
    color: theme.palette.secondary.main,
    fontSize: '1.125em',
    fontWeight: '700',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    lineHeight: '1.6875em',
    padding: '0.3em 1em',
  },
  disabled: {
    background: 'lightgray',
    color: 'slategray',
  },
  halfRound: {
    borderRadius: theme.spacing(2, 0, 2, 0),
  },
}));

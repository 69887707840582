import React from 'react';
import clsx from 'clsx';

import useIsMobile from 'hooks/useIsMobile';

import Carousel from 'components/atoms/Carousel';
import Icon from 'components/atoms/Icon';
import Image from 'components/atoms/Image';

import { useStyles } from './styles';

const CustomSlide = (props) => {
  const { controls, item, width, height, handleClick } = props;
  const classes = useStyles({ width, height });
  const isMobile = useIsMobile();

  return (
    <div {...props} className={classes.wrapper}>
      <Image
        src={item.src}
        className={classes.photo}
        alt={item.alt}
        opts={{ width: 300 }}
        onClick={handleClick}
      />
      <button onClick={controls?.prev} className={clsx([classes.arrows, classes.prev])}>
        <Icon name={'arrowLeft'} color={'secondary'} size={isMobile ? '1em' : '1.2em'} />
      </button>
      <button onClick={controls?.next} className={clsx([classes.arrows, classes.next])}>
        <Icon name={'arrowRight'} color={'secondary'} size={isMobile ? '1em' : '1.2em'} />
      </button>
    </div>
  );
};

const SinglePhotoCarousel = ({ photos, width, height }) => {
  return <Carousel items={photos} width={width} height={height} CustomSlide={CustomSlide} />;
};

export default SinglePhotoCarousel;

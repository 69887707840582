import React from 'react';
import Button from '@material-ui/core/Button';

import Avatar from 'components/atoms/Avatar';

import { useStyles } from './styles';

/**
 * @param {string} name - Name that is displayed on the button
 * @param {element} image - An image element
 * @param onClick
 */
const AvatarButton = ({ name, image, onClick }) => {
  const classes = useStyles();

  return (
    <Button variant={'text'} className={classes.buttonStyled} onClick={onClick}>
      {name}
      <div className={classes.avatarWrapper}>
        <Avatar image={image} size="small" />
      </div>
    </Button>
  );
};

export default AvatarButton;

import { useCallback, useEffect, useMemo, useState } from 'react';
import axios from 'axios';
import {
  ArrayParam,
  BooleanParam,
  NumberParam,
  StringParam,
  useQueryParams,
  withDefault,
} from 'use-query-params';

import { useSnackbar } from 'providers/SnackbarProvider';
import { getAllProperties } from 'services/propertyService';
import PropertyFiltersParser from 'utils/propertyFiltersParser';
import sortCriteria from 'utils/sortCriteria';

export const initialSelection = () => {
  return {
    locations: [],
    price_min: undefined,
    price_max: undefined,
    bds_from: undefined,
    bds_to: undefined,
    ba_from: undefined,
    ba_to: undefined,
    sqft_from: undefined,
    sqft_to: undefined,
    est_from: undefined,
    est_to: undefined,
    proposals: undefined,
    estimates: undefined,
    bbox: undefined,
    sort_by: 'price_asc',
  };
};

let cancelToken;

const useSearchParams = (setLoading) => {
  const [openSnackbar] = useSnackbar();

  const [params, _setParams] = useQueryParams({
    locations: withDefault(ArrayParam, []),
    price_min: withDefault(NumberParam, undefined),
    price_max: withDefault(NumberParam, undefined),
    bds_from: withDefault(NumberParam, undefined),
    bds_to: withDefault(NumberParam, undefined),
    ba_from: withDefault(NumberParam, undefined),
    ba_to: withDefault(NumberParam, undefined),
    sqft_from: withDefault(NumberParam, undefined),
    sqft_to: withDefault(NumberParam, undefined),
    est_from: withDefault(NumberParam, undefined),
    est_to: withDefault(NumberParam, undefined),
    proposals: withDefault(BooleanParam, undefined),
    estimates: withDefault(BooleanParam, undefined),
    bbox: withDefault(StringParam, undefined),
    sort_by: withDefault(StringParam, 'price_asc'),
  });

  const [r, setR] = useState(0);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [properties, setProperties] = useState([]);
  const [resultCount, setResultCount] = useState(0);

  const apiFilters = useMemo(() => {
    return {
      ...PropertyFiltersParser(params),
      offset: (page - 1) * limit,
      limit,
    };
  }, [page, limit, params, r]);

  const getProperties = async () => {
    try {
      setLoading(true);

      if (typeof cancelToken !== typeof undefined) {
        cancelToken.cancel('Operation canceled due to new request.');
      }

      cancelToken = axios.CancelToken.source();

      const { data } = await getAllProperties(apiFilters, cancelToken.token);
      setLoading(false);
      setResultCount(data.count);
      setProperties([...properties, ...data.results]);
    } catch (error) {
      if (axios.isCancel(error)) {
        console.log('Request canceled', error.message);
        return;
      }
      openSnackbar({
        children: 'Network error, please try again',
        variant: 'error',
        autoHideDuration: 2000,
      });
      console.error(error);
    }
  };

  useEffect(() => {
    getProperties();
  }, [apiFilters]);

  const fetchMore = async () => {
    setPage((page) => page + 1);
  };

  const sort = useMemo(() => sortCriteria.find(({ value }) => value === params.sort_by), [
    params.sort_by,
    sortCriteria,
  ]);

  /**
   * Partial update page params
   * @type {function(*): void}
   */
  const setParamsAndResetPage = useCallback(
    (value) => {
      setPage(1);
      setProperties([]);
      setR(Math.random());
      if (value && value.all_estimates) {
        value.proposals = undefined;
        value.estimates = undefined;
      }
      if (value && value.all_estimates) {
        value.all_estimates = undefined;
      }
      _setParams((params) => ({ ...params, ...value }));
    },
    [setPage, setProperties, _setParams]
  );

  const setSortAndResetPage = (value) => {
    setParamsAndResetPage({ sort_by: value.value });
  };

  const resetParams = () => {
    setParamsAndResetPage(initialSelection());
  };

  const setMapBounds = (sw, ne) => {
    const bbox = `${sw.lat},${sw.lng};${ne.lat},${ne.lng}`;
    setParamsAndResetPage({ bbox });
  };

  return {
    properties,
    resultCount,
    fetchMore,
    params,
    sort,
    apiFilters,
    setMapBounds,
    resetParams,
    setParamsAndResetPage,
    setSortAndResetPage,
    setLimit,
  };
};

export default useSearchParams;

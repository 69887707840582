import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  propValue: {
    color: theme.palette.primary.main,
    fontWeight: 'bold',
    fontSize: (props) => (props.marketValue ? '3rem' : props.fontSize),
    fontFamily: "'Montserrat', sans-serif;",
  },
  marketValue: {
    color: '#b7b7b7',
    fontSize: '1rem',
    marginTop: '-1rem',
    fontWeight: 'bold',
    letterSpacing: '0.5px',
    fontFamily: "'Montserrat', sans-serif;",
  },
  content: {
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    position: 'absolute',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  container: {
    display: 'flex',
    justifyContent: 'center',
    position: 'relative',
  },
}));

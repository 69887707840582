import React from 'react';
import { Typography } from '@material-ui/core';

import useIsMobile from 'hooks/useIsMobile';

import TextButton from 'components/atoms/TextButton';
import FilterDropdown from 'components/molecules/FilterDropdown';

import EstimatesChildren from './components/Estimates';
import LocationChildren from './components/Location';
import MoreChildren from './components/More';
import PriceChildren from './components/Price';
import SizesChildren from './components/Size';
import { useStyles } from './styles';

/**
 * Shows a set of property filters
 * @param handleFilter Triggered when the filters are updated
 * @param currentSelection
 * @param onReset
 */
const PropertyFilters = ({ handleFilter, currentSelection, onReset, locations }) => {
  const isMobile = useIsMobile();
  const classes = useStyles();

  return (
    <div className={classes.root}>
      {isMobile && (
        <Typography variant={'body1'} className={classes.title}>
          Search Filters
        </Typography>
      )}
      <div className={classes.locationFilter}>
        <FilterDropdown
          selectIcon="locationOnOutlinedIcon"
          label="Locations"
          MenuProps={{ classes: { paper: classes.locationMenu } }}
        >
          <LocationChildren
            locations={locations}
            currentSelection={currentSelection}
            onChange={handleFilter}
            multiple
          />
        </FilterDropdown>
      </div>
      <div className={classes.moneyFilter}>
        <FilterDropdown selectIcon="money" hasArrow={false} width="auto" label="Price">
          <PriceChildren currentSelection={currentSelection} onChange={handleFilter} />
        </FilterDropdown>
      </div>
      <div className={classes.sizeFilter}>
        <FilterDropdown selectIcon="rule" hasArrow={false} width="auto" label="Size">
          <SizesChildren currentSelection={currentSelection} onChange={handleFilter} />
        </FilterDropdown>
      </div>
      <div className={classes.estimatesFilter}>
        <FilterDropdown selectIcon="barChart" hasArrow={false} width="auto" label="Estimates">
          <EstimatesChildren currentSelection={currentSelection} onChange={handleFilter} />
        </FilterDropdown>
      </div>
      <div className={classes.moreFilter}>
        <FilterDropdown selectIcon="flag" hasArrow={false} width="auto" label="More">
          <MoreChildren />
        </FilterDropdown>
      </div>
      {!isMobile && <TextButton label="Reset" onClick={onReset} className={classes.resetButton} />}
    </div>
  );
};

export default PropertyFilters;

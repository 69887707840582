import React from 'react';
import { makeStyles } from '@material-ui/core';

import Icon from 'components/atoms/Icon';

const useStyles = makeStyles(() => ({
  root: {
    position: 'absolute',
    left: ({ size }) => -size / 2,
    top: ({ size }) => -size,
  },
  children: {
    position: 'absolute',
    left: '50%',
    top: ({ size }) => -size * 1.2,
    transform: 'translate(-50%, -50%)',
    width: '20em',
    zIndex: 2,
  },
  icon: {
    zIndex: 1,
    position: 'absolute',
  },
}));

/**
 * Renders a Marker in the desired coordinates
 *
 * @param {number} lat Number for the latitude
 * @param {number} lng Number for the longitude
 * @param {string} icon Name of the icon to be rendered
 * @param {number} size Marker size
 * @param {boolean} isActive Controls whether the children are rendered or not
 * @param onMouseLeave
 * @param {any} children
 */
const Marker = ({
  lat,
  lng,
  icon = 'locationOnOutlinedIcon',
  size = 40,
  isActive,
  onMouseLeave,
  children,
}) => {
  const classes = useStyles({ size });

  return (
    <div className={classes.root} lat={lat} lng={lng}>
      <Icon name={icon} size={size} className={classes.icon} />
      {isActive && (
        <div className={classes.children} onMouseLeave={onMouseLeave}>
          {children}
        </div>
      )}
    </div>
  );
};

export default Marker;

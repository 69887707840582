import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  title: {
    fontSize: '1.25rem',
    lineHeight: '1.875rem',
    paddingBottom: '1em',
  },
  relatedList: {
    display: 'flex',
    flexDirection: ({ seeMore, isMobile }) => (seeMore && !isMobile ? 'row' : 'column'),
    flexWrap: 'wrap',
    position: 'relative',
    width: '100%',
  },
  relatedListItem: {
    padding: ({ seeMore }) => (seeMore ? theme.spacing(2) : theme.spacing(0, 0, 3, 0)),
    width: ({ seeMore, isMobile }) => (seeMore && !isMobile ? '50%' : '100%'),
  },
  seeMore: {
    padding: theme.spacing(2),
    display: 'flex',
    justifyContent: 'flex-end',
  },
}));

import React from 'react';

import getImageUrl from 'utils/getImageUrl';

/**
 * Image now using cloudinary params for tweaks
 */
const Image = ({ src, alt, opts, ...rest }) => {
  return <img src={getImageUrl(src, opts)} alt={alt} {...rest} />;
};

export default Image;

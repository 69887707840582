import React from 'react';
import { Paper } from '@material-ui/core';

import Avatar from 'components/atoms/Avatar';
import Icon from 'components/atoms/Icon';
import Menu from 'components/atoms/Menu';

import { useStyles } from './MobileHeader.styles';

const AvatarMenu = ({ userImage, menuOptions, handleClick, handleClose, anchorEl }) => {
  const classes = useStyles({ userImage });

  return (
    <Paper className={classes.registered}>
      <div onClick={(event) => handleClick(event)} className={classes.avatarWrapper}>
        {userImage ? (
          <Avatar image={userImage} size="small" />
        ) : (
          <Icon name={'account'} size={'1.5rem'} color={'#FFFFFF'} />
        )}
      </div>
      <Menu
        options={menuOptions}
        keepMounted
        anchorEl={anchorEl}
        onClose={handleClose}
        open={!!anchorEl}
        PaperProps={{
          style: {
            width: '100%',
            left: '0',
          },
        }}
      />
    </Paper>
  );
};

export default AvatarMenu;

import React, { useEffect, useState } from 'react';
import { Button } from '@material-ui/core';
import { LinearProgress as MuiLinearProgress } from '@material-ui/core';

import { useStyles } from './styles';

export default function LinearProgress({ onClickFn, isMobile, isDisabled }) {
  const classes = useStyles();
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    if (!isDisabled) {
      setProgress(100);
    }
  }, [isDisabled]);

  return (
    <div className={classes.root}>
      <div className={classes.container}>
        <MuiLinearProgress
          className={classes.linearProgress}
          variant="determinate"
          value={progress}
        />
        <Button
          size={isMobile ? 'medium' : 'large'}
          variant="contained"
          color="primary"
          onClick={onClickFn}
          disabled={isDisabled}
        >
          Finish
        </Button>
      </div>
    </div>
  );
}

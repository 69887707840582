import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  formControl: {
    width: '100%',
    '& fieldset': {
      borderColor: `${theme.palette.primary.bg} !important`,
    },
    '& svg': {
      color: theme.palette.primary.main,
      display: ({ hasArrow }) => !hasArrow && 'none',
    },
  },
  selectIcon: {
    position: 'absolute',
    top: 13,
    left: 4,
    marginRight: '20px',
    color: `${theme.palette.success.main} `,
    display: 'block !important',
  },
  selectWrapper: {
    position: 'relative',
  },
  lineSeparator: {
    borderLeft: `1px solid ${theme.palette.primary.bg}`,
    height: '29px',
    position: 'absolute',
    left: 30,
    top: 10,
  },
  select: {
    width: '100%',
    '& div': {
      paddingLeft: ({ selectIcon }) => (selectIcon ? '40px' : '10px'),
      '&:focus': {
        backgroundColor: 'transparent',
      },
    },
    height: (props) => props.hidden && 0,
    visibility: (props) => props.hidden && 'hidden',
    color: ({ selectTextColor }) => selectTextColor,
  },
  accordionRoot: {
    width: '100%',
    boxShadow: 'none',
    border: `1px solid ${theme.palette.primary.bg}`,

    '&.Mui-expanded': {
      border: 'none',
      [theme.breakpoints.only('xs')]: {
        '& svg': {
          top: 0,
        },
      },
    },

    '& .MuiAccordionSummary-root.Mui-expanded': {
      paddingRight: '0',
      minHeight: '0',
      border: 'none',
      borderBottom: `1px solid ${theme.palette.primary.bg}`,
    },
  },
  accordionSummary: {
    boxShadow: 'none',
    border: 'none',
    '& .MuiAccordionSummary-content': {
      paddingLeft: ({ selectIcon }) => (selectIcon ? '30px' : '10px'),
      '&:focus': {
        backgroundColor: 'transparent',
      },
    },
    '& .Mui-expanded': {
      minHeight: '0',
      margin: '0 !important',
      border: 'none',
    },
  },
}));

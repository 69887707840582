import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Container, Paper } from '@material-ui/core';

import routes from 'router/routes';

import Icon from 'components/atoms/Icon';
import Logo from 'components/atoms/Logo';

import AvatarMenu from './AvatarMenu';
import { useStyles } from './MobileHeader.styles';

const MobileHeader = ({
  items,
  user,
  openLogin,
  menuOptions,
  handleClick,
  handleClose,
  anchorEl,
}) => {
  const classes = useStyles();

  const [open, setOpen] = useState(false);

  return (
    <Container className={classes.root}>
      <div className={classes.menu} onClick={() => setOpen(true)}>
        <Icon name={'menu'} size={'2rem'} color={'#000000'} />
      </div>
      <div className={classes.logo}>
        <Link to={routes.results}>
          <Logo />
        </Link>
      </div>
      <div>
        {!user ? (
          <Paper className={classes.user} onClick={() => openLogin()}>
            <Icon name={'account'} size={'1.5rem'} color={'#C1F4C1'} />
          </Paper>
        ) : (
          <AvatarMenu
            userImage={user.image}
            menuOptions={menuOptions}
            handleClick={handleClick}
            handleClose={handleClose}
            anchorEl={anchorEl}
          />
        )}
      </div>
      {open && (
        <div className={classes.openedMenu}>
          {items &&
            items.map((item) => (
              <span key={item.label} className={classes.link}>
                {item.external ? (
                  <a href={item.path} target="_blank" rel="noopener noreferrer">
                    {item.label}
                  </a>
                ) : (
                  <Link to={item.path} key={item.label}>
                    {item.label}
                  </Link>
                )}
              </span>
            ))}
          <div className={classes.closeMenuIcon} onClick={() => setOpen(false)}>
            <Icon name={'close'} color={'primary'} size={'2rem'} />
          </div>
        </div>
      )}
    </Container>
  );
};

export default MobileHeader;

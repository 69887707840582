const MuiInputBase = {
  root: {},
  adornedEnd: {
    paddingRight: '1em',
  },
  adornedStart: {
    paddingLeft: '1em',
  },
};

export default MuiInputBase;

import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    background: theme.palette.secondary.main,
    boxShadow: '0 4px 10px rgba(0, 0, 0, 0.1)',
    borderRadius: theme.spacing(2),
    overflow: 'hidden',
  },
  managerCount: {
    color: theme.palette.primary.light,
    fontSize: '1.2em',
    fontWeight: 'bold',
    margin: theme.spacing(1),
  },
  imageWrapper: {
    width: '25%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'black',
    [theme.breakpoints.only('xs')]: {
      width: '20%',
    },
  },
  image: {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
  },
  right: {
    width: '75%',
    padding: theme.spacing(1.2),
    '& div': {
      display: 'flex',
      alignItems: 'center',
      lineHeight: '1.5rem',
    },
    [theme.breakpoints.only('xs')]: {
      display: 'flex',
      justifyContent: 'space-between',
      width: '80%',
    },
  },
  rightTop: {
    marginBottom: theme.spacing(0.5),
    justifyContent: 'space-between',
    [theme.breakpoints.only('xs')]: {
      flexDirection: 'column',
      alignItems: 'start!important',
      marginBottom: '0',
    },
  },
  value: {
    fontWeight: 'bold',
    fontSize: '1rem',
  },
  rightBottom: {
    justifyContent: 'space-between',
  },
  bold: {
    fontWeight: 700,
  },
  features: {
    display: 'flex',
    fontSize: '1.125rem',
    lineHeight: '1.688rem',
    [theme.breakpoints.only('xs')]: {
      fontSize: '0.875rem',
    },
  },
  placeholder: {
    width: '90%',
  },
}));

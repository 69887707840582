/**
 * Transforms a backend Catalog into a frontend catalog
 * @param {*} catalog Catalog object coming from the backend
 */
const normalizeCatalog = (catalog) => {
  const {
    id,
    name,
    user,
    properties,
    more_details: moreDetails,
    public: isPublic,
    dynamic: isDynamic,
    permanent: isPermanent,
    slug,
  } = catalog;
  const isFavoritesCatalog = catalog.name.toLowerCase() === 'favorites';

  return {
    id,
    name,
    image: user?.image,
    user: user?.id,
    properties,
    moreDetails,
    propertyCount: properties?.length,
    isPublic,
    isDynamic,
    isPermanent,
    isFavoritesCatalog,
    slug,
  };
};

export default normalizeCatalog;

import * as Yup from 'yup';

const createCatalog = (t) =>
  Yup.object({
    name: Yup.string()
      .required(t('required'))
      .test('maxLen', t('lengthError'), (val) => val?.length < 60),
  });

export default createCatalog;

import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

const useFullUrl = () => {
  const [url, setUrl] = useState('');
  const { pathname, search } = useLocation();
  useEffect(() => {
    pathname && setUrl(`${window.location.protocol}//${window.location.host}${pathname}${search}`);
  }, [pathname]);
  return url;
};

export default useFullUrl;

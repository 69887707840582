import React from 'react';
import MuiButton from '@material-ui/core/Button';

import Icon from 'components/atoms/Icon';

const variantPropsMap = {
  primary: {
    variant: 'contained',
    color: 'primary',
  },
  secondary: {
    variant: 'outlined',
    color: 'primary',
  },
  third: {
    variant: 'contained',
    color: 'secondary',
  },
};

/**
 * @param {string} label - Label that is displayed on the button
 * @param {string} variant - primary, secondary, third
 * @param {('large'\'medium'\'small')} size - small, medium or large
 * @param {boolean} disabled disable button
 * @param {string} icon - Icon name to be rendered
 * @param {object} iconProps - Props for the Icon
 */
const Button = ({
  label,
  variant = 'primary',
  size = 'large',
  disabled,
  icon,
  iconProps,
  ...rest
}) => {
  const IconComponent = () => <Icon name={icon} {...iconProps} />;
  return (
    <MuiButton
      size={size}
      disabled={disabled}
      {...variantPropsMap[variant]}
      {...(icon && label && { endIcon: <IconComponent /> })}
      {...rest}
    >
      {icon && !label ? <IconComponent /> : label}
    </MuiButton>
  );
};

export default Button;

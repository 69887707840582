import React from 'react';
import { Route, Router } from 'react-router-dom';
import { Box } from '@material-ui/core';
import SimpleReactLightbox from 'simple-react-lightbox';
import { QueryParamProvider } from 'use-query-params';

import {
  DialogProvider,
  I18nProvider,
  SnackbarProvider,
  ThemeProvider,
  UserProvider,
} from 'providers';
import history from 'router/history';
import ViewContainer from 'router/ViewContainer';

const App = () => {
  return (
    <ThemeProvider>
      <SimpleReactLightbox>
        {process.env.REACT_APP_ENV === 'staging' && (
          <Box
            style={{
              fontSize: '.7em',
              backgroundColor: 'red',
              position: 'fixed',
              height: '15px',
              width: '100%',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
              zIndex: '9999',
            }}
          >
            STAGE STAGE STAGE STAGE STAGE STAGE STAGE STAGE STAGE STAGE STAGE STAGE STAGE STAGE
            STAGE STAGE STAGE STAGE STAGE STAGE STAGE STAGE STAGE STAGE STAGE STAGE STAGE STAGE
            STAGE STAGE STAGE STAGE STAGE STAGE STAGE STAGE STAGE STAGE STAGE STAGE STAGE STAGE
            STAGE STAGE STAGE STAGE STAGE STAGE STAGE STAGE STAGE STAGE STAGE STAGE STAGE STAGE
            STAGE STAGE STAGE STAGE STAGE STAGE STAGE STAGE STAGE STAGE STAGE STAGE STAGE STAGE
            STAGE STAGE STAGE STAGE STAGE STAGE STAGE STAGE STAGE STAGE STAGE STAGE STAGE STAGE
            STAGE STAGE STAGE STAGE STAGE STAGE STAGE STAGE STAGE STAGE STAGE STAGE STAGE STAGE
          </Box>
        )}
        <Router history={history}>
          <QueryParamProvider ReactRouterRoute={Route}>
            <UserProvider>
              <I18nProvider>
                <SnackbarProvider>
                  <DialogProvider>
                    <ViewContainer />
                  </DialogProvider>
                </SnackbarProvider>
              </I18nProvider>
            </UserProvider>
          </QueryParamProvider>
        </Router>
      </SimpleReactLightbox>
    </ThemeProvider>
  );
};

export default App;

import MuiAccordionDetails from './MuiAccordionDetails';
import MuiAvatarGroup from './MuiAvatarGroup';
import MuiButton from './MuiButton';
import MuiCssBaseline from './MuiCssBaseline';
import MuiDialog from './MuiDialog';
import MuiDrawer from './MuiDrawer';
import MuiFormControlLabel from './MuiFormControlLabel';
import MuiIconButton from './MuiIconButton';
import MuiInputAdornment from './MuiInputAdornment';
import MuiInputBase from './MuiInputBase';
import MuiMenu from './MuiMenu';
import MuiMenuItem from './MuiMenuItem';
import MuiOutlinedInput from './MuiOutlinedInput';
import MuiPaper from './MuiPaper';
import MuiSelect from './MuiSelect';
import MuiTextField from './MuiTextField';

export default {
  MuiButton,
  MuiCssBaseline,
  MuiSelect,
  MuiInputBase,
  MuiPaper,
  MuiFormControlLabel,
  MuiTextField,
  MuiOutlinedInput,
  MuiDrawer,
  MuiInputAdornment,
  MuiAccordionDetails,
  MuiMenuItem,
  MuiMenu,
  MuiDialog,
  MuiIconButton,
  MuiAvatarGroup,
};

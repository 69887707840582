import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { CircularProgress, DialogActions, Typography } from '@material-ui/core';
import { Form, Formik } from 'formik';

import useIsMobile from 'hooks/useIsMobile';
import ForgotPasswordModal from 'modals/ForgotPasswordModal';
import { useDialog } from 'providers/DialogProvider';
import { useUserContext } from 'providers/UserProvider';
import routes from 'router/routes';
import { executeLogin } from 'services/authService';
import signupSchema from 'validators/signup';

import Button from 'components/atoms/Button';
import TextInput from 'components/atoms/Formik/Input';

import { useStyles } from './styles';

const initialCreateAccount = {
  username: '',
  password: '',
  terms: true,
};

const LoginForm = ({ errorMessage, setShowSecondStep, setUsername, setPassword, title }) => {
  const [openDialog, closeDialog, clearDialogs] = useDialog();
  const isMobile = useIsMobile();
  const { reload, loading, setLoading } = useUserContext();
  // const [loading, setLoading] = useState(false);
  const [showError, setShowError] = useState(false);
  const history = useHistory();
  const [action, setAction] = useState('login');

  let matchResetPasswordRoute = useRouteMatch('/resetpassword/:id/:token');

  const classes = useStyles();
  const { t } = useTranslation('auth');

  const onSubmitLogin = async ({ username, password }, { setSubmitting }) => {
    if (!loading) {
      setLoading(true);
      const success = await executeLogin(username, password);
      if (!success) {
        setShowError(true);
        //only set loading to false here
        setLoading(false);
      } else {
        reload();
        clearDialogs();

        if (matchResetPasswordRoute?.isExact) {
          history.push('/');
        }
      }
      setSubmitting(false);
    }
  };

  const openForgotPasswordModal = () => {
    closeDialog({ silent: true });
    openDialog({
      children: <ForgotPasswordModal />,
      logo: true,
      onKill: () => history.push(routes.results),
      blurred: true,
    });
  };

  const createAccount = ({ username, password }) => {
    if (username && password) {
      setUsername(username);
      setPassword(password);
      setShowSecondStep(true);
    }
  };

  const handleAccess = (values, actions) => {
    switch (action) {
      case 'login':
        onSubmitLogin(values, actions);
        break;
      case 'create':
        createAccount(values);
        break;

      default:
        break;
    }
  };

  return (
    <>
      {loading ? (
        <div className={classes.loading}>
          <CircularProgress color="primary" />
        </div>
      ) : (
        <Formik
          onSubmit={handleAccess}
          initialValues={initialCreateAccount}
          validationSchema={signupSchema(t)}
        >
          {({ handleSubmit }) => (
            <Form className={classes.root}>
              <div className={classes.container}>
                {title && (
                  <div className={classes.label} style={{ fontSize: '1.1rem' }}>
                    {title}
                  </div>
                )}

                <div className={classes.contentFields}>
                  <TextInput
                    name="username"
                    placeholder={t('userPlaceholder')}
                    fullWidth
                    className={classes.input}
                  />
                  <div className={classes.inputWrapper}>
                    <TextInput
                      name="password"
                      type={'password'}
                      placeholder={'password'}
                      fullWidth
                    />
                  </div>
                  {showError && (
                    <div className={classes.errorAuthWrapper}>
                      <Typography variant={'body1'} className={classes.errorAuth}>
                        {errorMessage?.length ? errorMessage : t('authError')}
                      </Typography>
                    </div>
                  )}
                </div>
                <div>
                  <DialogActions className={classes.actions}>
                    <Button
                      label={t('logInSubmit')}
                      size={isMobile ? 'medium' : 'large'}
                      variant={'third'}
                      fullWidth
                      type="submit"
                      onClick={() => {
                        setAction('login');
                        handleSubmit();
                      }}
                    />
                    <span className={classes.or}>{t('or')}</span>
                    <Button
                      label={t('signUpSubmit')}
                      size={isMobile ? 'medium' : 'large'}
                      variant={'primary'}
                      onClick={() => {
                        setAction('create');
                        handleSubmit();
                      }}
                      fullWidth
                    />
                  </DialogActions>
                  <div className={classes.forgotPasswordWrapper} onClick={openForgotPasswordModal}>
                    <Typography variant={'body1'} className={classes.forgotPassword}>
                      {t('forgotPassword')}
                    </Typography>
                  </div>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      )}
    </>
  );
};

export default LoginForm;

const MuiPaper = {
  root: {
    width: '20vw',
  },
  elevation1: {
    boxShadow: '0 4px 10px rgba(0,0,0, 0.1)',
  },
};

export default MuiPaper;

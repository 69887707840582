import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  root: {
    '& a': {
      textDecoration: 'underline',
      color: theme.palette.secondary.main,
    },
  },
  messageWrapper: {
    borderRadius: theme.spacing(1),
    padding: theme.spacing(3),
    color: theme.palette.secondary.main,
    backgroundColor: ({ variant }) => variant,
    minWidth: '800px',
    '& svg': {
      cursor: 'pointer',
    },
    [theme.breakpoints.only('xs')]: {
      width: '100%',
      minWidth: 0,
    },
  },
  iconWrapper: {
    display: 'flex',
    position: 'absolute',
    right: 0,
    top: '50%',
    transform: 'translateY(-50%)',
    paddingRight: theme.spacing(1),
    alignItems: 'center',
  },
}));

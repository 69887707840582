import { createContext, useContext, useRef, useState } from 'react';

import getUniqueId from 'utils/getUniqueId';

import DialogContainer from './DialogContainer';

const defaultContext = {};
const DialogContext = createContext(defaultContext);

const DialogProvider = ({ children }) => {
  const [dialogs, setDialogs] = useState([]);

  /**
   *
   * @param {{silent: boolean}} options Options. Silent TRUE means the dialog closes without firing the onClose callback
   */
  const closeDialog = ({ silent = false } = {}) => {
    setDialogs((dialogs) => {
      const latestDialog = dialogs.pop();
      if (!latestDialog || silent) return dialogs;
      return [...dialogs].concat({ ...latestDialog, open: false });
    });
  };

  const createDialog = (option) => {
    const dialog = {
      key: `dialog-${getUniqueId()}`,
      open: true,
      ...option,
      onClose: closeDialog,
    };
    setDialogs((dialogs) => [...dialogs, dialog]);
  };

  const clearDialogs = () => {
    setDialogs([]);
  };

  const value = useRef([createDialog, closeDialog, clearDialogs]);

  return (
    <DialogContext.Provider value={value}>
      {children}
      {dialogs.map(({ key, ...dialog }) => {
        return <DialogContainer key={key} {...dialog} />;
      })}
    </DialogContext.Provider>
  );
};

/**
 * A hook for dialog management
 *
 * Usage:
 *
 * const [openDialog, closeDialog, clearDialogs] = useDialog();
 * openDialog({
 *   children: <YourContent />,
 *   title: "Content blah"
 * })
 *
 * @return [openDialog, closeDialog, clearDialogs]
 */
export const useDialog = () => {
  const dialogContext = useContext(DialogContext);

  if (!dialogContext) {
    throw new Error('DialogContext not found');
  }

  return dialogContext.current;
};

export default DialogProvider;

import { useEffect } from 'react';

import { useUserContext } from 'providers/UserProvider';
import { getDeviceId } from 'services/fingerprintService';

export const useCanView = () => {
  const { getViewAreas, reload } = useUserContext();
  const getFingerprint = async () => {
    await getDeviceId();
    getViewAreas();
  };
  useEffect(() => {
    getFingerprint();
  }, [reload]);
};

import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  button: {
    textTransform: 'none',
    minWidth: 'unset',
  },
  textOnly: {
    fontSize: '1.25em',
    lineHeight: '1.875em',
    fontWeight: '400',
    color: theme.palette.secondary[800],
  },
  iconText: {
    fontSize: '1.125em',
    lineHeight: '1.688em',
    fontWeight: '400',
    color: 'rgba(0,0,0,0.5)',
    alignItems: 'end',
  },
  icon: (active) => ({
    fontSize: 22,
    color: active ? theme.palette.primary.main : theme.palette.text.primary,
  }),
}));

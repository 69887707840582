import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  childRoot: {
    padding: '0.5em 0 0 0',
    width: '100%',
    [theme.breakpoints.up('md')]: {
      padding: '1.286em',
    },
  },
  innerWrapper: {
    display: 'flex',
    flexDirection: 'row',
    [theme.breakpoints.up('md')]: {
      flexDirection: 'column',
    },
  },
  row: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: '0.8em',
    [theme.breakpoints.up('md')]: {
      '&:first-child': {
        marginRight: '0',
      },
    },
  },
  title: {
    fontSize: '1.143em',
    fontWeight: '700',
    marginBottom: '0.6em',
  },
  labelColumn: {
    flex: '1',
    display: 'flex',
  },
  inputColumn: {
    display: 'flex',
  },
  inputWrapper: {
    width: '71px',
    [theme.breakpoints.up('md')]: {
      width: 'auto',
    },
  },
  inputText: {
    display: 'flex',
    alignItems: 'center',
    padding: '0',
    fontSize: '18px',
    marginRight: '10px',
    marginLeft: '0.4em',
    color: theme.palette.text.primary,
    [theme.breakpoints.up('md')]: {
      padding: '0 10px',
      fontWeight: '700',
    },
  },

  middleText: {
    display: 'flex',
    alignItems: 'center',
    padding: '0',
    fontSize: '18px',
    margin: '0 10px',
    color: theme.palette.text.primary,
    [theme.breakpoints.up('md')]: {
      padding: '0 10px',
    },
  },
}));

import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { generatePath, useHistory, useLocation } from 'react-router-dom';

import useIsMobile from 'hooks/useIsMobile';
import AccessModal from 'modals/AccessModal';
import { useDialog } from 'providers/DialogProvider';
import { useUserContext } from 'providers/UserProvider';
// import { isManager } from 'providers/UserProvider';
import routes from 'router/routes';
import { clearToken } from 'services/authService';
import { clearFingerprint } from 'services/fingerprintService';

import DesktopHeader from './components/DesktopHeader';
import MobileHeader from './components/MobileHeader';

const Header = () => {
  const { user, reload } = useUserContext();
  const { pathname } = useLocation();
  const history = useHistory();
  const [openDialog] = useDialog();
  const [anchorEl, setAnchorEl] = useState(null);

  const { t } = useTranslation('menu');

  const isMobile = useIsMobile();

  const headerItems = [
    {
      label: t('searchProperties'),
      path: routes.results,
    },
    {
      label: t('buyers'),
      path: 'https://www.propersum.io/vacation-property-buyers',
      external: true,
    },
    {
      label: t('agents'),
      path: 'https://www.propersum.io/real-estate-agents',
      external: true,
    },
    {
      label: t('managers'),
      path: 'https://www.propersum.io/vacation-rental-managers',
      external: true,
    },
  ];

  const menuOptions = [
    {
      label: t('myDashboard'),
      action: () => {
        history.push(generatePath(routes.dashboard, { tab: 'catalogs' }));
        setAnchorEl(null);
      },
    },
    // ...(isManager(user)
    //   ? [
    //       {
    //         label: t('proposals'),
    //         action: () => {
    //           history.push(generatePath(routes.dashboard, { tab: 'proposals' }));
    //           setAnchorEl(null);
    //         },
    //       },
    //     ]
    //   : []),
    {
      label: t('myFavorites'),
      action: () => {
        history.push(generatePath(routes.dashboard, { tab: 'catalogs' }));
        setAnchorEl(null);
      },
    },
    {
      label: t('myAccount'),
      action: () => {
        history.push(generatePath(routes.dashboard, { tab: 'account' }));
        setAnchorEl(null);
      },
    },
    {
      label: t('settings'),
      action: () => {
        history.push(generatePath(routes.dashboard, { tab: 'settings' }));
        setAnchorEl(null);
      },
    },
    {
      label: t('signOut'),
      action: () => {
        setAnchorEl(null);
        history.push(generatePath(routes.properties));
        clearToken();
        clearFingerprint();
        reload();
      },
    },
  ];

  const openLogin = () => {
    openDialog({
      children: <AccessModal />,
      logo: true,
    });
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const props = {
    items: headerItems,
    activePath: pathname,
    user,
    anchorEl,
    openLogin,
    handleClick,
    handleClose,
    menuOptions,
  };

  return isMobile ? <MobileHeader {...props} /> : <DesktopHeader {...props} />;
};

export default Header;

import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: theme.spacing(2),
    zIndex: '99',
  },
  openedMenu: {
    background: theme.palette.secondary.main,
    color: theme.palette.primary.main,
    position: 'absolute',
    top: '0',
    left: '0',
    height: '100vh',
    width: '100vw',
    padding: '1.3em',
    display: 'flex',
    flexDirection: 'column',
    zIndex: 102,
  },
  link: {
    fontSize: '1.3em',
    fontWeight: '500',
    paddingBottom: '0.5em',
    fontFamily: theme.typography.fontFamily,
    color: theme.palette.primary.main,
  },
  closeMenuIcon: {
    position: 'absolute',
    top: '1.3em',
    right: '1.3em',
    cursor: 'pointer',
  },
  menu: {
    cursor: 'pointer',
  },
  user: {
    cursor: 'pointer',
    width: '2.1875em',
    height: '2.1875em',
    background: theme.palette.common.white,
    borderRadius: '50%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  registered: {
    cursor: 'pointer',
    width: '2.1875em',
    height: '2.1875em',
    background: ({ userImage }) =>
      userImage ? theme.palette.common.white : theme.palette.primary.main,
    borderRadius: '50%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  avatarWrapper: {
    display: 'flex',
    justifyContent: 'center',
  },
}));

import { initReactI18next } from 'react-i18next';
import { capitalize } from '@material-ui/core';
import i18n from 'i18next';

import en from './locales/en';
import es from './locales/es';

i18n.use(initReactI18next).init({
  initImmediate: false,
  preload: ['en', 'es'],
  lng: 'en',
  defaultLanguage: 'en',
  otherLanguages: ['es'],
  fallbackLng: 'en',
  debug: false,
  interpolation: {
    format: (value, format) => (format === 'capitalize' ? capitalize(value) : value),
  },
  resources: {
    en,
    es,
  },
});

export default i18n;

export default {
  brand: 'Propersum®',
  goHome: 'Ir al inicio',
  pages: {
    results: {
      title: 'Buscar propiedades',
      description: 'Buscar propiedades',
      otherProperties: 'Otras propiedades',
    },
    resultsByLocation: {
      title: 'Vacation rentals for sale in {{area}} — Propersum',
      description:
        '{{area}} vacation rentals for sale with reliable rental estimates from local property managers.',
    },
    profile: {
      title: 'Perfil {{professional}}',
      description: 'Perfil {{professional}}',
      error: 'Hubo un error de nuestro lado',
      noUser: 'No se ha encontrado el perfil',
      ourCompany: 'Nuestra Compañía',
      video: 'Video',
      technologiesWeUse: 'Tecnologías y Servicios que utilizamos',
      businessFounded: 'Fundación de la Empresa',
      unitsUnderManagement: 'Unidades bajo gestión ',
      fullTimeEmployees: 'Empleados de tiempo completo',
      unitsPerEmployeeRatio: 'Proporción de unidades por empleado',
    },
  },
  menu: {
    searchProperties: 'Buscar Propiedades',
    buyers: 'Buyers',
    agents: 'Agentes',
    managers: 'Managers',
    myDashboard: 'Mi Dashboard',
    proposals: 'Proposals',
    myFavorites: 'Mis favoritos y Catálogos',
    myAccount: 'Mi Cuenta',
    settings: 'Configuración',
    login: 'Iniciar sesión',
    signOut: 'Cerrar Sesión',
  },
  home: {
    title: 'INICIO',
    redirect: 'REDIRIGIR',
    today: 'Hoy es el día',
    button: 'Disparar acción de ejemplo',
    values: 'Resultado del store (disparar una acción para cargar más)',
    login: 'Iniciar sesión',
    noMatches: 'No se encontraron coincidencias.',
    noPropertiesFound: 'No se encontraron propiedades',
  },
  propertyBrowser: {
    resultCount: '{{resultCount}} Resultados',
    sortBy: 'Ordenar por:',
    properties: 'Propiedad',
    properties_plural: 'Propiedades',
  },
  layout: {
    disclaimer:
      'Agents, agencies, brokers, property managers, insurance providers, lenders, advertisers, and PROPERSUM are not liable or responsible for errors and omissions in this material, and no guarantee can be made for any data, rental figures, taxes, insurance, and/or potential income & expenses displayed. PROPERSUM does not provide financial, investment, lending, insurance, or real estate services. The PROPERSUM logo is a registered trademark of PROPERSUM, Inc. All rights reserved ® 2020',
  },
  help: {
    defaultText:
      'Valores para Gross Rents, Management Fees y Otros servicios le serán brindados por el manager de la propiedad.',
  },
  error: {
    default: 'Algo salió mal. Intentalo de nuevo.',
  },
  managersList: {
    managers: 'Managers',
    managersHelp: 'Profesionales que ofrecen servicios para esta propiedad.',
    rents: 'Rents',
    rentsHelp:
      'El total de rentas que un manager espera generar para usted en el transcurso de un año.',
    fee: 'Fee',
    feeHelp:
      'El porcentaje de rentas que un manager va a cargar por brindarte sus servicios profesionales.',
  },
  summary: {
    summaryTitle: 'Summary',
    estimate: 'Estimate',
    grossRents: 'Alquileres Brutos',
    grossRentsHelp: 'Los Alquileres Brutos son brindados por managers de propiedades.',
    managementFee: 'Comisión de Gestión',
    otherServices: 'Otros servicios',
    subtotal: 'Annual Estimate',
  },
  cardOverview: {
    title: 'Resumen de Estimación',
    netPerformance: 'Rendimiento Neto',
    cashOnCash: 'Dinero en efectivo',
  },
  userMenu: {
    buttonTitle: 'Menu de usuario',
    optionMyAccount: 'Mi cuenta',
    optionLogOut: 'Cerrar sesión',
  },
  auth: {
    login: 'Iniciar Sesión',
    signup: 'Registrarse',
    confirmation: 'Continuar',
    forgotPassword: 'Recuperar contraseña',
    userPlaceholder: 'your@email.com*',
    passPlaceholder: 'Contraseña*',
    createPassPlaceholder: 'Crear Contraseña*',
    badCredentials: 'Datos in correctos. Pueden probar nuevamente',
    generalError: 'Ocurrió un error en el proceso de login',
    emailError: 'Email inválido',
    // passwordError:
    //   'La contraseña debe contener 8 o más caracteres, con al menos un carácter alfabético y un carácter numérico. ',
    authError: 'Email o contraseña incorrecta',
    passwordConfirmError: 'Las contraseñas deben coincidir',
    required: 'Requerido',
    termsAndConditions: 'Acepto los Terminos y Condiciones y las Politicas de privacidad',
    userType: 'Qué te describe mejor?',
    accept: 'Acepta los',
    terms: ' Términos y Condiciones y las Politicas de Privacidad',
    termsError:
      'Debe aceptar nuestros Términos y condiciones y Política de privacidad para crear una cuenta.',
    email: 'Email',
    newPassword: 'Nueva contraseña*',
    confirm: 'Confirmar nueva contraseña*',
    accountExists: 'La cuenta ya existe. ',
    accountExistsPlease: 'Por favor ',
    accountExistsLogin: 'inicie sesión aquí',
    accountExistsOr: ' o ',
    accountExistsReset: 'restablezca su contraseña',
    accountExistsNeeded: ' si es necesario.',
  },
  confirmationCatalogModal: {
    yes: 'Si',
    no: 'No',
    defaultError: 'Hubo un error. Por favor intente de nuevo.',
  },
  catalogs: {
    title: 'Catálogos',
    form: {
      titles: {
        create: 'Nuevo catálogo',
        update: 'Editar catálogo',
      },
      name: 'Nombre',
      submit: 'Guardar',
    },
    error: 'Algo salió mal. Intentalo de nuevo',
    catalogBy: 'Catálogo por ',
  },
  property: {
    bedrooms: 'Habitaciones',
    bathrooms: 'Baños',
  },
  actions: {
    save: 'GUARDAR',
    cancel: 'CANCELAR',
    send: 'ENVIAR',
  },
  updateCatalog: {
    changeTitle: 'Cambiar nombre',
    updateCatalogBy: 'Actualizar catalogo por',
    blank: '(en blanco)',
  },
  shareCatalog: {
    title: 'Compartir',
    email: 'Email',
    helpEmail: 'Envia un link de acceso a un destinatario. También serás copiado en el email.',
  },
  requestAccessModal: {
    legend: 'Este catálogo está bloqueado por el dueño. Para solicitar acceso completá tu email.',
    sendButton: 'Enviar',
    sendingEmail: 'Enviando...',
    emailError: 'Email invalido',
    required: 'Requerido.',
    error: 'Error mientras se enviaba el correo electrónico',
  },
  forgotModal: {
    forgot: 'Ingrese su correo electrónico y le enviaremos un mensaje con instrucciones',
    sendButton: 'Enviar',
    sendingEmail: 'Enviando...',
    emailError: 'Email invalido',
    required: 'Requerido.',
    error: 'Error mientras se enviaba el correo electrónico',
    emailPlaceholder: 'tuemail@email.com',
    email: 'Email',
    forgotModalTitle: 'Se olvido su contraseña',
    resetSuccess:
      'Recibirás un correo electrónico con instrucciones para restablecer la contraseña',
  },
  signupModal: {
    title: 'Crea una cuenta gratuita para desbloquear contenido',
  },
  ConfimationCatalogModal: {
    yes: 'Si',
    no: 'No',
  },
  catalogMenuOptions: {
    update: 'Actualizar',
    share: 'Compartir',
    updateCatalogBy: 'Actualizar catalogo por',
    confirm: 'Confirmar',
    delete: 'Eliminar',
    cancel: 'Cancelar',
    deleteConfirmation: 'Estás seguro de querer eliminarlo?',
    deleteCatalog: 'Eliminar catálogo',
    deleteProperty: 'Eliminar propiedad',
  },
  cardMenu: {
    addToACatalog: 'Agregar a catálogo',
    delete: 'Eliminar del catálogo',
    share: 'Compartir',
  },
  createPassword: {
    title: 'Primer ingreso',
    confirmation: 'Cambiar contraseña',
  },
  changePassword: {
    title: 'Primer ingreso',
    confirmation: 'Cambiar contraseña',
  },
  formErrors: {
    lengthMin: 'Demasiado corto',
    lengthMax: 'Limite de caracteres',
    email: 'Email inválido',
    password: 'Formato de contraseña inválido',
    passwordMatch: 'Las contraseñas no coinciden',
  },
  filters: {
    filter: 'Filtrar',
    save: 'Guardar',
    share: 'Compartir',
    list: 'Listado',
    map: 'Mapa',
    apply: 'Aplicar',
    areas: 'Areas',
    bedrooms: 'Habitaciones',
    bathrooms: 'Baños',
    clearFilter: 'Limpiar filtros',
    noResults: 'Sin resultados',
    noResultsHelp: 'Para obtener algún resultado, intenta cambiar los filtros de búsqueda',
    error: 'No se pudieron recuperar resultados. Intentalo de nuevo.',
  },
  validations: {
    requiredField: 'Campo requerido',
    invalidFormat: 'Formato inválido',
  },
  favorites: {
    myFavorites: 'Mis favoritos',
  },
  profileModal: {
    listedBy: 'Listado por ',
  },
  hamburgerMenu: {
    properties: 'Propiedades',
    favorites: 'Favoritos',
    catalogs: 'Catalogos',
    myAccount: 'Mi Cuenta',
  },
  footer: {
    contact: 'Contáctanos',
    terms: 'Términos y políticas',
    copyright: 'Propersum Inc • © {{year}} • all rights reserved.',
  },
  propertyDetails: {
    search: 'Buscar',
    emailTitle: 'Enviar esta propiedad a',
    managerProposalTitle: 'Propuestas del administrador para alquileres vacacionales',
    propertyDetailDescription:
      'Propersum estima que esta propiedad produce entre <0>{{estRentsLow}} y {{estRentsHigh}}</0>, anualmente en bruto ' +
      'de alquiler por vacaciones utilizando una empresa de gestión de buena reputación. ' +
      'Las tarifas del servicio de administración de propiedades se estiman entre el <1>{{estMgmtFeeLow}} y {{estMgmtFeeHigh}}</1>' +
      'de los ingresos brutos por alquiler, excluidos los gastos operativos y de propiedad.',
  },
  catalogList: {
    duplicate: 'Duplicar Catálogo',
    hideModalTitle: '¿Esconder Catálogo?',
    hide: 'Esconder Catálogo',
    rename: 'Renombrar Catálogo',
    delete: 'Borrar Catálogo',
    error: 'Hubo un error al buscar tus catálogos',
    lockMessage:
      '¿Está seguro de que quiere bloquear este catálogo? Bloquearlo hará que éste sea privado',
    unlockMessage:
      '¿Está seguro de que quiere desbloquear este catálogo? Desbloquearlo hará que éste sea público',
    hideMessage:
      'Ocultar el catálogo hará que permanezca oculto permanentemente de su vista. ¿Desea continuar?',
    deleteMessage: '¿Está seguro de que quiere eliminar este catálogo?',
    duplicateMessage: '¿Está seguro de que quiere duplicar este catálogo?',
    lockedTitle: 'Bloquear Catálogo',
    emailTitle: 'Compartir este catalogo con',
    unlockedTitle: 'Desbloquear Catálogo',
    confirmLockedBtnLabel: 'Si, bloquearlo',
    confirmUnlockedBtnLabel: 'Si, desbloquearlo',
    snackbarLockMessage: 'Catalog Bloqueado',
    snackbarUnlockMessage: 'Catalog Desbloqueado',
    share: 'Compartir Catálogo',
  },
  professionalInfo: {
    email: 'Email*',
    emailPlaceholder: 'your@email.com',
    phone: 'Teléfono (Opcional)',
    phonePlaceholder: '(XX) X - XXX - XXXX',
    textArea: 'Su Mensaje',
    send: 'Enviar',
    emailRequired: 'El email es requerido.',
    textRequired: 'El mensaje es requerido.',
    sendingEmail: 'Enviando...',
    error: 'Error mientras se enviaba el correo electrónico',
    view: 'Ver',
    edit: 'Editar',
    sent: 'Mensaje enviado',
  },
  brandedCatalog: {
    emptyCatalog: 'Catalogo vacío',
    searchProperties: 'Buscar propiedades',
    options: 'Opciones',
    page: {
      title: 'Catalogo {{title}}',
      error: 'Hubo un error de nuestro lado',
    },
    duplicate: 'Duplicar Catálogo',
    hideModalTitle: '¿Esconder Catálogo?',
    hide: 'Esconder Catálogo',
    rename: 'Renombrar Catálogo',
    delete: 'Borrar Catálogo',
    share: 'Compartir Catálogo',
    error: 'Hubo un error al buscar tus catálogos',
    lockMessage:
      '¿Está seguro de que quiere bloquear este catálogo? Bloquearlo hará que éste sea privado',
    unlockMessage:
      '¿Está seguro de que quiere desbloquear este catálogo? Desbloquearlo hará que éste sea público',
    hideMessage:
      'Ocultar el catálogo hará que permanezca oculto permanentemente de su vista. ¿Desea continuar?',
    deleteMessage: '¿Está seguro de que quiere eliminar este catálogo?',
    duplicateMessage: '¿Está seguro de que quiere duplicar este catálogo?',
    lockedTitle: 'Bloquear Catálogo',
    unlockedTitle: 'Desbloquear Catálogo',
    shareSearchModalTitle: 'Compartir',
    goHome: 'Ir al inicio',
    property: {
      delete: '¿Está seguro de que quiere eliminar esta propiedad?',
      deleteMessageConfirmation: 'Propiedad eliminada',
      renameCallbackMessage: 'El catálogo ha sido renombrado',
    },
    requestAccessTitle: 'Catálogo Bloqueado',
    successRequest:
      'El dueño del catálogo fue notificado con un pedido de acces. Recibirás un email una vez que el catálogo esté accesible,',

    loginFirst: 'Por favor, ingrese con su usuario e intente de nuevo.',
    defaultError: 'Algo salió mal. Inténtalo de nuevo .',
    unauthorizedError: 'Permiso denegado para realizar esta operation',
    snackbarLockMessage: 'Catalog Bloqueado',
    snackbarUnlockMessage: 'Catalog Desbloqueado',
  },
  addProposalModal: {
    title: 'Agregar Propuesta',
    grossRentEstimate: 'Estimación de alquiler bruto',
    managementFee: 'Tarifa de gestión',
    continue: 'Continuar',
    required: 'Requerido',
    sending: 'Enviando...',
    typeError: 'Debe especificar un número entre 0 y 100.',
    validationNumber: 'El número debe estar entre 0 y 100.',
    defaultError: 'Algo salió mal. Inténtalo de nuevo .',
    messageConfirmation: 'Aprenda a agregar propuestas ilimitadas con Propersum for Managers.',
    modalTitle: 'Agregar más propuestas',
  },
  marketMembershipCard: {
    active: 'Membresía Activa',
    inactive: 'Membresía Inactiva',
  },
  settings: {
    yourSettings: 'Tus configuraciones',
    saveSettingSuccess: 'Configuración guardada 👌',
    saveSettingError: 'Hubo un error al guardar la configuración',
  },
  addressData: {
    street: 'Calle',
    city: 'Ciudad',
    state: 'Estado',
    zipcode: 'Código Postal',
  },
  feedbackModal: {
    title: 'Agregar más propuestas',
    continue: 'Continuar',
    messageConfirmation: 'Aprenda a agregar propuestas ilimitadas con Propersum for Managers. ',
    defaultError: 'Something went wrong. Please try again.',
  },

  estimatesFilter: {
    managerProposalsOnly: 'Solo propuestas de administradores',
    propersumEstimatesOnly: 'Solo estimaciones de Propersum',
    both: 'Ambos',
  },
};

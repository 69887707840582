import React, { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { generatePath } from 'react-router';
import { Link } from 'react-router-dom';
import { Typography } from '@material-ui/core';

import useApiCall from 'hooks/useApiCall';
import SaveToNewCatalogModal from 'modals/SaveToNewCatalogModal';
import { useDialog } from 'providers/DialogProvider';
import { useSnackbar } from 'providers/SnackbarProvider';
import { useUserContext } from 'providers/UserProvider';
import routes from 'router/routes';
import { getUserCatalogs, saveToCatalog } from 'services/catalogService';

import Button from 'components/atoms/Button';
import CenteredLoader from 'components/atoms/CenteredLoader';
import CatalogModalListCard from 'components/molecules/CatalogModalListCard';

import { useStyles } from './styles';

const SaveToCatalogModal = ({ properties, moreDetails }) => {
  const classes = useStyles();
  const [openDialog, closeDialog] = useDialog();
  const [openSnackbar] = useSnackbar();
  const [execGetUserCatalogs, { data: catalogs, error, loading }] = useApiCall(getUserCatalogs);
  const [
    execSaveToCatalog,
    { data: saveToCatalogData, error: saveToCatalogError, loading: saveToCatalogLoading },
  ] = useApiCall(saveToCatalog);
  const { t } = useTranslation('saveToCatalogModal');
  const { user } = useUserContext();

  useEffect(() => {
    const getUserCatalogs = async () => {
      await execGetUserCatalogs(user?.slug);
    };

    getUserCatalogs();
  }, []);

  useEffect(() => {
    if (saveToCatalogData && !saveToCatalogError) {
      closeDialog();
      openSnackbar({
        children: (
          <>
            {t('saveSuccess')}
            <Link to={generatePath(routes.brandedCatalog, { slug: saveToCatalogData.slug })}>
              {t('saveSuccessLink')}
            </Link>
          </>
        ),

        variant: 'success',
      });
    }
  }, [saveToCatalogData, saveToCatalogError]);

  const handleClickCatalog = useCallback(
    async (catalog) => {
      if (properties && !saveToCatalogLoading) {
        await execSaveToCatalog(catalog, properties, moreDetails);
      }
    },
    [properties, saveToCatalogLoading]
  );

  const handleNewCatalog = useCallback(() => {
    if (properties) {
      closeDialog();
      openDialog({
        children: <SaveToNewCatalogModal properties={properties} moreDetails={moreDetails} />,
        title: t('newCatalogTitle'),
      });
    }
  }, [properties]);

  return (
    <div className={classes.root}>
      <div className={classes.top}>
        {loading || saveToCatalogLoading ? (
          <CenteredLoader />
        ) : (
          <>
            {catalogs
              ?.filter((catalog) => {
                return !catalog.isDynamic;
              })
              .map((catalog, index) => (
                <CatalogModalListCard key={index} catalog={catalog} onClick={handleClickCatalog} />
              ))}
          </>
        )}

        {error && (
          <Typography variant={'body1'} className={classes.error}>
            {t('error')}
          </Typography>
        )}
      </div>
      <div className={classes.bottom}>
        {!saveToCatalogLoading && (
          <>
            <Typography variant={'body1'} className={classes.separator}>
              <span>{t('or')}</span>
            </Typography>
            <Button
              fullWidth
              variant={'third'}
              label={t('create')}
              size={'large'}
              onClick={handleNewCatalog}
            />
          </>
        )}
      </div>
    </div>
  );
};

export default SaveToCatalogModal;

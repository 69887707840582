import { AvatarGroup as MuiAvatarGroup } from '@material-ui/lab';

import Avatar from 'components/atoms/Avatar';

import { useStyles } from './styles';

const sizeMap = {
  small: 'xSmall',
  medium: 'small',
};

/**
 * @param {Array} label - A list of objects with an image and altText.
 * @param {string} size - small or medium
 */
const AvatarGroup = ({ avatars, size = 'medium' }) => {
  const classes = useStyles();
  return (
    <MuiAvatarGroup max={3} classes={{ avatar: classes.avatar }}>
      {avatars.map((a, i) => (
        <Avatar key={i} image={a.image} altText={a.altText} size={sizeMap[size]} />
      ))}
    </MuiAvatarGroup>
  );
};

export default AvatarGroup;

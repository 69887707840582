import { useCallback, useState } from 'react';

/**
 * State management for API calls
 *
 * Example:
 * const [getUser, {data, error, loading }] = useApiCall(getUser);
 * exec(1)
 *
 * @param fn the function that returns data
 * @return {[(function(...[*]): Promise<void>), {data: unknown, error: boolean, loading: boolean}]}
 */
const useApiCall = (fn) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [data, setData] = useState(null);

  const exec = useCallback(async (...args) => {
    if (loading) return;
    try {
      setLoading(true);
      const request = await fn(...args);
      setData(request.data);
      setLoading(false);
      setError(null);
    } catch (e) {
      setError(e);
      setLoading(false);
    }
  }, []);

  return [exec, { data, error, loading }];
};

export default useApiCall;

import { post } from 'common/api';

const endpoints = {
  shareSearch: '/marketplace/share_search/',
  suggestedFeatures: '/marketplace/suggested_features/',
};

export const shareSearch = (url, email, name) => {
  const body = { ...(name && { sender: name }), url, recipient_email: email };
  return post(endpoints.shareSearch, body);
};

export const suggestedFeatures = (selected) => {
  return post(endpoints.suggestedFeatures, {
    selected,
  });
};

import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Typography } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import clsx from 'clsx';

import useIsMobile from 'hooks/useIsMobile';
import AccessModal from 'modals/AccessModal';
import GuidanceCardCustomModal from 'modals/GuidanceCardCustomModal';
import { useDialog } from 'providers/DialogProvider';

import Avatar from 'components/atoms/Avatar';
import Button from 'components/atoms/Button';
import Icon from 'components/atoms/Icon';

import { useStyles } from './styles';

/**
 * @param {object} guidanceCard An object containing guidance card data (title, button_text, image, hyperlink, internal_link, modal_name).
 * @param {func} handleHide A funcion to discard a guidance card.
 */
const GuidanceCard = ({ guidanceCard, handleHide, className }) => {
  const classes = useStyles();
  const isMobile = useIsMobile();
  const { t } = useTranslation('guidanceCard');

  const [openDialog] = useDialog();

  const {
    title,
    button_text,
    image,
    hyperlink,
    internal_link,
    modal_name,
    custom_modal_title,
  } = guidanceCard;

  const openModal = (data) => {
    openDialog({
      children: data.children,
      title: data.title,
    });
  };

  const button = (
    <Button
      label={isMobile ? t('go') : button_text}
      onClick={modal_name ? () => openModal(modalData[modal_name]) : undefined}
      size={isMobile ? 'medium' : 'large'}
      className={classes.button}
    />
  );

  const modalData = {
    SignUpModal: {
      children: <AccessModal />,
      title: t('signupModal:title'),
    },
    LoginModal: {
      children: <AccessModal />,
      title: t('auth:login'),
    },
    CustomGuidanceModal: {
      children: <GuidanceCardCustomModal modalData={guidanceCard} />,
      title: custom_modal_title,
    },
  };

  return (
    <Paper className={clsx(classes.root, className)}>
      <div className={classes.left}>
        <div onClick={handleHide}>
          <Icon name={'close'} size={'1em'} color={'#999999'} className={classes.close} />
        </div>
        {image && (
          <div className={classes.avatar}>
            <Avatar size={isMobile ? 'small' : 'large'} image={image} />
          </div>
        )}
        <Typography variant={'h3'} color={'primary'} className={classes.title}>
          {title}
        </Typography>
      </div>
      <div className={classes.right}>
        {modal_name && button}

        {hyperlink && !modal_name && !internal_link && (
          <a href={hyperlink} target="_blank" rel="noopener noreferrer">
            {button}
          </a>
        )}

        {internal_link && !modal_name && !hyperlink && <Link to={internal_link}>{button}</Link>}
      </div>
    </Paper>
  );
};

export default GuidanceCard;

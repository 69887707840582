import { createContext, useContext, useEffect, useState } from 'react';

import { getAllAreas, getPageDefaults } from 'services/locationService';

const defaultContext = {
  fromSearch: false,
};

const SearchContext = createContext(defaultContext);

const SearchContextProvider = ({ children }) => {
  const [locations, setLocations] = useState(null);
  const [metaTags, setMetaTags] = useState(null);
  const [defaultDescription, setDefaultDescription] = useState(null);
  const [subscriptionPrices, setSubscriptionPrices] = useState({});
  useEffect(() => {
    const getLocations = async () => {
      const result = await getAllAreas();
      setLocations(result.data);
    };
    getLocations();
  }, []);

  useEffect(() => {
    const getDefaults = async () => {
      const { tags, description, prices } = await getPageDefaults();
      setMetaTags(tags);
      setDefaultDescription(description);
      setSubscriptionPrices(prices);
    };
    getDefaults();
  }, []);

  return (
    <SearchContext.Provider
      value={{ fromSearch: true, locations, metaTags, defaultDescription, subscriptionPrices }}
    >
      {children}
    </SearchContext.Provider>
  );
};

/**
 * Returns fromSearch. If it is true, it means that we are on the search context.
 * @return {{fromSearch: boolean }}
 */
export const useSearchContext = () => {
  const searchContext = useContext(SearchContext);

  if (!searchContext) {
    throw new Error('UserContext not found');
  }

  return searchContext;
};

export default SearchContextProvider;

/**
 * Returns a CDN image with transformations
 *
 * @param {string} fileKey
 * @param width
 * @param height
 * @param crop Extracts the specified size from the original image without distorting or scaling the delivered asset.
 * @param fill Creates an asset with the exact specified width and height without distorting the
 * asset. This option first scales as much as needed to at least fill both of the specified dimensions.
 * If the requested aspect ratio is different than the original, cropping will occur on the dimension
 * that exceeds the requested size after scaling.
 * @param fit Scales the asset up or down so that it takes up as much space as possible within a
 * bounding box defined by the specified dimension parameters without cropping any of it. The
 * original aspect ratio is retained and all of the original image is visible.
 * @param {string} ar
 * @return {string}
 */
const getImageUrl = (fileKey, { width, height, crop, fill, fit, ar } = {}) => {
  let trans = [
    ...(width ? [`w_${width}`] : []),
    ...(height ? [`h_${height}`] : []),
    ...(crop ? [`c_crop`] : []),
    ...(fill ? [`c_fill`] : []),
    ...(fit ? [`c_fit`] : []),
    ...(ar ? [`ar_${ar}`] : []),
  ];

  return `https://res.cloudinary.com/djfwrndkt/image/upload/${trans.join(',')}/${fileKey}`;
};

export default getImageUrl;

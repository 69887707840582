import React from 'react';
import { Checkbox as MUICheckbox, CircularProgress, FormControlLabel } from '@material-ui/core';
import ErrorIcon from '@material-ui/icons/Error';

import { useStyles } from './styles';

const Checkbox = ({
  checked,
  handleChange,
  name,
  label,
  size = 'medium',
  labelClass,
  loading,
  error,
}) => {
  const classes = useStyles();

  const icon = error ? (
    <ErrorIcon color={'error'} />
  ) : loading ? (
    <CircularProgress size={24} />
  ) : undefined;

  return (
    <FormControlLabel
      control={
        <MUICheckbox
          checked={checked}
          onChange={handleChange}
          size={size}
          name={name}
          icon={icon}
          checkedIcon={icon}
          classes={{
            root: classes.root,
            checked: classes.checked,
          }}
        />
      }
      disabled={loading}
      label={label}
      style={{ marginRight: 0 }}
      classes={{ label: labelClass }}
    />
  );
};

export default Checkbox;

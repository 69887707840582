import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

import Button from 'components/atoms/Button';
import ErrorPageMessage from 'components/molecules/ErrorPageMessage';

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false, error: '', errorInfo: '' };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true, error };
  }

  componentDidCatch(error, errorInfo) {
    // eslint-disable-next-line no-console
    console.error({ error, errorInfo });
    this.setState({ errorInfo });
  }

  onClick() {
    this.setState({ hasError: false });
    this.props.history.push('/');
  }

  render() {
    const { hasError } = this.state;

    if (hasError) {
      return (
        <ErrorPageMessage>
          <h1>There is a temporary server error.</h1>
          <p>
            We are working on it. If issue persists please{' '}
            <a href={'https://www.propersum.io/contact-us'} target={'_blank'} rel={'noreferrer'}>
              contact us
            </a>
            .
          </p>
          <Button label="Go home" onClick={this.onClick.bind(this)} />
        </ErrorPageMessage>
      );
    }

    return this.props.children;
  }
}

export default withRouter(ErrorBoundary);

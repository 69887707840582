import React, { useMemo } from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';

import useGetArea from 'hooks/useGetArea';
import { parseTags } from 'utils/locationsAdapter';

const SearchPageMetaTags = ({ location }) => {
  const { t } = useTranslation('pages');
  const area = useGetArea(location);

  const defaultMetaTitle = <title>{t('resultsByLocation.title', { area: area?.name })}</title>;
  const defaultMetaDescription = (
    <meta name="description" content={t('resultsByLocation.description', { area: area?.name })} />
  );

  const areaTags = useMemo(() => {
    return parseTags(area?.html_meta_tags || []);
  }, [area]);

  const tagsToRender = areaTags.length ? areaTags : [defaultMetaTitle, defaultMetaDescription];

  return <Helmet defer={false}>{tagsToRender}</Helmet>;
};

export default SearchPageMetaTags;

import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  root: {
    width: '42px !important',
    height: '26px !important',
    padding: '0 !important',
    margin: theme.spacing(1),
  },
  switchBase: {
    padding: 1,

    '&$checked': {
      transform: 'translateX(16px) !important',
      color: theme.palette.common.white,
      '& + $track': {
        backgroundColor: `${theme.palette.primary.main} !important`,
        opacity: 1,
        border: 'none',
      },
    },
  },
  thumb: {
    width: '24px !important',
    height: '24px !important',
  },
  track: {
    borderRadius: '13px !important',
    border: 'none',
    backgroundColor: ({ disabled }) =>
      disabled ? '#E1E1E1 !important' : `${theme.palette.primary.main} !important`,
    opacity: '1 !important',
  },
  checked: {},
}));

const { makeStyles } = require('@material-ui/core');

export const useStyles = makeStyles(() => ({
  root: {
    height: (props) => props.height,
    width: (props) => props.width,
    backgroundImage: (props) => props.display === 'none' && `url(${props.image})`,
    backgroundSize: 'contain',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: (props) => (props.button ? 'pointer' : 'initial'),
    backgroundRepeat: 'no-repeat',
  },
  icon: {
    cursor: 'pointer',
    padding: '0.5rem',
    borderRadius: '50%',
  },
}));

import React from 'react';
import { Box } from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';
import clsx from 'clsx';

import useIsMobile from 'hooks/useIsMobile';

import { useStyles } from './styles';

const PropertyListCardSkeleton = ({ className, size = 'large' }) => {
  const classes = useStyles({ size });
  const isMobile = useIsMobile();
  const isXsmall = useIsMobile('xs');
  const height = isMobile ? '1rem' : '1.5rem';
  return (
    <div className={clsx(classes.root, className, isMobile && !isXsmall ? classes.smCard : null)}>
      <div className={classes.images}>
        <Skeleton
          variant="rect"
          width={isMobile ? '6.5em' : size === 'large' ? '16.8125em' : '11.125em'}
          height={isMobile ? '9.5em' : '14.3125em'}
        />
      </div>
      <div className={classes.information}>
        <Box display={'flex'} justifyContent={'space-between'} paddingRight={'1rem'}>
          <Box>
            <Skeleton height={height} width={isMobile ? '140px' : '250px'} />
            <Skeleton height={height} width={isMobile ? '140px' : '250px'} />
          </Box>
          <Box>
            <Skeleton variant="circle" width={'64px'} height={'64px'} />
          </Box>
        </Box>
        <Box marginTop={'-1.5rem'}>
          <Skeleton height={height} width={'60%'} />
        </Box>
        <Box>
          <Skeleton height={height} width={'80%'} />
          <Skeleton height={height} width={'80%'} />
        </Box>
      </div>
    </div>
  );
};

export default PropertyListCardSkeleton;

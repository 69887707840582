import React from 'react';
import { useTranslation } from 'react-i18next';
import InfiniteScroll from 'react-infinite-scroll-component';

import PropertyListHeader from 'components/molecules/PropertyListHeader';
import PropertyList from 'components/organisms/PropertyList';
import PropertyMap from 'components/organisms/PropertyMap';
import RecommendedProperties from 'components/organisms/RecommendedProperties';

import { useStyles } from './styles';

/**
 * This component can be used in a results page or a catalog.
 * It shows a list of properties with a map on the right section.
 *
 * Here is when the map and the list are connected, showing a set of markers with the
 * location of each listed property, updating results when the Refresh button is pressed.
 *
 * Panning on the map applies a filter on the list if the user triggers a refresh
 *
 * @param {number} resultCount The total amount of properties
 * @param {Property[]} properties The property list
 * @param {boolean} loading True if the loader should be rendered
 * @param onChangeSort callback for sort update
 * @param {string} selectedSort The current sort criteria
 * @param currentView
 * @param onBBoxSearch
 * @param handleDeletePropertyFromCatalog
 * @param fetchMoreProperties
 * @param filters
 * @param {Element} guidanceCard A guidance card element
 * @param showRecommended
 * @param customEmptyMessage
 */
const PropertiesBrowser = ({
  resultCount,
  properties,
  mapProperties,
  loading,
  onChangeSort,
  selectedSort,
  currentView = 'list',
  onBBoxSearch = () => {},
  fetchMoreProperties,
  filters,
  handleDeletePropertyFromCatalog,
  guidanceCard,
  showRecommended,
  customEmptyMessage,
  moreDetails = [],
}) => {
  const classes = useStyles({ currentView });
  const { t } = useTranslation('pages');

  return (
    <div className={classes.root}>
      <div className={classes.resultList} id="infinite-scroll-target">
        <InfiniteScroll
          dataLength={properties.length}
          next={fetchMoreProperties}
          hasMore={properties.length > 0 && (loading || resultCount > properties.length)}
          scrollableTarget={'infinite-scroll-target'}
          style={{ overflow: 'visible' }}
          endMessage={
            showRecommended &&
            !!properties?.length &&
            !loading && (
              <RecommendedProperties title={t('results.otherProperties')} filters={filters} />
            )
          }
        >
          <PropertyListHeader
            resultCount={resultCount}
            onChangeSort={onChangeSort}
            selectedSort={selectedSort}
            loading={loading}
          />
          <PropertyList
            properties={properties}
            loading={loading}
            guidanceCard={guidanceCard}
            customEmptyMessage={customEmptyMessage}
            handleDeletePropertyFromCatalog={handleDeletePropertyFromCatalog}
            moreDetails={moreDetails}
          />
        </InfiniteScroll>
      </div>
      <div className={classes.map}>
        <PropertyMap properties={mapProperties || properties} onBBoxSearch={onBBoxSearch} />
      </div>
    </div>
  );
};

export default PropertiesBrowser;

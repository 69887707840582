const propertyFiltersParser = ({
  locations,
  price_min,
  price_max,
  bds_from,
  bds_to,
  ba_from,
  ba_to,
  sqft_from,
  sqft_to,
  est_from,
  est_to,
  proposals,
  estimates,
  bbox,
  sort_by,
}) => {
  const parsedFilters = {
    areas: [...new Set(locations)],
    min_rent_proposal: est_from,
    max_rent_proposal: est_to,
    min_square_footage: sqft_from,
    max_square_footage: sqft_to,
    min_beds: bds_from,
    max_beds: bds_to,
    min_baths: ba_from,
    max_baths: ba_to,
    min_price: price_min,
    max_price: price_max,
    include_with_proposals: proposals,
    include_without_proposals: estimates,
    bbox,
    order_by: sort_by,
  };
  let key;
  for (key in parsedFilters) {
    if (parsedFilters[key] === undefined) delete parsedFilters[key];
  }
  // Because values are rounded down when displayed it would be confusing for a
  // $77k displayed (but really $76593) estimated property not to return when entering $77k as max
  // and similarly a property that is $77k displayed (but really is $77382)
  let max = parsedFilters['max_rent_proposal'];
  if (max > 0 && max % 1000 == 0) {
    parsedFilters['max_rent_proposal'] = max + 499;
  }
  // Same story for minimum to filter
  let min = parsedFilters['min_rent_proposal'];
  if (min > 0 && min % 1000 == 0) {
    parsedFilters['min_rent_proposal'] = min - 500;
  }
  return parsedFilters;
};

export default propertyFiltersParser;

import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  root: {
    marginRight: theme.spacing(2),
    width: '100%',
  },
  listItem: {
    marginBottom: theme.spacing(3),
  },
}));

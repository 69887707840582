import { useEffect, useState } from 'react';
import axios from 'axios';

import { getMapProperties } from 'services/propertyService';

let cancelToken;

const useSearchMap = (apiFilters, properties) => {
  const [mapProperties, setMapProperties] = useState([]);

  const searchMapProperties = async () => {
    try {
      if (typeof cancelToken !== typeof undefined) {
        cancelToken.cancel('Operation canceled due to new request.');
      }

      cancelToken = axios.CancelToken.source();
      const { data } = await getMapProperties(
        { ...apiFilters, limit: process.env.REACT_APP_MAP_PROPERTIES_LIMIT },
        cancelToken.token
      );
      setMapProperties(data.results);
    } catch (error) {
      if (axios.isCancel(error)) {
        console.log('Request canceled', error.message);
        return;
      }

      console.error(error);
    }
  };

  useEffect(() => {
    if (apiFilters.offset !== 0) {
      return;
    }
    properties.length ? searchMapProperties() : setMapProperties([]);
  }, [properties]);

  return {
    mapProperties,
  };
};

export default useSearchMap;

import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    height: '20vh',
    textAlign: 'center',
    color: theme.palette.primary.main,
    position: 'relative',
    [theme.breakpoints.up('md')]: {
      height: '15vh',
    },
  },
  footer: {
    background: theme.palette.secondary[700],
    height: '70%',
  },
  start: {
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    padding: '1em',
    alignItems: 'center',
    flexWrap: 'wrap',
    [theme.breakpoints.up('md')]: {
      justifyContent: 'space-between',
    },
  },
  end: {
    color: theme.palette.primary.bg,
    background: theme.palette.secondary[800],
    height: '30%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: '16px',
    lineHeight: '24px',
  },
  links: {
    color: theme.palette.primary.bg,
    fontSize: '16px',
    lineHeight: '24px',
  },
  link: { padding: '0 0.5em', fontWeight: 'bold' },
}));

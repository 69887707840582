import { post } from 'common/api';

const endpoints = {
  clientSecret: '/marketplace/subscription_payment_intent/',
};

export const getClientSecret = async (body) => {
  const res = await post(endpoints.clientSecret, body);
  return res.data.clientSecret;
};

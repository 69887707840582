import React from 'react';
import { CircularProgress } from '@material-ui/core';

import { useStyles } from './styles';

/**
 * A full page loader for lazy pages and user-info loading
 */
const PageLoader = () => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <CircularProgress />
    </div>
  );
};

export default PageLoader;

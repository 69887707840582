import React from 'react';
import { Typography } from '@material-ui/core';

import useIsMobile from 'hooks/useIsMobile';

import Icon from 'components/atoms/Icon';
import Input from 'components/atoms/Input';
import LineSeparator from 'components/atoms/LineSeparator';

import { useStyles } from './styles';

const SizesChildren = ({ currentSelection, onChange }) => {
  const isMobile = useIsMobile();
  const classes = useStyles();

  const handleChange = (handler, event) => {
    onChange({ ...currentSelection, [handler]: event.target.value });
  };

  const renderRow = (label, icon, shortLabel, values, handleFrom, handleTo) => {
    return (
      <>
        {!isMobile && (
          <Typography variant="body1" className={classes.title}>
            {label}
          </Typography>
        )}
        <div className={classes.row}>
          {isMobile && (
            <div className={classes.labelColumn}>
              <Icon name={icon} className={classes.selectIcon} color={'#666666'} />

              <Typography variant="body1" className={classes.inputText}>
                {shortLabel}
              </Typography>
            </div>
          )}
          <div className={classes.inputColumn}>
            <div className={classes.inputWrapper}>
              <Input value={values.from} disabled={false} onChange={handleFrom} placeholder={'0'} />
            </div>
            <Typography variant="body1" className={classes.middleText}>
              to
            </Typography>
            <div className={classes.inputWrapper}>
              <Input value={values.to} disabled={false} onChange={handleTo} placeholder={'∞'} />
            </div>
          </div>
        </div>
      </>
    );
  };

  return (
    <div className={classes.childRoot}>
      {renderRow(
        'Bedrooms',
        'bed',
        'Bds',
        { from: currentSelection.bds_from, to: currentSelection.bds_to },
        (event) => handleChange('bds_from', event),
        (event) => handleChange('bds_to', event)
      )}
      {!isMobile && <LineSeparator margin={'1.5em 0'} />}
      {renderRow(
        'Bathrooms',
        'bath',
        'Ba',
        { from: currentSelection.ba_from, to: currentSelection.ba_to },
        (event) => handleChange('ba_from', event),
        (event) => handleChange('ba_to', event)
      )}
      {!isMobile && <LineSeparator margin={'1.5em 0'} />}
      {renderRow(
        'Sq Ft',
        'rule',
        'Sq Ft',
        { from: currentSelection.sqft_from, to: currentSelection.sqft_to },
        (event) => handleChange('sqft_from', event),
        (event) => handleChange('sqft_to', event)
      )}
    </div>
  );
};
export default SizesChildren;

import React, { useEffect, useState } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { useTranslation } from 'react-i18next';
import { Typography } from '@material-ui/core';
import { Form, Formik } from 'formik';

import useApiCall from 'hooks/useApiCall';
import useFullUrl from 'hooks/useFullUrl';
import { useDialog } from 'providers/DialogProvider';
import { useSnackbar } from 'providers/SnackbarProvider';
import { shareSearch } from 'services/searchService';
import shareModalSchema from 'validators/shareModal';

import Button from 'components/atoms/Button';
import Input from 'components/atoms/Formik/Input';

import { useStyles } from './styles';

const ShareSearchModal = ({ user, noTrailingInfo }) => {
  const [copied, setCopied] = useState(false);
  const [showError, setShowError] = useState(false);
  const classes = useStyles();
  const { t } = useTranslation('shareSearchModal');
  const url = useFullUrl();
  const [, closeDialog] = useDialog();
  const [openSnackbar] = useSnackbar();
  const [execShare, { data, error, loading }] = useApiCall(shareSearch);
  const onSubmit = async (values) => {
    const { email } = values;
    await execShare(url, email, user);
  };

  useEffect(() => {
    if (error) {
      setShowError(true);
    }
  }, [error]);

  useEffect(() => {
    if (data && !error) {
      closeDialog();
      openSnackbar({
        children: t('shareSuccess'),
        variant: 'success',
      });
    }
  }, [data, error]);

  const handleCopy = () => {
    setCopied(true);
    setTimeout(() => setCopied(false), 750);
  };
  return (
    <Formik
      onSubmit={onSubmit}
      initialValues={{ email: '' }}
      validationSchema={shareModalSchema(t)}
    >
      <Form className={classes.root}>
        <div className={classes.section}>
          <Input
            name="email"
            placeholder={t('emailPlaceholder')}
            fullWidth
            label={'Email'}
            required
          />
        </div>
        {showError && (
          <div className={classes.section}>
            <Typography variant={'body1'} className={classes.error}>
              {t('error')}
            </Typography>
          </div>
        )}
        <div className={[classes.section, classes.actions].join(' ')}>
          <CopyToClipboard text={url} onCopy={() => handleCopy()}>
            <Button
              label={copied ? t('linkCopied') : t('copyButton')}
              variant={'third'}
              icon={!copied && 'link'}
              fullWidth
              className={classes.leftButton}
            />
          </CopyToClipboard>
          <Button
            label={loading ? t('sendingEmail') : t('sendButton')}
            variant={'primary'}
            fullWidth
            className={classes.rightButton}
            type={'submit'}
            disabled={loading}
          />
        </div>
        {!noTrailingInfo && (
          <div className={classes.content}>
            <Typography variant={'body2'}>{t('share')}</Typography>
          </div>
        )}
      </Form>
    </Formik>
  );
};

export default ShareSearchModal;

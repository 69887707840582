import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  root: { padding: theme.spacing(5) },
  section: { paddingBottom: theme.spacing(3) },
  actions: {
    display: 'flex',
  },
  content: {
    '& p': {
      color: theme.palette.secondary[800],
    },
  },
  error: {
    textTransform: 'capitalize',
  },
}));

import { get } from 'common/api';

const endpoints = {
  guidancecards: '/marketplace/guidancecards/',
};

export const getGuidanceCards = async (page) => {
  const body = page && { front_end_view: page };

  return get(endpoints.guidancecards, body);
};

import React from 'react';
import { useTranslation } from 'react-i18next';
import { Typography } from '@material-ui/core';
import MenuItem from '@material-ui/core/MenuItem';
import clsx from 'clsx';

import Icon from 'components/atoms/Icon';

import { useStyles } from '../../styles';

const LocationsChildren = ({ locations, currentSelection, onChange }) => {
  const classes = useStyles();
  const { t } = useTranslation('propertyBrowser');

  const isSelected = (value) => {
    return currentSelection.locations.includes(value);
  };

  const handleChange = (value) => {
    const newSelection = isSelected(value)
      ? currentSelection.locations.filter((e) => e !== value)
      : [...currentSelection.locations, value];

    currentSelection.locations = [...new Set(newSelection)];

    onChange(currentSelection);
  };

  if (!locations) return null;

  return locations.map((location) => (
    <MenuItem
      key={location.id}
      value={location.slug}
      className={clsx(classes.menuItem, isSelected(location.slug) && classes.selected)}
      onClick={() => handleChange(location.slug)}
    >
      {location.display_text || location.name}
      {/* eslint-disable-next-line no-constant-condition */}
      {location.property_count && false ? (
        <>
          {' '}
          <Typography variant={'body1'} className={classes.propertiesCount}>
            {location.property_count} {t('properties', { count: location.property_count })}
          </Typography>
        </>
      ) : null}
      {isSelected(location.slug) && (
        <Icon name={'close'} color={'#FFFFFF'} className={classes.close} size={20} />
      )}
    </MenuItem>
  ));
};

export default LocationsChildren;

import React from 'react';
import clsx from 'clsx';

import { useStyles } from './styles';

const ValuePill = ({ value, type = 'default' }) => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <p className={clsx(classes.text, classes[type])}>{value}</p>
    </div>
  );
};

export default ValuePill;

/* eslint-disable no-unused-vars */
import { get, patch, post, put } from 'common/api';

import normalizeProperty, { normalizeMapProperty } from 'utils/propertyAdapter';

const endpoints = {
  properties: '/marketplace/properties/',
  propertyImages: '/marketplace/property_images/',
  shareProperty: '/marketplace/share_specific_property/',
  propertiesByCatalogSlug: '/marketplace/catalogs/:slug/properties/',
  catalogBySlug: '/marketplace/catalogs/:slug/',
  recommendedProperties: '/marketplace/properties/recommended/',
  mapProperties: 'marketplace/quickmap_propertydata/',
};

const removeUnpaidIfNotIn = (dbProperty, DisplayUnpaidInProperties) => {
  if (!DisplayUnpaidInProperties.includes(dbProperty.id)) {
    dbProperty.management_proposals = dbProperty.management_proposals.filter((p) => {
      return p.paid;
    });
  }
  return dbProperty;
};

/**
 * @param {object} query
 * Example Query Object:
    offset: int,
    limit: int,
    only_displayed: boolean,
    order_by: string,
    areas: string[],
    min_rent_proposal: int,
    max_rent_proposal: int,
    min_square_footage: int,
    max_square_footage: int,
    min_beds: int,
    max_beds: int,
    min_baths: int,
    max_baths: int,
    min_price: int,
    max_price: int
  * @returns {object} response with a list of properties
 */
export const getAllProperties = async (query = { limit: 10 }, cancelToken) => {
  const { data } = await get(endpoints.properties, query, cancelToken);
  const results = data?.results?.map((p) => normalizeProperty(p));
  const count = data?.count;
  return {
    data: { results, count },
    cancelToken,
  };
};

export const getMapProperties = async (query = { limit: 10 }, cancelToken) => {
  const { data } = await get(endpoints.mapProperties, query, cancelToken);
  const results = data?.results?.map((p) => normalizeMapProperty(p));
  const count = data?.count;
  return {
    data: { results, count },
    cancelToken,
  };
};

export const getRecommendedProperties = async (query = { limit: 2 }) => {
  const { data } = await get(endpoints.recommendedProperties, query);
  const results = data?.results?.map((p) => normalizeProperty(p));

  return {
    data: { results, count: data?.count, isLastPage: !data?.next },
  };
};

export const getPropertyBySlug = async (slug, include_unpaid_proposals = false) => {
  const { data } = await get(
    `${endpoints.properties}${slug}/?include_unpaid_proposals=${String(include_unpaid_proposals)}`
  );
  return normalizeProperty(data);
};

export const getNextPage = (pageUrl) => get(pageUrl);

export const shareProperty = (props) => {
  const { property, sendMoreDetails, email } = props;
  const body = {
    property_id: property.id,
    recipient_email: email,
    send_more_details: sendMoreDetails,
  };
  return post(endpoints.shareProperty, body);
};

// Property Images
export const getAllPropertyImages = () => get(endpoints.propertyImages);

export const getPropertyImage = (id) => get(`${endpoints.propertyImages}${id}/`);

export const getPropertiesByCatalogSlug = async (
  slug,
  query = { limit: 10, include_unpaid_proposals: false },
  moreDetails = []
) => {
  const { data } = await get(
    endpoints.propertiesByCatalogSlug.replace(':slug', slug).replace(':moreDetails', moreDetails),
    query
  );
  const unpaidRemoved = data?.results?.map((p) => removeUnpaidIfNotIn(p, moreDetails));
  const results = unpaidRemoved?.map((p) => normalizeProperty(p));
  const count = data?.count;
  return { data: { results, count } };
};

export const deletePropertiesByCatalogSlug = async ({ catalog, propertyID }) => {
  const updatedProperties = catalog.properties.filter((property) => property !== propertyID);
  const {
    id,
    image,
    propertyCount,
    slug,
    user,
    isPermanent,
    isPublic,
    ...partialCatalog
  } = catalog;

  const body = {
    ...partialCatalog,
    public: isPublic,
    permanent: isPermanent,
    ...{ properties: updatedProperties },
  };
  const { data } = await patch(endpoints.catalogBySlug.replace(':slug', catalog.slug), body);
  const results = data?.results?.map((p) => normalizeProperty(p));
  const count = data?.count;
  return { data: { results, count } };
};

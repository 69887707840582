import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(() => ({
  root: {
    color: (props) => props.color && props.color,
    '& circle': {
      transition: (props) =>
        props.isAnimated ? 'stroke-dashoffset 1000ms cubic-bezier(0.4, 0, 0.2, 1) 0ms' : 'none',
    },
  },
}));

import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { generatePath, useHistory, useLocation } from 'react-router-dom';
import { Container, Typography } from '@material-ui/core';

import useGuidanceCard from 'hooks/useGuidanceCards';
import useIsMobile from 'hooks/useIsMobile';
import AccessModal from 'modals/AccessModal';
import SaveSearchModal from 'modals/SaveSearchModal';
import ShareSearchModal from 'modals/ShareSearchModal';
import { useDialog } from 'providers/DialogProvider';
import { useSnackbar } from 'providers/SnackbarProvider';
import { useUserContext } from 'providers/UserProvider';
import routes from 'router/routes';
import { getAllProperties } from 'services/propertyService';
import PropertyFiltersParser from 'utils/propertyFiltersParser';

import LineSeparator from 'components/atoms/LineSeparator';
import GuidanceCard from 'components/organisms/GuidanceCard';
import PropertiesBrowser from 'components/organisms/PropertiesBrowser';
import PropertyBrowserActionsHeader from 'components/organisms/PropertyBrowserActionsHeader';

import { useStyles } from './styles';
import useSearchMap from './useSearchMap';
import useSearchParams from './useSearchParams';

/**
 * @param {string} location optional, where location filter should be initialized
 */
const SearchProperties = ({ location }) => {
  const classes = useStyles();
  const { t } = useTranslation('pages');
  const history = useHistory();
  const locationParams = useLocation();

  const { user, loading: userLoading } = useUserContext();
  const isMobile = useIsMobile();
  const [openDialog] = useDialog();
  const [openSnackbar] = useSnackbar();
  const [guidanceCardData, discardGuidanceCard] = useGuidanceCard('ResultsPage');

  const [currentView, setCurrentView] = useState('list');
  const [headerLoading, setHeaderLoading] = useState(false);

  const [propsLoading, setPropsLoading] = useState(true);
  const {
    params,
    properties,
    resultCount,
    setParamsAndResetPage,
    apiFilters,
    fetchMore,
    setMapBounds,
    sort,
    setSortAndResetPage,
  } = useSearchParams(setPropsLoading);

  const { mapProperties } = useSearchMap(apiFilters, properties);

  const loginWall = () => {
    openSnackbar({
      children: t('results.loginFirst'),
    });
    openDialog({
      maxWidth: 'xs',
      logo: true,
      blurred: true,
      children: <AccessModal />,
    });
  };

  /**
   * Get location from /{location} and set it on params for filtering
   */
  useEffect(() => {
    if (location && !locationParams?.state?.redirection) {
      setParamsAndResetPage(params.locations.push(location));
    }
  }, []);

  const onViewChange = () => {
    setCurrentView((current) => (current === 'list' ? 'map' : 'list'));
  };

  const onSearchSave = useCallback(async () => {
    if (!userLoading && !user) {
      loginWall();
    } else {
      try {
        setHeaderLoading(true);
        const { data } = await getAllProperties(PropertyFiltersParser(params));
        const propertyIds = data?.results?.map((p) => p.id);
        if (propertyIds.length) {
          openDialog({
            children: <SaveSearchModal properties={propertyIds} />,
            title: t('results.saveSearchModalTitle'),
          });
        } else {
          openSnackbar({
            variant: 'warning',
            children: (
              <Typography variant={'body1'}>{t('results.saveSearchModalError')}</Typography>
            ),
          });
        }
      } catch (error) {
        console.error(error);
      }
      setHeaderLoading(false);
    }
  }, [params, userLoading, user]);

  const onSearchShare = () => {
    if (!userLoading && !user) {
      loginWall();
    } else {
      openDialog({
        children: <ShareSearchModal noTrailingInfo />,
        title: t('results.shareSearchModalTitle'),
      });
    }
  };

  const onFilter = (params) => {
    setPropsLoading(true);
    setParamsAndResetPage(params);
  };

  useEffect(() => {
    if (location) {
      if (params.locations.length !== 1) {
        history.push({
          pathname: generatePath(routes.results),
          search: locationParams.search,
          state: { redirection: true },
        });
      }
    } else {
      if (params.locations.length === 1) {
        history.push({
          pathname: generatePath(routes.resultsByLocation, { location: params.locations[0] }),
          search: locationParams.search,
          state: { redirection: true },
        });
      } else {
        if (locationParams.pathname !== '/') {
          history.push({
            pathname: generatePath(routes.results),
            search: locationParams.search,
            state: { redirection: true },
          });
        }
      }
    }
  }, [location, params]);

  return (
    <>
      <div className={classes.searchFilterWrapper}>
        <Container>
          <LineSeparator margin={0} />
          <PropertyBrowserActionsHeader
            onSave={onSearchSave}
            onShare={onSearchShare}
            onFilter={onFilter}
            onViewChange={onViewChange}
            currentView={currentView}
            initialValues={params}
            loading={headerLoading}
          />
        </Container>
      </div>
      <Container style={{ height: 'calc(100vh - 70px - 64px)' }} disableGutters={isMobile}>
        <PropertiesBrowser
          properties={properties}
          mapProperties={mapProperties}
          resultCount={resultCount}
          selectedSort={sort}
          onChangeSort={setSortAndResetPage}
          loading={propsLoading}
          currentView={currentView}
          onBBoxSearch={setMapBounds}
          fetchMoreProperties={fetchMore}
          filters={apiFilters}
          showRecommended
          guidanceCard={
            guidanceCardData && (
              <GuidanceCard
                guidanceCard={guidanceCardData}
                handleHide={() => discardGuidanceCard(guidanceCardData.id)}
              />
            )
          }
        />
      </Container>
    </>
  );
};

export default SearchProperties;

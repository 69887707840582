import React, { useState } from 'react';
import { useEffect } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Box, MenuItem, Typography } from '@material-ui/core';

import CheckoutModal from 'modals/CheckoutModal';
import { useDialog } from 'providers/DialogProvider';
import SearchContextProvider, { useSearchContext } from 'providers/SearchContextProvider';

import Button from 'components/atoms/Button';
import Dropdown from 'components/atoms/Dropdown';

import { useStyles } from './styles';

const UpgradeModal = ({ propertyArea }) => {
  return (
    <SearchContextProvider>
      <Modal propertyArea={propertyArea} />
    </SearchContextProvider>
  );
};

const Modal = ({ propertyArea }) => {
  const { locations, subscriptionPrices } = useSearchContext();
  const [openDialog, closeDialog] = useDialog();
  const { t } = useTranslation('upgradeModal');
  const [locationSelected, setLocationSelected] = useState('');
  const [quarterly, setQuarterly] = useState(true);

  const quarterLabel =
    locationSelected?.view_quarter_price > 0
      ? Math.round(locationSelected?.view_quarter_price)
      : Math.round(subscriptionPrices?.quarterPrice);

  const annualLabel =
    locationSelected?.view_annual_price > 0
      ? Math.round(locationSelected?.view_annual_price)
      : Math.round(subscriptionPrices?.annualPrice);
  const classes = useStyles();

  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: 250,
        width: 'auto',
      },
    },
  };

  useEffect(() => {
    const propertyLocation = locations?.find(
      (location) => location.slug === propertyArea.replace('-area', '')
    );
    setLocationSelected(propertyLocation);
  }, [propertyArea, locations]);

  const onChangeDropdown = (value) => {
    setLocationSelected(value);
  };

  const openCheckoutModal = () => {
    closeDialog({ silent: true });

    const price = quarterly ? quarterLabel : annualLabel;

    openDialog({
      children: <CheckoutModal location={locationSelected} quarterly={quarterly} price={price} />,
      logo: true,
    });
  };

  return (
    <div className={classes.paper}>
      <Box marginBottom={'1rem'}>
        <Typography variant="h5" color="initial">
          {t('title')}
        </Typography>
      </Box>
      <Box>
        <Dropdown
          MenuProps={MenuProps}
          onChange={onChangeDropdown}
          placeholder={t('location')}
          selected={locationSelected}
          selectTextColor={locationSelected ? '#41B060' : '#9F9F9F'}
        >
          {locations?.map((item) => {
            return (
              <MenuItem key={item.slug} value={item}>
                {item.name}
              </MenuItem>
            );
          })}
        </Dropdown>
      </Box>
      <Box className={classes.actions}>
        <Button
          label={t('quarterly', { quarterLabel })}
          variant={quarterly ? 'primary' : 'secondary'}
          fullWidth
          onClick={() => setQuarterly(true)}
        />
        <Button
          label={t('annualy', { annualLabel })}
          variant={!quarterly ? 'primary' : 'secondary'}
          fullWidth
          onClick={() => setQuarterly(false)}
        />
      </Box>
      <Box className={classes.footer}>
        <Typography>
          <Trans t={t} i18nKey={'footer.properScore'} components={{ bold: <strong /> }} />
        </Typography>

        <Typography>
          <Trans t={t} i18nKey={'footer.financials'} components={{ bold: <strong /> }} />
        </Typography>

        <Typography>
          <Trans t={t} i18nKey={'footer.hotSheets'} components={{ bold: <strong /> }} />
        </Typography>
      </Box>

      <Box textAlign={'right'}>
        <Button
          label={t('Continue')}
          variant={'primary'}
          type={'submit'}
          disabled={!locationSelected}
          onClick={openCheckoutModal}
        />
      </Box>
    </div>
  );
};

export default UpgradeModal;

/* eslint-disable no-unused-vars */
import { deleteResource, get, patch, post } from 'common/api';

import normalizeCatalog from 'utils/catalogAdapter';

const endpoints = {
  catalogs: '/marketplace/catalogs/',
  catalogBySlug: '/marketplace/catalogs/:slug/',
  userBySlug: '/users/:userSlug/',
  shareCatalog: '/marketplace/catalog_shares/',
  unlockCatalog: '/marketplace/unlock_requests/',
};

export const createCatalog = async (
  name,
  user,
  properties = [],
  moreDetails = [],
  permanent = false,
  isPublic = false
) => {
  const body = { name, properties, more_details: moreDetails, permanent, public: isPublic };
  return post(endpoints.catalogs, body);
};

export const duplicateCatalog = async (catalog) => {
  const {
    name,
    isPermanent: permanent,
    properties,
    moreDetails: more_details,
    isPublic,
    user,
  } = catalog;
  return createCatalog(name, user, properties, more_details, permanent, isPublic);
};

export const getUserCatalogs = async (userID) => {
  const body = userID && { owner: userID };

  const { data } = await get(endpoints.catalogs, body);
  const results = data?.results?.map((p) => normalizeCatalog(p));

  return { data: results };
};

export const getFavoritesCatalog = async () => {
  const body = { permanent: true, name: 'favorites', limit: 1 };
  const { data } = await get(endpoints.catalogs, body);
  const results = data?.results?.map((p) => normalizeCatalog(p));
  if (results) {
    return results[0];
  } else {
    return null;
  }
};

export const deleteCatalog = async (slug) =>
  deleteResource(endpoints.catalogBySlug.replace(':slug', slug));

export const hideCatalog = async ({ user, catalog }) => {
  const body = { hidden_catalogs: [...user.hidden_catalogs, catalog.slug] };
  return patch(endpoints.userBySlug.replace(':userSlug', user.slug), body);
};

export const changeCatalogPrivacy = async (catalog) => {
  const {
    id,
    image,
    propertyCount,
    slug,
    user,
    isPermanent,
    isPublic,
    ...partialCatalog
  } = catalog;

  const body = { ...partialCatalog, public: !isPublic, permanent: isPermanent };
  return patch(endpoints.catalogBySlug.replace(':slug', catalog.slug), body);
};

export const renameCatalog = async (catalog, name) => {
  const {
    id,
    image,
    propertyCount,
    slug,
    user,
    isPermanent,
    isPublic,
    ...partialCatalog
  } = catalog;
  const body = { ...partialCatalog, public: isPublic, permanent: isPermanent, name };

  return patch(endpoints.catalogBySlug.replace(':slug', catalog.slug), body);
};

export const saveToCatalog = async (catalog, properties, moreDetails = []) => {
  const body = {
    name: catalog.name,
    public: catalog.isPublic,
    permanent: catalog.isPermanent,
    properties: [...catalog.properties, ...properties],
    more_details: [...catalog.moreDetails, ...moreDetails],
  };
  return patch(endpoints.catalogBySlug.replace(':slug', catalog.slug), body);
};

export const toggleFavorite = async (property) => {
  const bd = { permanent: true, name: 'favorites', limit: 1 };

  const { data } = await get(endpoints.catalogs, bd);
  const favorites = data?.results[0];

  const properties = favorites.properties.includes(property)
    ? favorites.properties.filter((item) => item !== property)
    : [...favorites.properties, property];

  // eslint-disable-next-line
  const { id, slug, user, ...rest } = favorites;
  const body = { ...rest, properties };

  return patch(endpoints.catalogBySlug.replace(':slug', favorites.slug), body);
};

export const getCatalogBySlug = async (slug) => {
  const { data } = await get(endpoints.catalogBySlug.replace(':slug', slug));
  const results = normalizeCatalog(data);
  results.user = data.user;
  return { data: results };
};

export const shareCatalog = ({ catalog, email, user }) => {
  const body = { catalog: catalog.id, recipient_email: email, sender: user.id };
  return post(endpoints.shareCatalog, body);
};

export const unlockCatalog = ({ catalog }) => {
  const body = { catalog: catalog.id };
  return post(endpoints.unlockCatalog, body);
};

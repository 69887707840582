import React from 'react';
import { useHistory } from 'react-router-dom';
import { Typography } from '@material-ui/core';

import { useDialog } from 'providers/DialogProvider';

import Button from 'components/atoms/Button';
import Image from 'components/atoms/Image';

import { useStyles } from './styles';

/**
 * @param {object} modalData An object custom modal data (custom_modal_text_area1, custom_modal_image, custom_modal_text_area2, custum_modal_button_text, hyperlink, internal_link).
 */
const GuidanceCardCustomModal = ({ modalData }) => {
  const classes = useStyles();
  const history = useHistory();
  const [, closeDialog] = useDialog();

  const {
    custom_modal_text_area1,
    custom_modal_image,
    custom_modal_text_area2,
    custum_modal_button_text,
    hyperlink,
    internal_link,
  } = modalData;

  const handleClick = () => {
    closeDialog();
    history.push(modalData.internal_link);
  };

  return (
    <div className={classes.root}>
      <div className={classes.section}>
        <Typography variant={'body1'} className={classes.text}>
          {custom_modal_text_area1}
        </Typography>
      </div>
      {custom_modal_image && (
        <div className={classes.imageSection}>
          <Image src={custom_modal_image} />
        </div>
      )}

      <div className={classes.section}>
        <Typography variant={'body1'} className={classes.text}>
          {custom_modal_text_area2}
        </Typography>
      </div>
      <div className={classes.actions}>
        {hyperlink && !internal_link && (
          <a href={hyperlink} target="_blank" rel="noopener noreferrer">
            <Button label={custum_modal_button_text} className={classes.button} />
          </a>
        )}

        {internal_link && !hyperlink && (
          <Button
            label={custum_modal_button_text}
            className={classes.button}
            onClick={handleClick}
          />
        )}
      </div>
    </div>
  );
};

export default GuidanceCardCustomModal;

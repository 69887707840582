import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { generatePath, Link } from 'react-router-dom';
import { Typography } from '@material-ui/core';
import clsx from 'clsx';
import { Form, Formik } from 'formik';

import useApiCall from 'hooks/useApiCall';
import { useDialog } from 'providers/DialogProvider';
import { useSnackbar } from 'providers/SnackbarProvider';
import { useUserContext } from 'providers/UserProvider';
import routes from 'router/routes';
import { createCatalog } from 'services/catalogService';
import createCatalogValidation from 'validators/createCatalog';

import Button from 'components/atoms/Button';
import Input from 'components/atoms/Formik/Input';

import { useStyles } from './styles';

const CreateCatalogModal = ({ properties = [], i18nKey = 'createCatalogModal', trailingInfo }) => {
  const classes = useStyles();
  const { t } = useTranslation(i18nKey);
  const [, closeDialog] = useDialog();
  const [openSnackbar] = useSnackbar();
  const { user } = useUserContext();
  const [showError, setShowError] = useState(false);

  const [execCreateCatalog, { data, error, loading }] = useApiCall(createCatalog);

  const onSubmit = async ({ name }, { setSubmitting }) => {
    await execCreateCatalog(name, user?.id, properties);
    setSubmitting(false);
  };

  useEffect(() => {
    if (error) {
      setShowError(true);
    }
  }, [error]);

  useEffect(() => {
    if (data && !error) {
      closeDialog();
      openSnackbar({
        children: (
          <Link to={generatePath(routes.brandedCatalog, { slug: data.slug })}>
            {t('saveSuccess')},
          </Link>
        ),

        variant: 'success',
      });
    }
  }, [data, error]);

  return (
    <Formik
      onSubmit={onSubmit}
      initialValues={{ name: '' }}
      validationSchema={createCatalogValidation(t)}
    >
      <Form className={classes.root}>
        <div className={classes.section}>
          <Input name="name" placeholder={t('placeholder')} fullWidth label={t('label')} required />
        </div>
        {showError && (
          <div className={classes.section}>
            <Typography variant={'body1'} className={classes.error}>
              {t('defaultError')}
            </Typography>
          </div>
        )}
        <div className={clsx(classes.section, classes.actions)}>
          <Button
            label={loading ? t('creating') : t('create')}
            variant={'primary'}
            fullWidth
            type={'submit'}
            disabled={loading}
          />
        </div>
        {trailingInfo && (
          <Typography variant={'body2'} className={classes.trailingInfo}>
            {t('trailingInfo')}
          </Typography>
        )}
      </Form>
    </Formik>
  );
};

export default CreateCatalogModal;

import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  root: {
    height: '9.5em',
    background: theme.palette.secondary.main,
    boxShadow: '0 4px 10px rgba(0, 0, 0, 0.1)',
    borderRadius: '5px',
    display: 'flex',
    overflow: 'hidden',
    flex: '0 1 50%',
    [theme.breakpoints.only('xs')]: {
      flex: '1 1 100%',
    },
    [theme.breakpoints.only('sm')]: {
      gap: '1rem',
    },
    [theme.breakpoints.up('md')]: {
      height: '14.3125em',
    },
  },
  images: (props) => ({
    position: 'relative',
    width: '7.9em',
    height: '100%',
    [theme.breakpoints.down(400)]: {
      width: '6.5em',
    },
    [theme.breakpoints.up('sm')]: {
      width: props.size === 'large' ? '16.8125em' : '11.125em',
    },
  }),
  imageCarousel: {
    // display: 'none',
    cursor: 'pointer',
  },
  information: {
    flex: '1',
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(1),
    minWidth: '0',
    justifyContent: 'space-between',
    cursor: 'pointer',
    color: '#000',
    '&:hover': {
      color: '#000',
    },
    [theme.breakpoints.up('md')]: {
      padding: '1em',
      paddingBottom: '0.8em',
    },
  },
  image: () => ({
    '& img': {
      width: '100%',
      height: '7.3em',
      objectFit: 'cover',
      [theme.breakpoints.up('md')]: {
        height: '14.3125em',
      },
    },
  }),
  top: {
    position: 'absolute',
    zIndex: 50,
    left: 0,
    top: 0,
    right: 0,
    display: 'flex',
    justifyContent: 'space-between',
    padding: theme.spacing(1),
    background: 'linear-gradient(0, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.62) 100%)',
  },
  bottom: {
    pointerEvents: 'none',
    padding: theme.spacing(1),
    position: 'absolute',
    zIndex: 50,
    left: 0,
    bottom: 0,
    right: 0,
    background: 'linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.62) 100%)',
    textAlign: 'center',
    '& .longNumber': {
      display: 'none',
      [theme.breakpoints.up('md')]: {
        display: 'initial',
        color: theme.palette.secondary.main,
        fontWeight: '700',
        fontSize: '1.75rem',
        margin: '0 ',
      },
    },
    '& .shortNumber': {
      color: theme.palette.secondary.main,
      fontWeight: '700',
      fontSize: '1.25rem',
      margin: '0 ',
      [theme.breakpoints.up('md')]: {
        display: 'none',
      },
    },
  },
  header: {
    display: 'flex',
    flexDirection: 'column',
    margin: '0',
    [theme.breakpoints.up('md')]: {
      justifyContent: 'space-between',
      flexDirection: 'row',
    },
  },
  title: {
    display: 'flex',
    flexDirection: 'column',
    fontSize: '1rem',
    margin: '0',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    [theme.breakpoints.up('md')]: {
      fontSize: '1.125rem',
    },
  },
  info: {
    display: 'flex',
    flexGrow: 1,
    justifyContent: 'space-between',
  },
  infoWrapper: {
    display: 'flex',
    flexGrow: 1,
    justifyContent: 'space-between',
    [theme.breakpoints.only('xs')]: {
      alignItems: 'center',
    },
  },
  area: {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    margin: '0',
  },
  address: {
    fontWeight: '700',
    margin: '0',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    width: '10rem',
    [theme.breakpoints.up(1030)]: {
      width: '16rem',
    },
  },
  bold: {
    fontWeight: 700,
  },
  features: {
    fontSize: '0.9rem',
    marginTop: '-1.5rem',
    '& span': {
      marginRight: '5px',
    },
    [theme.breakpoints.up('sm')]: {
      marginTop: '0',
      fontSize: '1rem',
      textAlign: 'initial',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
  },
  infoFinancial: {
    flexBasis: '33%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    [theme.breakpoints.up(400)]: {
      flexBasis: '28%',
    },
    [theme.breakpoints.up('md')]: {
      flexBasis: '35%',
    },
  },
  featuresBreak: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'initial',
    },
  },
  smText: {
    fontSize: '0.84rem',
  },
  smCard: {
    height: '9.5em',
    gap: '0.3rem',
  },
  estimatesTop: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'flex',
      justifyContent: 'space-between',
      fontSize: '1rem',
      lineHeight: '2rem',
      borderBottom: `1px solid ${theme.palette.primary.bg}`,
      marginBottom: '0.5em',
    },
  },
  estimatesBottom: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  estimatesLong: {
    fontSize: '0.9rem',
    display: 'initial',
    flexBasis: '65%',
    padding: '0.5rem',
    [theme.breakpoints.only('xs')]: {
      fontSize: '0.84rem',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      padding: 0,
    },
  },
  estimatesShort: {
    gap: 5,
    display: 'flex',
    fontSize: '0.95rem',
    alignItems: 'center',
    fontWeight: 500,
    letterSpacing: 0.5,
    '& p': {
      margin: '0',
      fontSize: '0.9em',
      lineHeight: '1em',
      marginTop: '-0.714em',
      [theme.breakpoints.only('xs')]: {
        marginTop: '0',
      },
    },
  },
  avatarGroupContainer: {
    display: 'flex',
    flexBasis: '65%',
    paddingRight: '0.5rem',
    alignItems: 'center',
    [theme.breakpoints.only('xs')]: {
      marginBottom: 0,
      marginTop: 0,
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      paddingLeft: '0.5rem',
    },
  },
  manager: {
    margin: '0',
    paddingLeft: '1em',
    fontSize: '1em',
    '& span': {
      fontWeight: '700',
    },
    [theme.breakpoints.only('xs')]: {
      fontSize: '0.84rem',
      paddingLeft: '0.5em',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
  },
  actionIcon: {
    padding: 0,
  },
  link: {
    color: '#000',
    width: '100%',
    '&:hover': {
      color: '#000',
    },
  },
}));

import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  root: { padding: theme.spacing(5) },
  separator: {
    width: '100%',
    textAlign: 'center',
    borderBottom: ` 1px solid ${theme.palette.primary.bg}`,
    lineHeight: '0.1em',
    margin: theme.spacing(2, 0, 4, 0),
    '& span': {
      padding: theme.spacing(0, 2),
      background: theme.palette.secondary.main,
      color: theme.palette.secondary[400],
    },
  },
  top: {
    position: 'relative',
    maxHeight: '30vh',
    overflowY: 'auto',
  },
  bottom: {
    background: theme.palette.secondary.main,
    paddingTop: theme.spacing(4),
  },
  error: {
    color: theme.palette.error.main,
  },
}));
